import { CREATETEAM } from '../constant';

const initialState = {
    CreateTeam: {},
};

// eslint-disable-next-line default-param-last
const teamReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATETEAM:
            return { ...state, CreateTeam: { ...action.data } };


        default:
            return state;
    }
};

export default teamReducer;
