/* eslint-disable import/no-absolute-path */
/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */

export const imagesAll = {
  mainLogo: '/assets/mainLogo.png',
  mainAppLogo: '/assets/apple-icon.png',
  mainWhiteLogo: '/assets/Logo.png',
  authLogo: '/assets/images/auth_logo.png',
  facebook: '/assets/images/ic_facebbook.png',
  google: '/assets/images/ic_google.png',
  twiter: '/assets/images/twiter.png',
  girls: '/assets/images/Girl-with-interface.png',
  adminRegister: '/assets/images/Group_Analysis.png',
  error404: '/assets/images/404.png',
  error403: '/assets/images/403.png',
  error500: '/assets/images/500.png',
  maintenance: '/assets/images/maintenance.png',
  coming_soon: '/assets/images/coming_soon.png',
  upload: '/assets/images/upload.png',
  seo: '/assets/images/seo.png',
  excle: '/assets/images/excle.png',
  qrCode: '/assets/images/iOSAppStore.png',
  appstore: '/assets/images/apple.png',
  playstore: '/assets/images/playstore.png',
  pencil: '/assets/images/pencil-edit-icon.png',
  octopus: `/assets/images/octopus.png`,
  profileicon: '/assets/profileicon.jpg',
  activity_level: '/assets/images/Activity_level.png',
  cross_black: '/assets/black-cross-icon.png',
  cross_white: '/assets/white-cross-icon.png',
};
