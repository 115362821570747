/* eslint-disable import/no-unresolved */

import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Box, Card, Container, Table, TableBody, TableContainer, TablePagination, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';

// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/service/AxiosAll';
import useSettings from 'src/hooks/useSettings';
import useTable, { getComparator } from 'src/hooks/useTable';
import useTabs from 'src/hooks/useTabs';
// _mock_
// components
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import Scrollbar from 'src/components/Scrollbar';
import { TableNoData } from 'src/components/table';
// sections

import RecycleBinTableRow from 'src/sections/@dashboard/invoice/list/RecycleBinTableRow';
import ReCycleBinTableTead from 'src/components/table/CompanyTableHead';
import RecycleBinToolebar from 'src/sections/@dashboard/invoice/list/RecycleBinToolebar';
import LoadingOverlay from 'react-loading-overlay';

// ----------------------------------------------------------------------

const SERVICE_OPTIONS = [
  'all',
  'full stack development',
  'backend development',
  'ui design',
  'ui/ux design',
  'front end development',
];

const TABLE_HEAD = [
  { id: 'CompanyName', label: 'Customer Name', align: 'left' },
  { id: 'CompanyEmail', label: 'Primary Admin', align: 'left' },
  { id: 'TripshiftOwner', label: 'TripShift owner', align: 'left' },
  { id: 'TotalLicence', label: 'Licences purchased', align: 'center', width: 140 },
  { id: 'RemainingLicence', label: 'Licences remaining', align: 'center', width: 140 },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function RecycleBin() {
  const theme = useTheme();
  const { userDetails, userRole, token } = useSelector((state) => state.loginReducer);

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  // Destructure properties from useTable hook
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'status' });

  // Initialize state variables
  const [tableData, setTableData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [count, setCount] = useState('');
  const [orderValue, setOrder] = useState('asc');
  const [orderByValue, setOrderBy] = useState(null);
  const [filterService, setFilterService] = useState('all');
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [reload, setReload] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const { currentTab: filterStatus, onChangeTab: onFilterStatus } = useTabs('all');
  const [makeAdmin, setMakeAdmin] = useState(false);
  const [makeTeam, setMakeTeam] = useState(false);
  const [loading, setLoading] = useState(false);
  const [teamLength, setTeamLength] = useState();
  const setting = localStorage.getItem('settings');
  const [open, setOpen] = useState(false);
  const mode = JSON.parse(setting);

  // Function to handle filtering by name
  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  // Function to handle filtering by service
  const handleFilterService = (event) => {
    setFilterService(event.target.value);
  };
  // Apply sorting and filtering to the table data
  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
  });
  // Check if data is not found
  const isNotFound = !dataFiltered?.length;
  const denseHeight = dense ? 56 : 76;
  const location = useLocation();
  const reloadAPI = location?.state?.reload;

  // Function to fetch customer list
  const getCustomerList = async () => {
    setLoading(true);

    const data = {
      search: filterName,
      sortBy: orderByValue,
      sortOrder: orderValue === 'asc' ? 'ASC' : 'DESC',
      pageNo: page + 1,
      perPageLimit: rowsPerPage,
      RoleId: userRole?.data,
    };
    const res = await AxiosAll(
      'get',
      `/api/company/getalldeletedcustomer?pageNo=${
        page + 1
      }&perPageLimit=${rowsPerPage}&search=${filterName}&sortOrder=${
        orderValue === 'asc' ? 'ASC' : 'DESC'
      }&sortBy=${orderByValue}`,
      {},
      token
    );
    setTableData([]);
    if (res?.status === 200) {
      setReload(false);
      setTableData(res?.data?.result);
      setCount(res?.data?.total);
      setLoading(false);
    } else {
      setLoading(false);
      setTableData([]);
    }
  };

  // Fetch customer list when dependencies change
  useEffect(() => {
    getCustomerList();
  }, [token, userRole, orderByValue, orderValue, filterName, page, rowsPerPage, reloadAPI]);

  return (
    <Page title="Recycle Bin" className="manage-member">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <div>
          <Typography
            variant="h5"
            sx={{ pl: 0.5 }}
            className={mode?.themeMode === 'dark' ? 'light_link_text' : 'header_dark_heading'}
          >
            {userRole?.data === 4 ? 'TripShift Admin Portal' : null}
          </Typography>

          {userRole?.data === 4 ? (
            <Typography
              variant="h5"
              sx={{ mt: 2, mb: 10, pl: 0.5 }}
              noWrap
              className={mode?.themeMode === 'dark' ? 'light_link_text' : 'header_dark_heading'}
            >
              Welcome {userDetails?.data?.data?.firstName} {userDetails?.data?.data?.lastName}{' '}
            </Typography>
          ) : null}
        </div>
        <HeaderBreadcrumbs heading="Recycle Bin" links={[]} />

        <Card>
          <RecycleBinToolebar
            filterName={filterName}
            filterService={filterService}
            filterStartDate={filterStartDate}
            filterEndDate={filterEndDate}
            teamLength={teamLength}
            selected={selected?.length}
            tableData={selectedIds}
            MainTable={dataFiltered}
            teamName={dataFiltered[0]?.teamName}
            setSelected={setSelected}
            selectedRow={selected}
            setReload={setReload}
            makeAdmin={makeAdmin}
            makeTeam={makeTeam}
            user={userDetails}
            setFilterName={setFilterName}
            onFilterName={handleFilterName}
            onFilterService={handleFilterService}
            onFilterStartDate={(newValue) => {
              setFilterStartDate(newValue);
            }}
            onFilterEndDate={(newValue) => {
              setFilterEndDate(newValue);
            }}
            optionsService={SERVICE_OPTIONS}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <LoadingOverlay active={loading} spinner>
                <Table size={'medium'} className="memberinner-table">
                  <ReCycleBinTableTead
                    order={orderValue}
                    orderBy={orderByValue}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData?.length}
                    numSelected={selected?.length}
                    setOrder={setOrder}
                    user={userDetails}
                    team={false}
                    setOrderBy={setOrderBy}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        tableData?.map((row) => row?.userId)
                      )
                    }
                  />

                  <>
                    <TableBody>
                      {dataFiltered?.map((row, index) => (
                        <RecycleBinTableRow
                          key={index}
                          row={row}
                          user={userDetails}
                          selected={selected.includes(row?.userId)}
                          onSelectRow={() => onSelectRow(row?.userId)}
                          setReload={setReload}
                          getCustomerList={getCustomerList}
                        />
                      ))}

                      {isNotFound ? <TableNoData isNotFound={isNotFound} /> : null}
                    </TableBody>
                  </>
                </Table>
              </LoadingOverlay>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  comparator,
  filterName,
  filterStatus,
  filterService,
  filterStartDate,
  filterEndDate,
}) {
  const stabilizedThis = tableData?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis?.map((el) => el[0]);

  if (filterName) {
    tableData = tableData?.filter(
      (item) =>
        item.invoiceNumber.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        item.invoiceTo.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  return tableData;
}
