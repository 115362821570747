/* eslint-disable import/order */
/* eslint-disable object-shorthand */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { useState } from 'react';
// @mui
import { Button, Dialog, DialogActions, DialogContent, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import AxiosAll from 'src/service/AxiosAll';
// eslint-disable-next-line import/no-named-as-default
import DeleteSuccess from './DeleteSuccess';
import { LoadingButton } from '@mui/lab';
// components

// ----------------------------------------------------------------------

const emails = ['username@gmail.com', 'user02@gmail.com'];

export default function RestoreCustomer({
  open,
  setOpen,
  companyId,
  name,
  editLisenceValue,
  setEditLisenceValue,
  Value,
  getCustomerList,
  deleteUser,
}) {
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);
  const theme = useTheme();
  const { userDetails, token } = useSelector((state) => state.loginReducer);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [opensuceessModal, setOpenSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleRestoreCustomer = async () => {
    setLoading(true);

    const data = {
      companyId: companyId,
    };
    const res = await AxiosAll('post', `/api/Company/restore-customer`, data, token);

    if (res?.status === 200) {
      setLoading(false);

      setOpen(false);
      setOpenSuccessModal(true);
    }
    setLoading(false);
  };

  return (
    <>
      {/* //delete confirmation  */}

      <Dialog open={open}>
        <DialogContent className="delete-alert">
          <Typography
            variant="body1"
            className={mode?.themeMode === 'dark' ? 'heading_18_dark heading_18 boldttext' : 'heading_18 boldttext'}
          >
            You are about to Restore {name} on the Tripshift Platform.
            <br />
            Confirm ?{' '}
          </Typography>
        </DialogContent>
        <DialogActions className="btn-section-alert">
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={handleRestoreCustomer}
            size="medium"
            className="confirm-btn"
          >
            Confirm
          </LoadingButton>
          <Button onClick={() => setOpen(false)} variant="contained" className="cancle_btn" size="medium">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <DeleteSuccess
        CustomerRestoreName={name}
        opensuceessModal={opensuceessModal}
        getCustomerList={getCustomerList}
        setOpenSuccessModal={setOpenSuccessModal}
      />
    </>
  );
}
