/* eslint-disable no-nested-ternary */
/* eslint-disable no-debugger */
import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Checkbox, TableCell, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// utils
// components
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/service/AxiosAll';
import { useSelector } from 'react-redux';
import Highlight from 'react-highlighter';
// eslint-disable-next-line import/no-named-as-default

// ----------------------------------------------------------------------

TeamFlowAddTeamTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function TeamFlowAddTeamTableRow({
  row,
  filterName,
  selected,
  id,
  onSelectRow,
  setFilterData,
  setTableCurrentData,
  onViewRow,
  onEditRow,
  onDeleteRow,
  tableData,
  setTableData,
  getMemberList,
  teamNameTeamFlow,
  setOrderBy,
}) {
  const theme = useTheme();
  const { userDetails, token } = useSelector((state) => state.loginReducer);

  const { sent, AppStatus, team, member, role, teamName, roleId, name, userEmail, userId, status, invoiceTo, level } =
    row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const AddMemberToTeam = async (userId) => {
    if (userId) {
      const data = {
        teamId: id,
        userId: [userId],
      };
      const res = await AxiosAll('post', '/api/team/update-team-members', data, token);
      if (res?.status === 200) {
        getCurrentTeamMemberList();
      }
    }
  };

  const getCurrentTeamMemberList = async () => {
    const res = await AxiosAll('get', `/api/team/team-detail?teamId=${id}`, {}, token);
    if (res?.status === 200) {
      const results = tableData?.filter(
        ({ userId: id1 }) => !res?.data?.data?.listofMembers?.some(({ id: id2 }) => id2 === id1)
      );
      setTableData(results);
      setTableCurrentData(res?.data?.data?.listofMembers);
      setFilterData(res?.data?.data?.listofMembers);
      getMemberList();
    } else {
      setTableCurrentData([]);
    }
  };

  const memberName = name === ' ' ? userEmail : name;
  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox" className="checkbox-edit-section">
        {(userDetails?.data?.data?.id === userId && teamName === 'no team') || roleId === 5 || roleId === 2 ? (
          <Checkbox disabled checked={selected} onClick={onSelectRow} />
        ) : teamName === 'no team' ? (
          <Checkbox checked={selected} onClick={onSelectRow} />
        ) : (
          <Checkbox disabled checked={selected} onClick={onSelectRow} />
        )}
      </TableCell>
      <TableCell align="left" className="team-name-tb  first-table-width " style={{ fontWeight: '600' }}>
        <Highlight search={filterName}>{name.trim() ? name : userEmail}</Highlight>
      </TableCell>
      <TableCell align="left" sx={{ fontStyle: 'italic' }}>
        {teamName}
      </TableCell>
    </TableRow>
  );
}
