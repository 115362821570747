/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
import { useState, useEffect } from 'react';
import * as Yup from 'yup';
// eslint-disable-next-line import/order
import useResponsive from '../../hooks/useResponsive';
// form
// eslint-disable-next-line import/no-unresolved

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { Box, Button, Card, Container, Stack, Typography, useTheme } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import Image from '../../components/Image';
// utils
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import styled from '@emotion/styled';
// eslint-disable-next-line import/no-unresolved
import Page from 'src/components/Page';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/service/AxiosAll';
// routes
// components
// eslint-disable-next-line import/no-unresolved
import Logo from 'src/components/Logo';
// eslint-disable-next-line import/no-unresolved
import { imagesAll } from 'src/assets/Images_index';

import LoadingOverlay from 'react-loading-overlay';
import { useMsal } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { PublicClientApplication } from '@azure/msal-browser';
import { SignIn } from 'src/AuthConfig';
// eslint-disable-next-line import/no-unresolved

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));
const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  // width: '100%',
  maxWidth: 464,
  // maxWidth:'32.22vw',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  // maxWidth:'33.33vw',

  margin: 'auto',
  // minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));
const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export default function SuperAdminRegister() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');
  const setting = localStorage.getItem('settings');
  const { themeStretch } = useSettings();
  const [message, setMessage] = useState();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');
  const [loading, setLoading] = useState(false);

  const mode = JSON.parse(setting);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const theme = useTheme();
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const msalInstance = new PublicClientApplication(SignIn);

  const NewUserSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
    companyName: Yup.string().required('Company name is required'),
  });

  const defaultValues = {
    email: '',
    companyName: '',
  };

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (values) => {
    // eslint-disable-next-line no-debugger
    try {
      const createPayload = {
        email: values?.email,
        companyName: values?.companyName,
        type: 1,
        origin: window.location.origin,
        RoleId: 0,
      };

      const res = await AxiosAll('post', '/api/user/register', createPayload);
      if (res?.status === 200) {
        setType('success');
        setOpen(true);
        setMessage(res?.data?.message);
      } else {
        setType('error');
        setOpen(true);
        setMessage(res?.response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogin = () => {
    setLoading(true);
    const loginRequest = {
      scopes: ['https://tripshiftuk.onmicrosoft.com/1ebd5094-17cd-408f-a407-9031710d85e0/TripShiftPlatformProduction'],
      prompt: 'login',
      nonce: 'defaultNonce',
    };
    instance.loginRedirect(loginRequest).then((response) => {
      const accessToken = response.accessToken;
    });
  };

  useEffect(() => {
    if (accounts?.length) {
      getToken();
    }
  }, [accounts]);

  const getToken = async () => {
    setLoading(true);
    if (accounts.length > 0) {
      try {
        const tokenRequest = {
          scopes: ['https://tripshiftuk.onmicrosoft.com/1ebd5094-17cd-408f-a407-9031710d85e0/TripShiftPlatformProduction'],
          account: accounts[0], // Select the first account for simplicity
        };

        const response = await msalInstance.acquireTokenSilent(tokenRequest);
        // Access the access token from the response

        const accessToken = response?.accessToken;
        if (response?.accessToken) {
          fetchData(accessToken);
          // Use the access token as needed
        }
      } catch (error) {
        console.error('Failed to acquire access token:', error);
      }
    }
  };

  const fetchData = async (authToken) => {
    setLoading(true);

    try {
      const response = await AxiosAll('post', '/api/user/update-user-status', '', `Bearer ${authToken}`);
      // eslint-disable-next-line no-debugger
      // await dispatch(addLogin(response));
      // await dispatch(updateRole(response?.data?.data?.roleId));
      // await dispatch(updateImage(response.data.data?.photoURL));
      if (response.status === 200) {
        // handleLogOutSSo();
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <Page title="Login">
      <LoadingOverlay
        // eslint-disable-next-line react/jsx-boolean-value
        active={loading}
        spinner
      >
        <div className="login">
          <RootStyle>
            <HeaderStyle className="boxpadding">
              <Logo />
            </HeaderStyle>

            {mdUp && (
              <SectionStyle className="section-style">
                <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }} className="welcome_text">
                  Welcome TripShifter!
                </Typography>
                <Image visibleByDefault disabledEffect src={imagesAll.authLogo} className="login_sectionimg" />
              </SectionStyle>
            )}
            <Container maxWidth="sm">
              <ContentStyle className="mobilepadd container-style">
                <Stack>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h4" gutterBottom className="signin_text">
                      Welcome to TripShift!
                    </Typography>
                    {/* <Typography sx={{ color: 'text.secondary', mb: 5, mt: 1 }} className="details_text">
                        Enter your details below.
                      </Typography> */}
                  </Box>

                  {/* <SocialLogin /> */}
                </Stack>

                <Button type="button" sx={{ mt: 3 }} fullWidth size="large" variant="contained" onClick={handleLogin}>
                  Login
                </Button>
                {/* <Alert severity="error" sx={{ mt: 3 }}>login feild</Alert> */}
              </ContentStyle>
            </Container>
          </RootStyle>
        </div>
      </LoadingOverlay>
    </Page>
  );
}
