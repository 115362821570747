/* eslint-disable no-debugger */
import PropTypes from 'prop-types';
// @mui
import { TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material';
import { useState } from 'react';

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

// ----------------------------------------------------------------------

TableHeadUploadCsv.propTypes = {
  onSort: PropTypes.func,
  orderBy: PropTypes.string,
  sortOrder: PropTypes.string,
  headLabel: PropTypes.array,
  rowCount: PropTypes.number,
  numSelected: PropTypes.number,
  onSelectAllRows: PropTypes.func,
  sx: PropTypes.object,
};

export default function TableHeadUploadCsv({
  order,
  orderBy,
  rowCount = 0,
  headLabel,
  numSelected = 0,
  onSelectAllRows,
  setOrder,
  setOrderBy,
  user,
  team,
  onSort,
  sx,
}) {
  const [toggle, setToggle] = useState();

  const handleOrder = (name, orderby) => {
    setToggle({
      [name]: !toggle?.[name],
    });
    setOrder('asc');
    setOrderBy(orderby);
    // eslint-disable-next-line no-debugger
    if (toggle?.[name] && orderBy === orderby && order === 'asc') {
      setOrder('desc');
      setOrderBy(orderby);
      setToggle({
        ...toggle,
      });
    } else if (toggle?.[name] && orderBy === orderby && order === 'desc') {
      setOrder('asc');
      setOrderBy(orderby);
      setToggle({
        [name]: !toggle?.[name],
      });
    }
  };
  return (
    <TableHead sx={sx}>
      <TableRow>
        {onSelectAllRows && (
          <>
            {/* <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={(event) => onSelectAllRows(event.target.checked)}
              />
            </TableCell> */}
          </>
        )}

        {headLabel.map((headCell, index) => (
          <TableCell key={index}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={() => onSort(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
