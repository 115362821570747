/* eslint-disable import/no-named-as-default */
/* eslint-disable no-debugger */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import { useEffect, useState } from 'react';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import Iconify from 'src/components/Iconify';
import AxiosAll from 'src/service/AxiosAll';
import EmailConfirmDialog from './emailConfirm';
// import Typography from 'src/theme/overrides/Typography';

// ----------------------------------------------------------------------

export default function AddMemberDialogs({ setReload, setAddReload }) {
  // State variables
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { userDetails, userRole, token } = useSelector((state) => state.loginReducer);
  const [validationError, setValidationError] = useState(null);

  // Handle email input change
  const handleChange = (e) => {
    setEmailValue(e.target.value);
  };

  // Open the dialog
  const handleClickOpen = () => {
    setOpen(true);
    setOpenAlert(false);
    setValidationError(null);
  };

  // Close the dialog
  const handleClose = () => {
    setOpen(false);
  };
  // Handle form submission
  const onSubmit = async () => {
    // const filterString = emailValue?.replace(/ /g, "").replace(/(\r\n|\n|\r)+/gm, "", '')
    if (validateEmailAddresses()) {
      // perform additional actions here (e.g. send email)
      const filterString = emailValue
        ?.replaceAll(/[\n\r]/g, ',')
        .replaceAll(/\s/g, '')
        .split(',')
        .filter((str) => str !== '')
        .join(',');
      const data = {
        email: filterString,
        origin: window.location.origin,
        type: 2,
        invite_external: true,
        RoleId: userRole?.data,
      };

      if (data) {
        setLoading(true);
        const res = await AxiosAll('post', '/api/user/register', data, token);
        if (res?.data?.success === true) {
          setLoading(false);
          setOpen(false);
          setOpenAlert(true);
          // setReload(true);
          setAddReload(true);
        } else {
          setLoading(false);
          setError(res?.response?.data?.message);
          // setEmailValue('');
          handletimeout();
          // setReload(true);
          setAddReload(true);
        }
      }
    }
  };

  // Handle error timeout
  const handletimeout = () => {
    const timer1 = setTimeout(() => setError(null), 10 * 1000);
    return () => {
      clearTimeout(timer1);
    };
  };

  // Validate email addresses
  const validateEmailAddresses = () => {
    const emaillist = emailValue
      ?.replaceAll(/[\n\r]/g, ',')
      .replaceAll(/\s/g, '')
      .split(',')
      .filter((str) => str !== '')
      .join(',');
    const emailAddressesArray = emaillist.split(/[,\n]+/).map((emailAddress) => emailAddress.trim());

    const invalidEmails = emailAddressesArray.filter((emailAddress) => {
      const emailRegex = /^$|^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return !emailRegex.test(emailAddress);
    });

    if (invalidEmails.length > 0) {
      setValidationError(`${invalidEmails.join(', ')}`);
      return false;
      // eslint-disable-next-line no-else-return
    } else {
      setValidationError(null);
      return true;
    }
  };

  // Check email validation on every change in emailValue
  useEffect(() => {
    validateEmailAddresses();
  }, [emailValue]);

  return (
    <>
      {' '}
      <Button
        size="medium"
        variant="contained"
        className="newmemberbtn"
        startIcon={<Iconify icon={'eva:plus-fill'} />}
        onClick={handleClickOpen}
        sx={{ boxShadow: 'none' }}
      >
        Add Member
      </Button>
      {openAlert ? (
        <EmailConfirmDialog
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          emailValue={emailValue}
          setEmailValue={setEmailValue}
        />
      ) : null}
      <Dialog open={open} onClose={handleClose} className="addmemberdialogs">
        <Grid>
          <Grid item xs={2} className="cross_section textright" sx={{ mr: 4, mt: 2 }}>
            <Iconify
              icon={'akar-icons:cross'}
              className="alert_cross"
              onClick={handleClose}
              style={{ cursor: 'pointer' }}
            />
          </Grid>
        </Grid>
        <DialogTitle sx={{ pl: 4, mb: 2 }}>Add Member</DialogTitle>
        <DialogContent className="addmembers">
          {/* <DialogContentText   > */}
          <Typography variant="body1" className="main-para">
            To add multiple users put each email on a separate line or separate by commas.
          </Typography>

          {/* </DialogContentText> */}

          <TextField
            multiline
            // rows={3}
            className="member-textfield"
            onChange={handleChange}
            autoFocus
            fullWidth
            type="email"
            margin="dense"
            variant="outlined"
            label="Email Address"
          />
        </DialogContent>
        <DialogActions>
          {loading ? (
            <LoadingButton type="submit" variant="contained" loading>
              Invite Members
            </LoadingButton>
          ) : validationError ? (
            <LoadingButton variant="contained" disabled>
              Invite Members
            </LoadingButton>
          ) : (
            <>
              <LoadingButton onClick={onSubmit} variant="contained" loading={false}>
                Invite Members
              </LoadingButton>
            </>
          )}
        </DialogActions>

        <div>
          {validationError && emailValue?.length > 0 ? (
            <Alert severity="error" sx={{ mb: 3 }}>
              <strong>{`The following email addresses are invalid: ${validationError}`}</strong>
            </Alert>
          ) : null}
          {error ? (
            <Alert severity="error" sx={{ mb: 3 }}>
              <strong>{error}</strong>
            </Alert>
          ) : null}
        </div>
      </Dialog>
    </>
  );
}
