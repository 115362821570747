/* eslint-disable no-unreachable */
/* eslint-disable no-lonely-if */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import { useNavigate } from 'react-router-dom';
// @mui
import { Card, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
// import useAuth from '../../hooks/useAuth';
import { useTheme } from '@emotion/react';
import { useState, useEffect } from 'react';
import useResponsive from '../../hooks/useResponsive';
// routes
import Page from '../../components/Page';
// sections
// eslint-disable-next-line import/order
// eslint-disable-next-line import/order
import { useDispatch, useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { RedirectURl, SignIn } from 'src/AuthConfig';
// import AxiosAll from 'src/service/AxiosAll';
import LoadingOverlay from 'react-loading-overlay';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  maxWidth: 480,
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function CreatePassword() {
  // const { "" } = useAuth();
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);
  const theme = useTheme();
  const mdUp = useResponsive('up', 'md');
  const params = new URLSearchParams(window.location.search);
  const Accesstoken = params.get('token');
  const email = params.get('email');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const { token } = useSelector((state) => state.loginReducer);
  const [loading, setLoading] = useState(true);
  const msalInstance = new PublicClientApplication(SignIn);

  useEffect(() => {
    if (email) {
      setLoading(true);
      handleCustomerSignUp();
    } else {
      setLoading(true);
      navigate('/login');
    }
  }, []);

  const handleCustomerSignUp = () => {
    instance.loginRedirect({
      clientId: '1ebd5094-17cd-408f-a407-9031710d85e0',
      authority: 'https://tripshiftuk.b2clogin.com/tripshiftuk.onmicrosoft.com/B2C_1_TripShift_SignUp',
      scopes: ['https://tripshiftuk.onmicrosoft.com/1ebd5094-17cd-408f-a407-9031710d85e0/TripShiftPlatformProduction'],
      nonce: 'defaultNonce',
      knownAuthorities: ['https://tripshiftuk.b2clogin.com'],
      redirectUri: `${RedirectURl}/login`,
    });
  };

  return (
    <Page title="Create Password" className="register">
      <LoadingOverlay
        // eslint-disable-next-line react/jsx-boolean-value
        active={loading}
        spinner
      >
        <RootStyle>
          <>
            <Container>
              <ContentStyle className="container-style">
                {/* <Box sx={{ mb: 5, alignItems: 'center' }} className="bottmarg">
              <Box sx={{ flexGrow: 1 }}>
               
            </Typography> */}
              </ContentStyle>
            </Container>
          </>
        </RootStyle>
      </LoadingOverlay>
    </Page>
  );
}
