/* eslint-disable import/no-unresolved */
// @mui
import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
// eslint-disable-next-line import/order
import WelcomeTripshiftForm from 'src/sections/auth/dialogForm/welcomeTripshiftForm';

// ----------------------------------------------------------------------

export default function WelcomeTripshiftDialogs({ open, setOpen }) {
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} className="welcomeTripshift">
        <DialogTitle variant="h4" gutterBottom className="main_heading" sx={{ textAlign: 'center', mb: 1 }}>
          Welcome to Tripshift!
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{ textAlign: 'center', mb: 2, color: 'text.secondary' }}
            className="heading_18 welcomepara"
          >
            You're nearly there, just take a minute and help us set up your profile
          </DialogContentText>
          <WelcomeTripshiftForm setOpen={setOpen} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
