/* eslint-disable object-shorthand */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
// @mui
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Snackbar,
  Typography,
  useTheme,
} from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/service/AxiosAll';
// eslint-disable-next-line import/no-unresolved
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

export default function AlertDialog({
  title,
  setSelected,
  selectedRow,
  dataFiltered,
  multiSelect,
  setReload,
  alertOpne,
  setRemoveAdminOpen,
  setTeamOpen,
  setAdminOpen,
  setAlert,
  setIds,
  selectedUserIds,
  setRoleKey,
  userName,
  userEmail,
  userId,
  roleId,
}) {
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [message, setMessage] = useState();
  const [Notifyopen, setNotifyOpen] = useState(false);
  const [type, setType] = useState('');
  const { userDetails, token } = useSelector((state) => state.loginReducer);
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseSuccess = () => {
    setOpen(false);
    setSuccessOpen(true);
  };

  const handleClose = () => {
    if (userId) {
      setSuccessOpen(false);
      setReload(true);
      setAlert(false);
    } else {
      setSuccessOpen(false);
      setSelected([]);
      setReload(true);
      setAlert(false);
    }
  };
  useEffect(() => {
    if (alertOpne === true) setOpen(alertOpne);
  }, [alertOpne]);

  const handleCancle = () => {
    setOpen(false);
    setAlert(false);
    setSuccessOpen(false);
  };

  const handleUpdateRole = async () => {
    if (userId) {
      const dataObject = {
        userId: userId,

        roleId:
          title === 'Make Team Lead'
            ? 2
            : title === 'Remove Admin' && roleId === 5
            ? 2
            : title === 'Remove Admin'
            ? 3
            : 1,
      };
      const res = await AxiosAll('post', '/api/user/update-role', [dataObject], token);
      if (res?.status === 200) {
        selectedUserIds.push(userId);
        setRoleKey(title);
        // setIds([row?.userId])
        handleCloseSuccess();
      } else {
        // setNotifyOpen(true)
        // setMessage(res.response?.data.message)
        // setType("error")
        handleCloseSuccess();
        // setOpen(true)
      }
    } else {
      const dataObject = selectedRow?.map((id) => ({
        userId: id,
        roleId: title === 'Make Team Lead' ? 2 : title === 'Remove Admin' ? 3 : 1,
      }));

      const res = await AxiosAll('post', '/api/user/update-role', dataObject, token);
      if (res?.status === 200) {
        setIds(selectedRow);
        setRoleKey(title);
        // setNotifyOpen(true)
        // setMessage(res?.data?.message)
        // setReload("8")
        handleCloseSuccess();
      } else {
        // setNotifyOpen(true)
        // setMessage(res.response?.data.message)
        // setType("error")
        handleCloseSuccess();

        // setOp
      }
    }
  };

  const name = userName === ' ' ? userEmail : userName;
  return (
    <>
      <Snackbar
        open={Notifyopen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={3000}
        onClose={() => setNotifyOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} severity={type || 'success'} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      {!userName ? (
        <Button
          size="medium"
          onClick={handleClickOpen}
          variant="outlined"
          sx={{ mr: 2, textTransform: 'initial' }}
          className="secondlast-btn"
        >
          {title === 'Make Admin' ? 'Make admin' : title === 'Make Team Lead' ? 'Make team lead' : title}
        </Button>
      ) : null}

      <Dialog open={open} onClose={handleCancle}>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
          {multiSelect && title === 'Make Admin' ? (
            <Typography
              variant="body1"
              className={mode?.themeMode === 'dark' ? 'heading_18_dark heading_18 boldttext' : 'heading_18 boldttext'}
            >
              You are about to make the all selected members Admin. Would you like to proceed?
            </Typography>
          ) : title === 'Make Admin' ? (
            <Typography
              variant="body1"
              className={mode?.themeMode === 'dark' ? 'heading_18_dark heading_18 boldttext' : 'heading_18 boldttext'}
            >
              You are about to make {userName} an admin. Would you like to proceed?
            </Typography>
          ) : title === 'Remove Admin' ? (
            <Typography
              variant="body1"
              className={mode?.themeMode === 'dark' ? 'heading_18_dark heading_18 boldttext' : 'heading_18 boldttext'}
            >
              You are about to remove {userName} admin status. Would you like to proceed?
            </Typography>
          ) : title === 'Make Team Lead' && multiSelect ? (
            <Typography
              variant="body1"
              className={mode?.themeMode === 'dark' ? 'heading_18_dark heading_18 boldttext' : 'heading_18 boldttext'}
            >
              You are about to make the all selected members team lead. Would you like to proceed?
            </Typography>
          ) : (
            <Typography
              variant="body1"
              className={mode?.themeMode === 'dark' ? 'heading_18_dark heading_18 boldttext' : 'heading_18 boldttext'}
            >
              You are about to make {userName} a {title.replace('Make', '')}. Would you like to proceed?
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdateRole} variant="contained" size="medium" className="confirm-btn">
            Confirm
          </Button>
          <Button
            onClick={() => handleCancle()}
            variant="contained"
            color="secondary"
            v
            className="cancle_btn"
            size="medium"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={successOpen} onClose={handleClose} className="toast-dialogs">
        <Grid container spacing={2} className="MemberAlert">
          <Grid item xs={2}>
            <div className="checkicon-bg">
              <Iconify icon={'ant-design:check-circle-filled'} width={24} height={24} className="" />
            </div>
          </Grid>
          <Grid item xs={8.5}>
            {multiSelect && title === 'Make Admin' ? (
              <Typography
                variant="body1"
                className={mode?.themeMode === 'dark' ? 'heading_18_dark heading_18' : 'heading_18'}
              >
                All selected members will receive an email letting them know they are now an admin.
              </Typography>
            ) : title === 'Make Admin' ? (
              <Typography
                variant="body1"
                className={mode?.themeMode === 'dark' ? 'heading_18_dark heading_18' : 'heading_18'}
              >
                {userName} will receive an email letting them know they are now an admin.{' '}
              </Typography>
            ) : title === 'Remove Admin' ? (
              <Typography
                variant="body1"
                className={mode?.themeMode === 'dark' ? 'heading_18_dark heading_18' : 'heading_18'}
              >
                {userName} is no longer an admin
              </Typography>
            ) : title === 'Make Team Lead' && multiSelect ? (
              <Typography
                variant="body1"
                className={mode?.themeMode === 'dark' ? 'heading_18_dark heading_18' : 'heading_18'}
              >
                All selected members will receive an email letting them know they are now a Team Lead.
              </Typography>
            ) : (
              <Typography
                variant="body1"
                className={mode?.themeMode === 'dark' ? 'heading_18_dark heading_18' : 'heading_18'}
              >
                {name} will receive an email letting them know they are now a Team Lead.
              </Typography>
            )}
          </Grid>
          <Grid item xs={1.5} className="cross_section">
            <Iconify icon={'akar-icons:cross'} className="alert_cross" onClick={handleClose} />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
