export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const CREATETEAM = 'CREATETEAM';
export const UPDATEROLE = 'UPDATEROLE';
export const UPDATEIMAGE = 'UPDATEIMAGE';
export const UPDATENAME = 'UPDATENAME';
export const TOKEN = 'TOKEN';
export const EXPIREON = 'EXPIREON';
export const USERTOKEN = 'USERTOKEN';
export const RESET = 'RESET';
export const EMAIL = 'EMAIL';
