/* eslint-disable camelcase */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable arrow-body-style */
/* eslint-disable object-shorthand */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-debugger */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';

// form
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Card, Checkbox, FormControlLabel, Grid, Stack, Typography, useTheme } from '@mui/material';
// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import AxiosAll from 'src/service/AxiosAll';
import useAuth from '../../../../hooks/useAuth';
// utils
import { fData } from '../../../../utils/formatNumber';
// _mock
// components
import { FormProvider, RHFSelect, RHFTextField, RHFUploadAvatar } from '../../../../components/hook-form';
import WorkingHoursDialog from 'src/sections/mui/dialog/workingHours';
import { useMsal } from '@azure/msal-react';
import { SignIn } from 'src/AuthConfig';
import { PublicClientApplication } from '@azure/msal-browser';
import { getEmail, getToken } from 'src/redux/actions/login';

// ----------------------------------------------------------------------

export default function AccountGeneral({ useremail, setLoading, Accesstoken }) {
  // Initialize variables and states
  const location = useLocation();
  const UserEmail = location?.state?.Email;
  const LoginToken = location?.state?.LoginToken;
  const [GUID, setGUID] = useState();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, userToken, email } = useSelector((state) => state.loginReducer);
  const [errorMessage, setErrorMessage] = useState(null);
  const [aboutOption, setAboutOption] = useState([]);
  const [imageError, setImageError] = useState('');
  const [uploadurl, setUploadUrl] = useState(false);
  const [days, setDays] = useState([]);
  const { instance, accounts } = useMsal();
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);
  const theme = useTheme();
  const msalInstance = new PublicClientApplication(SignIn);

  const queryParams = new URLSearchParams(location.hash.slice(1)); // Remove the '#' and create URLSearchParams

  const idToken = queryParams.get('id_token');
  const error = queryParams.get('error');
  // Define a Yup schema for updating user information
  const UpdateUserSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(/^[A-Za-z ]*$/, 'First name should not contain a number')
      .required('First name is required')
      .min(3, 'First name should be at least 3 characters long'),
    lastName: Yup.string()
      .matches(/^[A-Za-z ]*$/, 'Last name should not contain a number')
      .required('Last name is required'),
    jobTitle: Yup.string().required('Job title is required'),
    TermAndCondition: Yup.bool().oneOf([true], 'Please accept the Terms & Conditions'),
    DataAndPolicy: Yup.bool().oneOf([true], 'Please accept the Data Policy'),
  });

  const firstDayOfWeek = days?.length ? days[0]?.day : null;
  const lastDayOfWeek = days?.length && days?.length !== 1 ? days[days?.length - 1]?.day : null;

  // Effect hook to decode and set GUID when 'idToken' changes
  useEffect(() => {
    if (idToken) {
      const TOKEN = jwtDecode(idToken);
      setGUID(TOKEN);
    }
  }, [idToken]);

  // Effect hook to handle errors and navigate to the login page when 'error' changes
  useEffect(() => {
    if (error) {
      navigate('/login');
      dispatch(getToken(''));
      dispatch(getEmail(''));
    }
  }, [error]);
  // Define a function to get the name of the day of the week
  const getDayOfWeek = (day) => {
    switch (day) {
      case 1:
        return 'Sunday';
      case 2:
        return 'Monday';
      case 3:
        return 'Tuesday';
      case 4:
        return 'Wednesday';
      case 5:
        return 'Thursday';
      case 6:
        return 'Friday';
      case 7:
        return 'Saturday';
      default:
        return 'null';
    }
  };
  // Define a function to get a formatted string of days of the week
  const getDaysOfWeek = (firstDay, lastDay) => {
    const firstDayName = getDayOfWeek(firstDay);
    const lastDayName = getDayOfWeek(lastDay);
    return `${lastDayName === null ? `${firstDayName}` : `${firstDayName} - ${lastDayName}`}`;
  };

  // Define default values for the form
  const getModebase = async () => {
    // eslint-disable-next-line no-debugger
    const res = await AxiosAll('get', '/api/function/get-modebase', {});
    if (res?.status === 200) {
      setAboutOption(res?.data?.data);
    } else {
      // setOpen(true)
    }
  };

  // Define default values for the form
  const defaultValues = {
    firstName: '',
    lastName: '',
    jobTitle: '',
    workHour: '',
    about: aboutOption[0]?.id,
    photoUrl: 'https://www.pngitem.com/pimgs/m/30-307416_profile-icon-png-image-free-download-searchpng-employee.png',
    TermAndCondition: false,
    DataAndPolicy: false,
  };

  // Initialize a form using React Hook Form with Yup validation resolver
  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  // Extract form methods and state
  const {
    reset,
    setValue,
    setError,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();

  // Define a function to handle form submission
  const onSubmit = async (values) => {
    const UnauthorizedToken = LoginToken ? `Bearer ${LoginToken}` : userToken;
    console.log('UnauthorizedToken', UnauthorizedToken);
    // const GUID = idToken !== null && jwtDecode(idToken);
    setLoading(true);
    const dataObject = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: email ? email : UserEmail,
      jobTitle: values?.jobTitle,
      photoURL: typeof values?.photoUrl === 'object' ? null : values?.photoUrl,
      about: values?.about,
      days: days,
      // Token: token,
    };

    if (values?.firstName && values?.lastName) {
      try {
        const res = await AxiosAll('post', '/api/user/setup-account', dataObject, UnauthorizedToken);
        if (res?.status === 200) {
          // handleSignUp();
          navigate('/successfull-setup');
          setLoading(false);
        } else {
          setErrorMessage(res?.response?.data?.message || res?.response?.data?.title);
          setLoading(false);
        }
        dispatch(getToken(''));
        dispatch(getEmail(''));
      } catch (error) {
        console.error(error);
        setLoading(false);
        dispatch(getToken(''));
        dispatch(getEmail(''));
      }
    }
  };

  // Define a function to handle image upload
  const handleUpload = async () => {
    // eslint-disable-next-line no-debugger
    setLoading(true);
    setUploadUrl(true);
    const formData = new FormData();
    formData.append('file', values?.photoUrl);
    // eslint-disable-next-line no-debugger
    try {
      const res = await AxiosAll('post', '/api/utilities/upload-image', formData, token, 'multipart/form-data');
      if (res?.status === 200) {
        setValue('photoUrl', res?.data?.data?.imageUrl);
        setUploadUrl(false);
        setLoading(false);
      } else {
        setImageError(res?.response?.data?.message);
        setUploadUrl(false);
        setValue('photoUrl', '');

        const timer1 = setTimeout(() => setImageError(''), 3 * 1000);
        return () => {
          clearTimeout(timer1);
        };
      }
    } catch (error) {
      setUploadUrl(false);
      console.error(error);
    }
  };

  // Effect hook to handle image upload when 'values.photoUrl' changes
  useEffect(() => {
    if (typeof values?.photoUrl === 'object') {
      handleUpload();
    }
  }, [values?.photoUrl]);

  // Define a function to handle dropping an image file
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue(
          'photoUrl',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  }));

  // Effect hook to clear error message after a timeout
  useEffect(() => {
    const timer1 = setTimeout(() => setErrorMessage(null));
    return () => {
      clearTimeout(timer1);
    };
  }, [values?.firstName, values?.lastName, values?.password, values?.confirmPassword]);

  // Effect hook to fetch data when 'idToken' changes
  useEffect(() => {
    getModebase();
  }, []);

  // Define a function to handle single sign-out
  useEffect(() => {
    if (idToken !== null) {
      const GUID = jwtDecode(idToken);
      fetchData(GUID);
    }
  }, [idToken]);

  const handleLogOutSSo = () => {
    setLoading(true);

    instance.logoutRedirect({
      postLogoutRedirectUri: `${window.location.origin}/successfull-setup`,
      mainWindowRedirectUri: `${window.location.origin}`, // Specify the main window redirect URI
    });
  };

  // Define a function to fetch data based on 'USERGUID'
  const fetchData = async (USERGUID) => {
    if (USERGUID) {
      setLoading(true);
      try {
        const response = await AxiosAll(
          'post',
          `/api/user/update-user-status?email=${USERGUID?.emails[0]}&userguid=${USERGUID?.sub}`,
          ''
        );
        if (response.status === 200) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3.5}>
            <Card sx={{ py: 10, px: 1 }}>
              <RHFUploadAvatar
                sx={{ textAlign: 'center' }}
                name="photoUrl"
                maxSize={3145728}
                onDrop={handleDrop}
                user
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />
            </Card>
            {imageError !== '' && <p className="error-message profile_error_message">{imageError}</p>}
          </Grid>

          <Grid item xs={12} md={8.5}>
            <Card sx={{ p: 3 }}>
              <Typography sx={{ px: 2, mb: 2 }} variant="h6" className="heading_14">
                *Required fields
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  rowGap: 3,
                  columnGap: 2,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
              >
                <RHFTextField name="firstName" label="First Name*" />
                <RHFTextField name="lastName" label="Last Name*" />

                <RHFTextField name="jobTitle" label="Job title*" />
                <div> </div>

                <div>
                  <LabelStyle>How do you usually commute to work?</LabelStyle>
                  <RHFSelect style={{ marginTop: '8px' }} fullWidth name="about" className="select_input_field">
                    <option>select mode</option>
                    {aboutOption?.map((data, index) => (
                      <option value={data?.id} key={index}>
                        {data?.name}
                      </option>
                    ))}
                  </RHFSelect>
                </div>
                <div> </div>
              </Box>

              <Typography variant="body2" sx={{ mt: 3, mb: 3, pl: 2 }} className="mainly-working">
                {/* Set default work hours (include commuting hours) */}
                Please set your working hours (include commuting hours):
              </Typography>
              {days?.length && days[0]?.timeGroupId === 1 ? (
                <div className="edit_hour_section">
                  <card className="sethour-new-box">
                    <div className="time-new-box">
                      <Typography variant="subtitle1">
                        {days?.length ? getDaysOfWeek(firstDayOfWeek, lastDayOfWeek) : 'Monday - Friday'}
                      </Typography>

                      <Typography variant="subtitle1">
                        {days?.length
                          ? `${days[0]?.startTime.replace(/^0|:00/g, '')}- ${days[0]?.endTime.replace(/^0|:00/g, '')}`
                          : '7:00 am - 7:00 pm'}
                      </Typography>
                    </div>

                    <WorkingHoursDialog intialDay={true} setNewDays={setDays} newdays={days} />
                  </card>
                </div>
              ) : days?.length && days[0]?.timeGroupId === 3 ? (
                <div className="custom-new-box">
                  <div className="custom-card">
                    {days?.length &&
                      days?.map((data, key) => {
                        return (
                          <div className="custom-time-box" key={key}>
                            <Typography variant="subtitle1" sx={{ mb: 0.8 }}>
                              {data?.day === 2
                                ? 'Mon'
                                : data?.day === 3
                                ? 'Tue'
                                : data?.day === 4
                                ? 'Wed'
                                : data?.day === 5
                                ? 'Thu'
                                : data?.day === 6
                                ? 'Fri'
                                : data?.day === 7
                                ? 'Sat'
                                : 'Sun'}
                            </Typography>
                            <Typography variant="body">
                              {' '}
                              {data?.startTime.replace(/^0|:00/g, '')}-{data?.endTime.replace(/^0|:00/g, '')}
                            </Typography>
                          </div>
                        );
                      })}
                  </div>
                  <WorkingHoursDialog setNewDays={setDays} newdays={days} />
                </div>
              ) : (
                <div className="edit_hour_section">
                  <card className="sethour-new-box">
                    <div className="time-new-box">
                      <Typography variant="subtitle1">Monday - Friday</Typography>
                      <Typography variant="subtitle1">7:00 am - 7:00 pm</Typography>
                    </div>

                    <WorkingHoursDialog setNewDays={setDays} newdays={days} />
                  </card>
                </div>
              )}

              <FormControlLabel
                sx={{ mt: 2 }}
                control={
                  <Checkbox
                    id={'my-checkbox'}
                    checked={values?.TermAndCondition}
                    onChange={(e) => {
                      setValue('TermAndCondition', e.target.checked);
                    }}
                  />
                }
                label={
                  <Typography variant="overline" className="policy-heading">
                    I have read and accept the TripShift Ltd{' '}
                    <a
                      href="https://tripshift.eco/terms-conditions/"
                      className={mode?.themeMode === 'dark' ? 'link-tag-text-dark' : 'link-tag-text'}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {' '}
                      Terms & Conditions
                    </a>
                  </Typography>
                }
              />
              {errors.TermAndCondition && values?.TermAndCondition === false && (
                <p className="error-message">{errors.TermAndCondition?.message}</p>
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    id={'my-checkbox1'}
                    checked={values?.DataAndPolicy}
                    onChange={(e) => {
                      setValue('DataAndPolicy', e.target.checked);
                    }}
                  />
                }
                label={
                  <Typography variant="overline" className="policy-heading">
                    I have read and consent to the TripShift Ltd
                    <a
                      href="https://tripshift.eco/privacy-policy/"
                      className={mode?.themeMode === 'dark' ? 'link-tag-text-dark' : 'link-tag-text'}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {' '}
                      Data Policy
                    </a>
                  </Typography>
                }
              />
              {errors.DataAndPolicy && values?.DataAndPolicy === false && (
                <p className="error-message">{errors.DataAndPolicy?.message}</p>
              )}

              <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                {uploadurl ? (
                  <LoadingButton type="submit" variant="contained" disabled>
                    Register
                  </LoadingButton>
                ) : (
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    Register
                  </LoadingButton>
                )}
              </Stack>
            </Card>
            <div>
              {errorMessage ? (
                <Alert severity="error" sx={{ mb: 3 }}>
                  <strong>{errorMessage}</strong>
                </Alert>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
