/* eslint-disable import/no-unresolved */
/* eslint-disable no-dupe-else-if */
/* eslint-disable no-unreachable-loop */
// @mui
import { Card, Container, Grid, Typography } from '@mui/material';
// routes
// hooks
// eslint-disable-next-line import/no-unresolved

// sections
// eslint-disable-next-line import/named
// eslint-disable-next-line import/order, import/no-unresolved
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import useSettings from 'src/hooks/useSettings';
import Image from 'src/components/Image';
import { imagesAll } from 'src/assets/Images_index';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function SuccessReport() {
  const { themeStretch } = useSettings();

  return (
    <Page title="Request Report" className="manage-member">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Request Report"
          links={[
            { name: 'TripShift Platform', href: '/dashboard' },
            { name: 'Report', href: '/report' },
            { name: 'Request Report' },
          ]}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={10}>
            <Card sx={{ p: 3, boxShadow: 'none', border: '1px solid #DFE3E8', borderRadius: '0.625rem' }}>
              <Typography variant="h4" sx={{ mb: 2, color: 'primary.main', textAlign: 'center' }}>
                {' '}
                Success! Your request has been submitted{' '}
              </Typography>

              <Typography variant="body1" sx={{ mb: 0, color: 'text.secondary', textAlign: 'center' }}>
                {' '}
                TripShift has received your request, and we will be in touch with any questions. You should receive your
                report to your email address within 7 days.{' '}
              </Typography>
              <div className="error404">
                <Image src={imagesAll.seo} className="error404_img2" style={{ marginBottom: '10px' }} />
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
