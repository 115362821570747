/* eslint-disable no-debugger */
/* eslint-disable import/named */
/* eslint-disable import/no-unresolved */

import { Container, Box, styled } from '@mui/material';
// routes
// hooks
// eslint-disable-next-line import/no-unresolved
import LoadingOverlay from 'react-loading-overlay';
import { useState } from 'react';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';
import { useNavigate } from 'react-router';
import { useMsal } from '@azure/msal-react';

import useTabs from '../hooks/useTabs';
import useSettings from '../hooks/useSettings';
// _mock_
// components

import Page from '../components/Page';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
// sections
import { AccountGeneral } from '../sections/@dashboard/user/account';
import SetupAccount from './SetupAccount';

// ----------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));
export default function Profile() {
  const { instance, accounts } = useMsal();

  const params = new URLSearchParams(window.location.search);
  const Usertoken = params.get('token');
  const email = params.get('email');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { themeStretch } = useSettings();

  const { currentTab, onChangeTab } = useTabs('general');
  return (
    <Page title="User: Account Settings" className="publicprofile">
      <LoadingOverlay
        // eslint-disable-next-line react/jsx-boolean-value
        active={loading}
        spinner
      >
        <LogoOnlyLayout />
        {email === null && Usertoken === null ? (
          <Container className="profile_section" sx={{}}>
            <ContentStyle className="container-style-profile">
              <>
                <HeaderBreadcrumbs
                  heading="Please complete your TripShift profile"
                  links={[{ name: 'TripShift', href: '/' }, { name: 'Member', href: '/' }, { name: 'New Members' }]}
                />
              </>
              <AccountGeneral Accesstoken={Usertoken} setLoading={setLoading} useremail={email} />

              <Box sx={{ mb: 5 }} />
            </ContentStyle>
          </Container>
        ) : (
          <SetupAccount />
        )}
      </LoadingOverlay>
    </Page>
  );
}
