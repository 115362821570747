/* eslint-disable no-debugger */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { useEffect, useState } from 'react';
// @mui
import { Button, Dialog, DialogActions, DialogContent, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import AxiosAll from 'src/service/AxiosAll';
import { LoadingButton } from '@mui/lab';
import DeleteSuccess from './DeleteSuccess';
// components
// eslint-disable-next-line import/no-unresolved
// import Iconify from '../../../../components/Iconify';

export default function EditLicenseDailog({
  open,
  editnoteValue,
  setOpen,
  editLisenceValue,
  setEditLisenceValue,
  Value,
  getCustomerDetail,
  id,
  note,
}) {
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);
  const theme = useTheme();
  const { userDetails, token } = useSelector((state) => state.loginReducer);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [updateValue, setUpdateValue] = useState();
  const [loading, setLoading] = useState(false);
  const [opensuceessModal, setOpenSuccessModal] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    if (e.target.value !== '0') {
      setUpdateValue(e.target.value);
    }
  };

  const onSubmit = () => {
    setOpen(false);
  };

  useEffect(() => {
    setUpdateValue(Value);
  }, [Value, open]);

  const UpdateCustomerDetail = async () => {
    setLoading(true);
    const data = {
      companyId: id,
      licencePurchased: editLisenceValue !== '' ? editLisenceValue : updateValue,
      note: editnoteValue !== '' ? editnoteValue : note,
    };
    const res = await AxiosAll('post', `/api/Company/update-customer`, data, token);

    if (res?.status === 200) {
      setLoading(false);
      onSubmit();
      setOpenSuccessModal(true);
    }
    setLoading(false);
  };
  return (
    <>
      {/* //delete confirmation  */}
      <Dialog open={open} className="edit-licences">
        <DialogContent className="delete-alert">
          {/* <DialogContentText id="alert-dialog-description"> */}
          <Typography
            variant="body1"
            className={mode?.themeMode === 'dark' ? 'heading_18_dark heading_18 boldttext' : 'heading_18 boldttext'}
          >
            New total for Customer Licences:
          </Typography>
          <input
            name="edit"
            type="number"
            min={'1'}
            onKeyDown={(e) => {
              if (
                e.keyCode === 107 ||
                e.keyCode === 109 ||
                e.keyCode === 190 ||
                e.keyCode === 189 ||
                e.keyCode === 187 ||
                e.keyCode === 69
              ) {
                e.preventDefault();
              }
            }}
            className="editLisence"
            value={updateValue}
            onChange={handleChange}
          />
          {Value > updateValue ? (
            <p className="error-message license-error">{'The number cannot be less than the licenses purchased'}</p>
          ) : (
            ''
          )}
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions className="btn-section-alert" style={{ paddingTop: 0 }}>
          {Value > updateValue ? (
            <LoadingButton variant="contained" disabled size="medium" className="confirm-btn licences-btn">
              Confirm
            </LoadingButton>
          ) : (
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={UpdateCustomerDetail}
              size="medium"
              className="confirm-btn next-select-btn"
            >
              Confirm
            </LoadingButton>
          )}
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            className="cancle_btn new-cancle-btn"
            size="medium"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteSuccess
        opensuceessModal={opensuceessModal}
        getCustomerDetail={getCustomerDetail}
        setOpenSuccessModal={setOpenSuccessModal}
        LicenceUpdate={true}
      />
    </>
  );
}
