/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
/* eslint-disable no-debugger */
import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { TableCell, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// utils
// components

import AxiosAll from 'src/service/AxiosAll';
import { useSelector } from 'react-redux';
import Highlight from 'react-highlighter';
// eslint-disable-next-line import/no-named-as-default
import AssignTeamLeadFromTeam from 'src/sections/mui/dialog/AssignTeamLeadFromTeam';

// ----------------------------------------------------------------------

AddTeamTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function AddTeamTableRow({
  row,
  filterName,
  selected,
  id,
  onSelectRow,
  setFilterData,
  setTableCurrentData,
  onViewRow,
  onEditRow,
  teamNameTeamFlow,
  onDeleteRow,
  tableData,
  setTableData,
}) {
  const theme = useTheme();
  const { userDetails, token } = useSelector((state) => state.loginReducer);
  const [openAssign, setOpenAssign] = useState(false);
  const { firstName, lastName, teamName, name, userEmail, userId, email, status, invoiceTo, level } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleUpdateRole = async () => {
    if (userId) {
      const dataObject = {
        userId: userId?.userId,
        teamId: id,
        roleId: 2,
      };
      const res = await AxiosAll('post', '/api/user/update-team-lead-role', [dataObject], token);
      if (res?.status === 200) {
        // setReload("7")
      } else {
        // setOpen(true)
      }
    }
  };

  const memberName = name === ' ' ? userEmail : name;
  return (
    <>
      <TableRow hover className="select-hover" selected={selected}>
        <TableCell padding="checkbox">{/* <Checkbox checked={selected} onClick={onSelectRow} /> */}</TableCell>
        <TableCell align="left" className="team-name-tb">
          <Highlight search={filterName}>{firstName === null ? email : `${firstName} ${lastName}`}</Highlight>
        </TableCell>
        <TableCell align="left">{teamName}</TableCell>
        <TableCell align="left">
          <div className="hoverselectdiv">
            <buttton className="select-btn-form" onClick={() => setOpenAssign(true)}>
              Select
            </buttton>
          </div>
        </TableCell>
      </TableRow>
      <AssignTeamLeadFromTeam
        open={openAssign}
        teamId={id}
        userName={`${firstName} ${lastName}`}
        setOpen={setOpenAssign}
        userid={userId}
        teamName={teamNameTeamFlow}
      />
    </>
  );
}
