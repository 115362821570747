/* eslint-disable no-debugger */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import { useState } from 'react';
// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import Iconify from 'src/components/Iconify';
import AxiosAll from 'src/service/AxiosAll';
// import Typography from 'src/theme/overrides/Typography';

// ----------------------------------------------------------------------

export default function UploadCSVDialogs({ setOpenEmailModal, setReload }) {
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [csvFile, setCsvFile] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { userDetails, userRole, token } = useSelector((state) => state.loginReducer);
  const [validationError, setValidationError] = useState(null);

  const handleChange = (e) => {
    setError(null);
    if (e.target.files[0]) {
      setCsvFile(e.target.files[0]);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
    setOpenAlert(false);
    setValidationError(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleDownloadCSV = () => {
  //   window.open('https://tripshifttestmsalportal.azurewebsites.net/StaticFiles/templates/BulkUploadTemplated.csv');
  // };

  const onSubmit = async () => {
    // const filterString = emailValue?.replace(/ /g, "").replace(/(\r\n|\n|\r)+/gm, "", '')

    if (csvFile !== '') {
      const formData = new FormData();
      formData.append('file', csvFile);
      setLoading(true);

      const res = await AxiosAll('post', '/api/user/uploadcsv', formData, token, 'text/csv');
      if (res?.data?.data?.isSuccess) {
        setLoading(false);
        setReload(true);
        setOpen(false);
      } else {
        setLoading(false);
        setError(res?.response?.data?.message);
        handletimeout();
        console.log(res?.response?.data?.message);
      }
    }
  };

  const handletimeout = () => {
    const timer1 = setTimeout(() => setError(null), 10 * 1000);
    return () => {
      clearTimeout(timer1);
    };
  };

  // emails validator

  return (
    <>
      {' '}
      <Button
        size="medium"
        variant="contained"
        className="newmemberbtn"
        // startIcon={<Iconify icon={'eva:plus-fill'} />}
        onClick={handleClickOpen}
        sx={{ boxShadow: 'none' }}
      >
        Upload CSV
      </Button>
      <Dialog open={open} onClose={handleClose} className="addmemberdialogs">
        <Grid>
          <Grid item xs={2} className="cross_section textright" sx={{ mr: 4, mt: 2 }}>
            <Iconify
              icon={'akar-icons:cross'}
              className="alert_cross"
              onClick={handleClose}
              style={{ cursor: 'pointer' }}
            />
          </Grid>
        </Grid>
        <DialogTitle sx={{ pl: 4, mb: 2, pt: 0, textAlign: 'center' }}>Add Members via CSV </DialogTitle>

        <div style={{ textAlign: 'center', marginLeft: '5vw', marginTop: '10px' }}>
          <input type="file" id="myFile" name="filename" accept=".csv" onChange={handleChange} />
        </div>

        <DialogActions>
          <DialogContent className="addmembers">
            {/* <DialogContentText   > */}

            {/* <LoadingButton
              sx={{ ml: 0, mb: 0 }}
              variant="contained"
              className="download-template"
              onClick={handleDownloadCSV}
            >
              Download Template
            </LoadingButton> */}
            {/* </DialogContentText> */}
          </DialogContent>
          {loading ? (
            <LoadingButton type="submit" variant="contained" loading>
              Invite Members
            </LoadingButton>
          ) : error || csvFile === '' ? (
            <LoadingButton variant="contained" disabled>
              Invite Members
            </LoadingButton>
          ) : (
            <LoadingButton onClick={onSubmit} variant="contained" loading={false}>
              Invite Members
            </LoadingButton>
          )}
        </DialogActions>

        <div>
          {error !== null ? (
            <Alert severity="error" sx={{ mb: 3 }}>
              <strong>{error}</strong>
            </Alert>
          ) : null}
        </div>
      </Dialog>
    </>
  );
}
