// @mui
import { Grid, Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

export default function DashboardSkeleton() {
  return (
    <div className='dashboardskeleton'>
    <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
        <Skeleton variant="rectangular" width="100%" className="imagegrid"  />
      </Grid>
      <Grid item xs={12} md={4}>
      <Skeleton variant="rectangular" width="100%"  className="imagegrid"/>
      </Grid>
      <Grid item xs={12} >
      <Skeleton  variant="text" width="100%"  className="textgrid"/>
      </Grid>
      <Grid item xs={12} md={4}>
      <Skeleton variant="rectangular" width="100%"  className="imagegrid"/>
      </Grid>
      <Grid item xs={12} md={4}>
      <Skeleton variant="rectangular" width="100%"  className="imagegrid"/>
      </Grid>
      <Grid item xs={12} md={4}>
      <Skeleton variant="rectangular" width="100%"  className="imagegrid"/>
      </Grid>
      <Grid item xs={12} >
      <Skeleton  variant="text" width="100%"  className="textgrid"/>
      </Grid>
      <Grid item xs={12} md={6} lg={8}>
      <Skeleton variant="rectangular" width="100%"  className="imagegrid"/>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
      <Skeleton variant="rectangular" width="100%"  className="imagegrid"/>
      </Grid>
    </Grid>
    </div>
  );
}
