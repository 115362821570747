// main server controller
const EnvType = (Env) => {
  console.log('Env', Env);
  switch (Env) {
    case 1:
      return process.env.REACT_APP_STAGIN_SERVER;

    case 2:
      return process.env.REACT_APP_PRE_PRODUCTION_SERVER;

    case 3:
      return process.env.REACT_APP_PRODUCTION_SERVER;

    default:
      return process.env.REACT_APP_DEV_SERVER;
  }
};

export default {
  currentEnv: EnvType(process.env.REACT_APP_ENV_TYPE),
};
