/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { useEffect, useState } from 'react';
// @mui
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Iconify from 'src/components/Iconify';
import AxiosAll from 'src/service/AxiosAll';
// components
// eslint-disable-next-line import/no-unresolved
// import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

const emails = ['username@gmail.com', 'user02@gmail.com'];

export default function AdminTeamLeadDelete({
  openDeleteModal,
  selectedRow,
  tableData,
  multiSelect,
  setSelected,
  row,
  setReload,
  setOpenDeleteModal,
  setDeleteIds,
  DeleteUserIds,
  teamId,
  teamName,
}) {
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);
  const theme = useTheme();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [opensuceessModal, setOpenSuccessModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState(emails[1]);
  const [checked, setChecked] = useState(true);
  const { userDetails, userRole, token } = useSelector((state) => state.loginReducer);
  const navigate = useNavigate();
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClickOpen = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseSuccess = () => {
    if (multiSelect) {
      setOpenSuccessModal(false);
      setReload(true);
      setSelected([]);
    } else {
      setOpenSuccessModal(false);
      setReload(true);
    }
  };

  useEffect(() => {
    const timer1 = setTimeout(() => setOpenSuccessModal(false), 3 * 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  const handleClose = (value) => {
    setOpenDeleteModal(false);
    setSelectedValue(value);
  };

  const onSubmit = async () => {
    // eslint - disable - next - line no -debugger
    if (teamId) {
      const dataObject = {
        teamId: [teamId],
        RoleId: userRole?.data,
      };
      const res = await AxiosAll('post', '/api/team/delete-team', dataObject, token);
      if (res?.status === 200) {
        setDeleteIds([teamId]);
        setOpenDeleteModal(false);
        setOpenSuccessModal(true);
      }
    } else {
      const dataObject = {
        teamId: selectedRow,
      };
      const res = await AxiosAll('post', '/api/team/delete-team', dataObject, token);
      if (res?.status === 200) {
        setDeleteIds(selectedRow);
        setOpenSuccessModal(true);
        setOpenDeleteModal(false);
      }
    }
  };

  return (
    <>
      {/* <Box sx={{ textAlign: 'left' }}> */}
      <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <DialogContent style={{ textAlign: 'left' }}>
          {multiSelect ? (
            <>
              <Typography variant="body2" className="heading_16 boldttext" sx={{ pl: 1 }}>
                You are about to delete all selected teams. Would you like to proceed?
              </Typography>
            </>
          ) : (
            <Typography variant="body2" className="heading_16 boldttext" sx={{ pb: 2 }}>
              You are about to delete {teamName}. Would you like to proceed?
            </Typography>
          )}
        </DialogContent>

        <DialogActions className="delete-end-side" style={{ textAlign: 'left' }}>
          <Button
            onClick={() => {
              onSubmit();
            }}
            variant="contained"
            size="large"
          >
            Confirm
          </Button>

          <Button onClick={() => setOpenDeleteModal(false)} variant="contained" className="cancle_btn" size="large">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* </Box> */}

      <Dialog open={opensuceessModal} onClose={handleClose} className="toast-dialogs">
        <Grid container spacing={2} className="MemberAlert">
          <Grid item xs={2}>
            <div className="checkicon-bg">
              <Iconify icon={'ant-design:check-circle-filled'} width={24} height={24} className="" />
            </div>
          </Grid>
          <Grid item xs={8.5}>
            {multiSelect ? (
              <div className="email-value">
                <div className="email-value">
                  <Typography variant="body2" className="heading_16 " sx={{ pl: 1 }}>
                    All selected teams have been deleted
                    <br />
                    from the TripShift Platform.{' '}
                  </Typography>
                </div>
              </div>
            ) : (
              <div className="email-value">
                <div className="email-value">
                  <Typography variant="body2" className="heading_16 ">
                    {teamName}

                    <span style={{ paddingLeft: '0.2vw' }}>
                      has been deleted
                      <br />
                      from the TripShift Platform.
                    </span>
                  </Typography>
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={1.5} className="cross_section">
            <Iconify icon={'akar-icons:cross'} className="alert_cross" onClick={handleCloseSuccess} />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
