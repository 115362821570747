/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-debugger */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { useState } from 'react';
// @mui
import { Alert, Button, Dialog, DialogActions, DialogContent, Snackbar, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import AxiosAll from 'src/service/AxiosAll';
import { LoadingButton } from '@mui/lab';
import DeleteSuccess from './DeleteSuccess';
// components

// ----------------------------------------------------------------------

const emails = ['username@gmail.com', 'user02@gmail.com'];

export default function AddCustomerConfirmDailog({ open, userid, data, setData, setOpen, teamId, userName }) {
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);
  const theme = useTheme();
  const [message, setMessage] = useState();
  const [openError, setOpenError] = useState(false);
  const [type, setType] = useState('');
  const [loading, setLoading] = useState(false);
  const [opensuceessModal, setOpenSuccessModal] = useState(false);
  const { token } = useSelector((state) => state.loginReducer);

  const handleUpdateRole = async () => {
    setLoading(true);
    const res = await AxiosAll('post', '/api/user/primary-admin-register', data, token);
    if (res?.status === 200) {
      setLoading(false);

      setType('success');
      setMessage(res?.data?.message);
      setOpen(false);
      setOpenSuccessModal(true);
    } else {
      setLoading(false);

      setType('error');
      setOpenError(true);
      setMessage(res?.response?.data?.message);
    }
  };

  return (
    <>
      {/* //delete confirmation  */}
      <Dialog open={open} className="note-dialogs">
        <DialogContent className="delete-alert">
          <Typography
            variant="body1"
            className={mode?.themeMode === 'dark' ? 'heading_18_dark heading_18 boldttext' : 'heading_18 boldttext'}
          >
            NOTE: You are about to send an email invite to the Primary Admin for this customer: {data?.email}. This will
            allow them to set up their credentials and access the TripShift Platform. Confirm?
          </Typography>
        </DialogContent>
        <DialogActions className="btn-section-alert save-chnage-new">
          <LoadingButton
            onClick={handleUpdateRole}
            loading={loading}
            variant="contained"
            size="medium"
            className="confirm-btn"
          >
            Confirm
          </LoadingButton>
          <Button onClick={() => setOpen(false)} variant="contained" className="cancle_btn" size="medium">
            Cancel
          </Button>
        </DialogActions>
        <Snackbar
          open={openError}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={3000}
          onClose={() => setOpenError(false)}
        >
          <Alert onClose={() => setOpenError(false)} severity={type || 'success'} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      </Dialog>
      <DeleteSuccess opensuceessModal={opensuceessModal} setOpenSuccessModal={setOpenSuccessModal} AddCustomer={true} />
    </>
  );
}
