/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
// @mui
import { Box, Dialog, Grid, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Iconify from 'src/components/Iconify';
// components
// eslint-disable-next-line import/no-unresolved
// import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

export default function DeleteSuccess({
  setOpenSuccessModal,
  dataFiltered,
  opensuceessModal,
  setSelected,
  setReload,
  row,
  CustomerName,
  multidelete,
  selectedRow,
  successRemove,
  getCustomerList,
  LicenceUpdate,
  getCustomerDetail,
  AddCustomer,
  AddTeamAllocate,
  CustomerRestoreName,
  deleteUser,
}) {
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);
  const navigate = useNavigate();
  const theme = useTheme();
  const { userDetails } = useSelector((state) => state.loginReducer);

  const handleCloseSuccess = () => {
    // eslint-disable-next-line no-debugger
    if (row?.userId) {
      setOpenSuccessModal(false);
      setReload(true);
      // navigate("/members")
    } else if (deleteUser && CustomerName) {
      setOpenSuccessModal(false);
      navigate('/recyclebin', { state: { reload: true } });
    }
    if (CustomerName) {
      setOpenSuccessModal(false);
      getCustomerList();
    } else if (LicenceUpdate) {
      setOpenSuccessModal(false);
      getCustomerDetail();

      // navigate("/members")
    } else if (AddCustomer) {
      setOpenSuccessModal(false);
      navigate('/customer');
    } else if (AddTeamAllocate) {
      setOpenSuccessModal(false);
      navigate('/teams');
    } else if (CustomerRestoreName) {
      navigate('/recyclebin', { state: { reload: true } });
      setOpenSuccessModal(false);
    } else {
      setOpenSuccessModal(false);
      setReload(true);
      // setSelected([]);
    }
  };

  const rowData = dataFiltered?.filter((person) => selectedRow?.includes(person?.userId));

  const name = row?.name === ' ' ? row?.userEmail : row?.name;

  return (
    <>
      <Box sx={{ textAlign: 'left' }}>
        <Dialog open={opensuceessModal} onClose={() => handleCloseSuccess()} className="toast-dialogs">
          {successRemove ? (
            <Grid container spacing={2} className="MemberAlert">
              <Grid item xs={2}>
                <div className="checkicon-bg">
                  <Iconify icon={'ant-design:check-circle-filled'} width={24} height={24} className="" />
                </div>
              </Grid>
              <Grid item xs={8.5}>
                <div className="email-value">{`${name} has been removed as Team Lead from the TripShift Platform`}</div>
              </Grid>
              <Grid item xs={1.5} className="cross_section">
                <Iconify icon={'akar-icons:cross'} className="alert_cross" onClick={handleCloseSuccess} />
              </Grid>
            </Grid>
          ) : CustomerName ? (
            <Grid container spacing={2} className="MemberAlert">
              <Grid item xs={2}>
                <div className="checkicon-bg">
                  <Iconify icon={'ant-design:check-circle-filled'} width={24} height={24} className="" />
                </div>
              </Grid>
              <Grid item xs={8.5}>
                <div className="email-value">
                  {CustomerName} has been successfully deleted from the Tripshift Platform.
                </div>
              </Grid>
              <Grid item xs={1.5} className="cross_section">
                <Iconify icon={'akar-icons:cross'} className="alert_cross" onClick={() => handleCloseSuccess()} />
              </Grid>
            </Grid>
          ) : LicenceUpdate ? (
            <Grid container spacing={2} className="MemberAlert">
              <Grid item xs={2}>
                <div className="checkicon-bg">
                  <Iconify icon={'ant-design:check-circle-filled'} width={24} height={24} className="" />
                </div>
              </Grid>
              <Grid item xs={8.5}>
                <div className="email-value">Licences updated successfully</div>
              </Grid>
              <Grid item xs={1.5} className="cross_section">
                <Iconify icon={'akar-icons:cross'} className="alert_cross" onClick={() => handleCloseSuccess()} />
              </Grid>
            </Grid>
          ) : AddCustomer ? (
            <Grid container spacing={2} className="MemberAlert">
              <Grid item xs={2}>
                <div className="checkicon-bg">
                  <Iconify icon={'ant-design:check-circle-filled'} width={24} height={24} className="" />
                </div>
              </Grid>
              <Grid item xs={8.5}>
                <div className="email-value">Customer added successfully</div>
              </Grid>
              <Grid item xs={1.5} className="cross_section">
                <Iconify icon={'akar-icons:cross'} className="alert_cross" onClick={() => handleCloseSuccess()} />
              </Grid>
            </Grid>
          ) : AddTeamAllocate ? (
            <Grid container spacing={2} className="MemberAlert">
              <Grid item xs={2}>
                <div className="checkicon-bg">
                  <Iconify icon={'ant-design:check-circle-filled'} width={24} height={24} className="" />
                </div>
              </Grid>
              <Grid item xs={8.5}>
                <div className="email-value">Team lead allocated successfully</div>
              </Grid>
              <Grid item xs={1.5} className="cross_section">
                <Iconify icon={'akar-icons:cross'} className="alert_cross" onClick={() => handleCloseSuccess()} />
              </Grid>
            </Grid>
          ) : CustomerRestoreName ? (
            <Grid container spacing={2} className="MemberAlert">
              <Grid item xs={2}>
                <div className="checkicon-bg">
                  <Iconify icon={'ant-design:check-circle-filled'} width={24} height={24} className="" />
                </div>
              </Grid>
              <Grid item xs={8.5}>
                {CustomerRestoreName} has been successfully restored in the Tripshift Platform.
              </Grid>
              <Grid item xs={1.5} className="cross_section">
                <Iconify icon={'akar-icons:cross'} className="alert_cross" onClick={() => handleCloseSuccess()} />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2} className="MemberAlert">
              <Grid item xs={2}>
                <div className="checkicon-bg">
                  <Iconify icon={'ant-design:check-circle-filled'} width={24} height={24} className="" />
                </div>
              </Grid>
              <Grid item xs={8.5}>
                <div className="email-value">
                  {dataFiltered
                    ? 'All selected members have been deleted from the TripShift Platform.'
                    : `${name} has been deleted from the TripShift Platform`}
                </div>
              </Grid>
              <Grid item xs={1.5} className="cross_section">
                <Iconify icon={'akar-icons:cross'} className="alert_cross" onClick={() => handleCloseSuccess()} />
              </Grid>
            </Grid>
          )}
        </Dialog>
      </Box>
    </>
  );
}
