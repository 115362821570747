/* eslint-disable import/no-unresolved */
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, Checkbox, FormControlLabel, Stack, Typography, useTheme } from '@mui/material';
// hooks
// import useAuth from '../../../hooks/useAuth';
// import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/order
// eslint-disable-next-line import/order
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addLogin, updateRole } from 'src/redux/actions/login';
import AxiosAll from 'src/service/AxiosAll';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';
// import Typography from 'src/theme/overrides/Typography';

// ----------------------------------------------------------------------
const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

export default function WelcomeTripshiftForm({ setOpen }) {
  const { userDetails, token } = useSelector((state) => state.loginReducer);

  const [employeesCountOption, setEmployeesCountOption] = useState([]);
  const getRange = async () => {
    // eslint-disable-next-line no-debugger
    const res = await AxiosAll('get', '/api/EmployeeRange/get-employeerange', {}, token);
    if (res?.status === 200) {
      setEmployeesCountOption(res?.data?.data);
    } else {
      // setOpen(true)
    }
  };
  useEffect(() => {
    getRange();
  }, []);

  // Initialize and set up various variables and hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);
  const theme = useTheme();
  // Define validation schema and default form values
  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    job: Yup.string().required('Job title  required'),
    employeesCount: Yup.string().required('Employee count required'),
    businessSector: Yup.string().required('Business sector is required'),
    TermAndCondition: Yup.bool().oneOf([true], 'Please accept the Terms &    Conditions'),
    DataAndPolicy: Yup.bool().oneOf([true], 'Please accept the Data Policy'),
  });

  // Set up form methods and values
  const defaultValues = {
    firstName: '',
    lastName: '',
    job: '',
    employeesCount: '',
    businessSector: '',
    TermAndCondition: false,
    DataAndPolicy: false,
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();

  // Handle form submission
  const onSubmit = async (data) => {
    const Detail = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      jobTitle: data?.job,
      noOfEmployees: data?.employeesCount,
      busniessSector: data?.businessSector,
    };
    try {
      // eslint-disable-next-line no-debugger
      if (data?.firstName && data?.lastName && data?.job && data?.employeesCount && data?.businessSector) {
        const response = await AxiosAll('post', '/api/user/update-profile', Detail, token);
        if (response.data) {
          dispatch(addLogin(response));
          dispatch(updateRole(response?.data?.data?.roleId));

          setOpen(false);
          // await navigate('/dashboard');
        } else {
          // console.log('error', response);
        }
      }
    } catch (error) {
      console.error(error);
      reset();
      // if (isMountedRef.current) {
      //   setError('afterSubmit', { ...error, message: error.message });
      // }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {/* <SocialLogin /> */}

      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}> */}
        <RHFTextField name="firstName" label="First name *" />
        <RHFTextField name="lastName" label="Last name *" />
        {/* </Stack> */}

        <RHFTextField name="job" label="Job title *" />
        {/* <RHFTextField type="number" name="employeesCount" label="How many employees do you have in your business? *" /> */}
        <div>
          <LabelStyle>How many employees do you have in your business? *</LabelStyle>
          <RHFSelect
            fullWidth
            name="employeesCount"
            // label="How many employees do you have in your business? *"
            className="select_input_field"
            placeholder="Select  Range"
            InputLabelProps={{ shrink: true }}
            // SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
          >
            <option value={''} disabled className="select-range-text">
              Select Range
            </option>

            {employeesCountOption?.map((data, index) => (
              <option value={data?.id} key={index}>
                {data?.name}
              </option>
            ))}
          </RHFSelect>
        </div>
        <RHFTextField name="businessSector" label="What sector is your business in? *" />
        <FormControlLabel
          control={
            <Checkbox
              id={'my-checkbox'}
              checked={values?.TermAndCondition}
              onChange={(e) => {
                setValue('TermAndCondition', e.target.checked);
              }}
            />
          }
          label={
            <Typography variant="overline" className="policy-heading">
              I have read and accept the TripShift Ltd{' '}
              <a
                href="https://tripshift.eco/terms-conditions/"
                className={mode?.themeMode === 'dark' ? 'link-tag-text-dark' : 'link-tag-text'}
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                Terms & Conditions
              </a>
            </Typography>
          }
        />
        {errors.TermAndCondition && values?.TermAndCondition === false && (
          <p className="error-message">{errors.TermAndCondition?.message}</p>
        )}

        <FormControlLabel
          style={{ marginTop: '0px' }}
          control={
            <Checkbox
              id={'my-checkbox1'}
              checked={values?.DataAndPolicy}
              onChange={(e) => {
                setValue('DataAndPolicy', e.target.checked);
              }}
            />
          }
          label={
            <Typography variant="overline" className="policy-heading">
              I have read and consent to the TripShift Ltd
              <a
                href="https://tripshift.eco/privacy-policy/"
                className={mode?.themeMode === 'dark' ? 'link-tag-text-dark' : 'link-tag-text'}
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                Data Policy
              </a>
            </Typography>
          }
        />
        {errors.DataAndPolicy && values?.DataAndPolicy === false && (
          <p className="error-message">{errors.DataAndPolicy?.message}</p>
        )}

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Submit
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
