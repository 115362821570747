import {
  LOGIN,
  LOGOUT,
  UPDATEROLE,
  UPDATEIMAGE,
  TOKEN,
  EXPIREON,
  UPDATENAME,
  EMAIL,
  USERTOKEN,
  RESET,
} from '../constant';
// action CRUD for Login data

export const addLogin = (logindata) => ({ type: LOGIN, data: logindata });

export const updateRole = (logindata) => ({ type: UPDATEROLE, data: logindata });
export const updateImage = (logindata) => ({ type: UPDATEIMAGE, data: logindata });
export const updateName = (logindata) => ({ type: UPDATENAME, data: logindata });
export const getAccessToken = (token) => ({ type: TOKEN, data: token });
export const getExpireTime = (token) => ({ type: EXPIREON, data: token });
export const getToken = (token) => ({ type: USERTOKEN, data: token });
export const getEmail = (token) => ({ type: EMAIL, data: token });

export const deleteLogin = () => ({ type: LOGOUT });

export const reset = () => ({ type: RESET });
