/* eslint-disable import/no-duplicates */
/* eslint-disable import/no-unresolved */

import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// @mui
import { Card, Container, Typography, Stack, Grid, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import useSettings from 'src/hooks/useSettings';
import AxiosAll from 'src/service/AxiosAll';
import Image from 'src/components/Image';
import { imagesAll } from 'src/assets/Images_index';
import { LoadingButton } from '@mui/lab';
import 'react-daterange-picker/dist/css/react-calendar.css';
import moment from 'moment';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import DatePickerDialog from 'src/sections/mui/dialog/DatePicker';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function RequestReport() {
  const theme = useTheme();
  const { userRole, token } = useSelector((state) => state.loginReducer);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [error, setError] = useState(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [btnshow, setBtnShow] = useState(false);
  // Effect to reset 'toDate' when 'fromDate' changes
  useEffect(() => {
    if (fromDate) {
      setToDate(null);
    } else {
      setToDate(null);
    }
  }, [fromDate]);

  // Function to handle report generation
  const handleReport = async () => {
    setLoading(true);
    const res = await AxiosAll(
      'post',
      `/api/Company/report-request?startdate=${moment(selectedDate[0]?.startDate).format(
        'DD/MM/YYYY'
      )}&enddate=${moment(selectedDate[0]?.endDate).format('DD/MM/YYYY')}`,
      {},
      token
    );
    if (res?.status === 200) {
      setLoading(false);
      navigate('/success-report');
    }
    setLoading(false);
  };
  // Effect to control button visibility based on selected date range
  useEffect(() => {
    if (selectedDate[0]?.startDate && selectedDate[0]?.endDate) {
      setBtnShow(true);
    } else {
      setBtnShow(false);
    }
  }, [selectedDate[0]?.startDate, selectedDate[0]?.endDate]);

  // Function to toggle date picker visibility
  const handleOpenDatePicker = () => {
    setOpenDatePicker(!openDatePicker);
  };

  return (
    <Page title="Request Report" className="manage-member">
      <Container maxWidth={themeStretch ? false : 'lg'} sx={{ mt: 5 }}>
        <HeaderBreadcrumbs
          heading="Request Report"
          links={[
            { name: 'TripShift Platform', href: '/dashboard' },
            { name: 'Report', href: '/report' },
            { name: 'Request Report' },
          ]}
        />
        {userRole?.data === 1 || userRole?.data === 5 ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={10.8}>
              <Card
                sx={{
                  p: 5,
                  boxShadow: 'none',
                  border: '1px solid #DFE3E8',
                  borderRadius: '0.625rem',
                  mb: 20,
                  overflow: 'inherit',
                }}
              >
                <Typography variant="h6" sx={{ mb: 4, color: 'text.secondary' }}>
                  {' '}
                  Request a report{' '}
                </Typography>
                <Typography variant="body1" sx={{ mb: 4, color: 'text.secondary' }}>
                  Select a date range below to request a tailored report for your organisation covering that time frame.{' '}
                </Typography>

                {/* </div> */}

                <Typography variant="body1" sx={{ mb: 5, color: 'text.secondary' }}>
                  {' '}
                  Your report will be sent to the email address registered to your account, within 7 days. Please get in
                  touch with your Account Manager at TripShift if you have any specific questions or requirements.
                </Typography>

                <Typography variant="h6" sx={{ mb: 2, color: 'text.secondary' }}>
                  {' '}
                  Select date range{' '}
                </Typography>

                <>
                  <Stack spacing={20} direction={{ xs: 'column', sm: 'row' }}>
                    <Stack spacing={2} className="report-date-picker" direction={{ sm: 'row' }}>
                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                        {' '}
                        From:{' '}
                      </Typography>
                      <input
                        name="startDate"
                        placeholder="DD/MM/YYYY"
                        className="range-date-picker"
                        value={
                          selectedDate[0]?.startDate ? moment(selectedDate[0]?.startDate)?.format('DD/MM/YYYY') : null
                        }
                        onClick={handleOpenDatePicker}
                        readOnly
                      />
                    </Stack>

                    <Stack spacing={2} className="report-date-picker" direction={{ sm: 'row' }}>
                      {' '}
                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                        {' '}
                        To:{' '}
                      </Typography>
                      <input
                        name="endDate"
                        placeholder="DD/MM/YYYY"
                        value={selectedDate[0]?.endDate ? moment(selectedDate[0]?.endDate)?.format('DD/MM/YYYY') : null}
                        onClick={handleOpenDatePicker}
                        className="range-date-picker"
                        readOnly
                      />
                    </Stack>
                  </Stack>

                  {openDatePicker && (
                    <DatePickerDialog
                      startDatePlaceholder="DD/MM/YYYY"
                      endDatePlaceholder="DD/MM/YYYY"
                      dateDisplayFormat="dd/m/yyyy"
                      showDateDisplay={false}
                      openDatePicker={openDatePicker}
                      ranges={selectedDate}
                      setOpenDatePicker={setOpenDatePicker}
                      setSelectedDate={setSelectedDate}
                    />
                  )}
                </>
                <Stack
                  direction={{ xs: 'row', sm: 'row' }}
                  alignItems="flex-end"
                  justifyContent="flex-end"
                  sx={{ mt: 8 }}
                  spacing={2}
                >
                  {btnshow ? (
                    <LoadingButton
                      type="button"
                      onClick={handleReport}
                      size="large"
                      variant="contained"
                      className="enable_btn_report"
                      loading={loading}
                    >
                      Submit Request
                    </LoadingButton>
                  ) : (
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      className="disable_btn_report"
                      loading={loading}
                    >
                      Submit Request
                    </Button>
                  )}
                </Stack>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={10}>
              <Card sx={{ p: 3, boxShadow: 'none', border: '1px solid #DFE3E8', borderRadius: '0.625rem' }}>
                <Typography variant="h4" sx={{ mb: 2, textAlign: 'center' }}>
                  {' '}
                  Request a Report{' '}
                </Typography>

                <Typography variant="body1" sx={{ mb: 0, color: 'text.secondary', textAlign: 'center' }}>
                  If you need something more specific than what is reported on your{' '}
                  <Link
                    variant="body1"
                    className="heading_14 greenclo textstyl"
                    sx={{ color: 'primary.main' }}
                    to="/dashboard"
                  >
                    {' '}
                    Dashboard
                  </Link>
                  , you can ask an Admin to request a specific report for you. Please visit the{' '}
                  <Link
                    variant="body1"
                    className="heading_14 greenclo textstyl"
                    sx={{ color: 'primary.main' }}
                    to="/members"
                  >
                    {' '}
                    Member Management{' '}
                  </Link>{' '}
                  area of the platform to find an Admin who can help you get the report you are looking for.{' '}
                </Typography>
                <div className="error404">
                  <Image src={imagesAll.seo} className="error404_img2" style={{ marginBottom: '10px' }} />
                </div>
              </Card>
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
}
