/* eslint-disable import/no-unresolved */
import { useState } from 'react';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, IconButton, InputAdornment, Stack } from '@mui/material';
// hooks
// import useAuth from '../../../hooks/useAuth';
// import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import { useNavigate } from 'react-router-dom';
import AxiosAll from 'src/service/AxiosAll';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import Iconify from '../../../components/Iconify';
// eslint-disable-next-line import/order
// eslint-disable-next-line import/order

// ----------------------------------------------------------------------

export default function RegisterForm() {
  // const { register } = useAuth();

  // const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();
  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    companyName: Yup.string().required('company name required'),
    password: Yup.string()
      .required('Password is required')
      .matches(/[a-z]+/, ' At least one lowercase character')
      .matches(/[A-Z]+/, ' At least one uppercase character')
      .matches(/[@$!%*#?&£]+/, ' At least one special character')
      .matches(/\d+/, ' At least one number')
      .min(8, 'Password must be at least 8 characters'),

    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    password: '',
    confirmPassword: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (values) => {
    const data1 = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      password: values?.confirmPassword.replace(/\s/g, ''),
      confirmPassword: values?.password,
      companyName: values?.companyName,
    };
    try {
      AxiosAll('post', '/api/admin/register', data1);
      navigate('/verify-code');
    } catch (error) {
      console.error(error);
      reset();
      // if (isMountedRef.current) {
      //   setError('afterSubmit', { ...error, message: error.message });
      // }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {/* <SocialLogin /> */}

      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="firstName" label="First name" />
          <RHFTextField name="lastName" label="Last name" />
        </Stack>

        <RHFTextField name="email" label="Email address" />
        <RHFTextField name="companyName" label="Company name" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
            autoComplete: 'new-password',
          }}
        />

        <RHFTextField
          name="confirmPassword"
          label="Confirm password"
          type={showConfirmPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                  <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {/* <Link to={'/verify-code'} className="register-btn"> */} Register
          {/* </Link> */}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
