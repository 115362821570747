/* eslint-disable react/jsx-key */
/* eslint-disable no-debugger */
import PropTypes from 'prop-types';
// @mui
import { TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material';
import { useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import { imagesAll } from 'src/assets/Images_index';
// eslint-disable-next-line import/no-unresolved
import Image from 'src/components/Image';

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

// ----------------------------------------------------------------------

AddTeamHeader.propTypes = {
  onSort: PropTypes.func,
  orderBy: PropTypes.string,
  sortOrder: PropTypes.string,
  headLabel: PropTypes.array,
  rowCount: PropTypes.number,
  numSelected: PropTypes.number,
  onSelectAllRows: PropTypes.func,
  sx: PropTypes.object,
};

export default function AddTeamHeader({
  order,
  orderBy,
  rowCount = 0,
  headLabel,
  numSelected = 0,
  onSelectAllRows,
  setOrder,
  setOrderBy,
  sx,
}) {
  const [toggle, setToggle] = useState();

  const handleOrder = (name, orderby) => {
    setToggle({
      [name]: !toggle?.[name],
    });
    setOrder('asc');
    setOrderBy(orderby);
    // eslint-disable-next-line no-debugger
    if (toggle?.[name] && orderBy === orderby && order === 'asc') {
      setOrder('desc');
      setOrderBy(orderby);
      setToggle({
        ...toggle,
      });
    } else if (toggle?.[name] && orderBy === orderby && order === 'desc') {
      setOrder('asc');
      setOrderBy(orderby);
      setToggle({
        [name]: !toggle?.[name],
      });
    }
  };
  return (
    <TableHead sx={sx}>
      <TableRow>
        <TableCell padding="checkbox" className="checkbox-edit-section">
          {window?.location?.pathname === '/allocate-team-lead' ? null : (
            <Image src={imagesAll.cross_black} style={{ height: '22px', width: '22px', margin: 'auto' }} />
          )}
        </TableCell>

        {headLabel.map((headCell) => (
          <TableCell className="first-table-width allocated-th">
            <TableSortLabel
              active={toggle?.[headCell?.id]}
              direction={toggle?.[headCell?.id] ? order : 'asc'}
              onClick={() => handleOrder(headCell?.id, headCell?.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
