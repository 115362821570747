/* eslint-disable import/named */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable no-debugger */
/* eslint-disable no-dupe-else-if */
/* eslint-disable no-unreachable-loop */
import { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Box, Button, Card, Container, Table, TableBody, TableContainer, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import AxiosAll from 'src/service/AxiosAll';
import useSettings from '../hooks/useSettings';
import useTable, { getComparator } from '../hooks/useTable';
import useTabs from '../hooks/useTabs';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { TableHeadUploadCsv, TableNoData } from '../components/table';
import { UploadcsvToolbar, UploadCsvTableRow } from '../sections/@dashboard/invoice/list';

const SERVICE_OPTIONS = [
  'all',
  'full stack development',
  'backend development',
  'ui design',
  'ui/ux design',
  'front end development',
];

const TABLE_HEAD = [
  { id: 'createdAt', label: 'Date', align: 'left' },
  { id: 'fileName', label: 'File Name', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'totalRows', label: 'Total Rows', align: 'left' },
  { id: 'totalErrorRows', label: 'Error Rows', align: 'left' },
  { id: 'role', label: 'Action', align: 'center', width: 140 },
];

// ----------------------------------------------------------------------

export default function CsvUploadList() {
  const theme = useTheme();
  // Get user details, role, and token from Redux state
  const { userDetails, userRole, token } = useSelector((state) => state.loginReducer);
  const { themeStretch } = useSettings();
  // Define table-related state variables and functions
  const { dense, order, orderBy, page, setPage, selected, onSelectAllRows, onSort } = useTable({
    defaultOrderBy: 'status',
  });

  // Initialize state variables
  const [tableData, setTableData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [count, setCount] = useState(0);
  const [orderValue, setOrder] = useState('desc');
  const [orderByValue, setOrderBy] = useState('');
  const [reload, setReload] = useState(false);
  const [Addreload, setAddReload] = useState(false);
  const { currentTab: filterStatus, onChangeTab: onFilterStatus } = useTabs('all');
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedUserIds, setIds] = useState([]);

  // Apply sorting and filtering to the data
  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterStatus,
  });

  // Check if the filtered data is empty
  const isNotFound = !dataFiltered?.length;

  // Function to load more data when a button is clicked
  const handleLoadMore = () => {
    setPage(page === 0 ? 2 : page + 1);
  };

  // Function to fetch a CSV file list
  const getCSVFileList = async () => {
    setLoading(true);

    const res = await AxiosAll(
      'get',
      `/api/user/get-import-status?query=${filterName}&currentPage=${
        page === 0 ? 1 : page
      }&pageSize=${7}&sortBy=${orderByValue}&sortOrder=${orderValue === 'asc' ? 'ASC' : 'DESC'}`,
      {},
      token
    );
    if (res?.status === 200) {
      if (tableData?.length && filterName === '') {
        if (Addreload === true) {
          setTableData([]);
          setAddReload(false);
          setTableData(res?.data?.data?.result);
          setLoading(false);
          setReload(false);
        } else {
          // Merge the two arrays
          const mergedArray = tableData?.concat(res?.data?.data?.result);
          const sortArray = mergedArray.sort((a, b) => b.id - a.id);

          // Remove duplicates based on a unique key
          const resultArray = sortArray?.reduce((accumulator, currentObject) => {
            // Check if the current object already exists in the accumulator array based on a unique key (in this case, 'id')
            const existingObject = accumulator?.find((object) => object?.id === currentObject?.id);
            // If the current object doesn't exist in the accumulator array, add it to the accumulator
            if (!existingObject) {
              accumulator?.push(currentObject);
            }
            return accumulator;
          }, []);
          setTableData(resultArray);
          setLoading(false);
          setReload(false);
        }
      } else {
        setTableData([]);
        setTableData(res?.data?.data?.result);
        setLoading(false);
        setCount(res?.data?.data?.total);
        setReload(false);
      }
    } else {
      setLoading(false);
      setTableData([]);
    }
  };

  // Fetch data when dependencies change
  useEffect(() => {
    getCSVFileList();
  }, [token, Addreload, orderByValue, orderValue, reload, userRole, filterName, page, rowsPerPage]);

  return (
    <Page title="Bulk Upload" className="manage-member membertitlepage">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        {/* <HeaderBreadcrumbs heading="Bulk Upload" links={[]} /> */}
        <Typography variant="h3" sx={{ mb: 2, ml: 2 }}>
          Bulk Upload
        </Typography>
        <Typography sx={{ mb: 0, ml: 3, mr: 2 }}>
          Before continuing please download our CSV template via the '"Download Template" option. Upon successfully
          uploading the CSV, it may take some time for the members to receive the emails. Similarly if the CSV has an
          error it will show up in the table below with an option to download and modify the file. For technical
          support, please contact <u>Sebastien@tripshift.co.uk</u>
        </Typography>
        <UploadcsvToolbar setReload={setReload} />
        <Card className="member-card-section-new">
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }} className="member-new-table">
              <LoadingOverlay
                // eslint-disable-next-line react/jsx-boolean-value
                active={loading}
                spinner
              >
                <Table size={'medium'} className="memberinner-table upload-csv-table">
                  <TableHeadUploadCsv
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData?.length}
                    numSelected={selected?.length}
                    setOrder={setOrder}
                    onSort={onSort}
                    user={userDetails}
                    team={false}
                    setOrderBy={setOrderBy}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        tableData?.map((row) => row?.userId)
                      )
                    }
                  />

                  <>
                    <TableBody>
                      {dataFiltered?.map((row, index) => (
                        <UploadCsvTableRow key={index} row={row} user={userDetails} />
                      ))}
                      {isNotFound && loading === false ? <TableNoData isNotFound={isNotFound} /> : null}{' '}
                    </TableBody>
                  </>
                </Table>

                {count === tableData?.length || tableData?.length === 0 || loading === true ? null : (
                  <Box sx={{ textAlign: 'center', mt: 3.5, mb: 5 }}>
                    <Button
                      type="submit"
                      onClick={handleLoadMore}
                      size="medium"
                      variant="contained"
                      sx={{ textTransform: 'inherit' }}
                    >
                      Load more
                    </Button>
                  </Box>
                )}
              </LoadingOverlay>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  comparator,
  filterName,
  filterStatus,
  filterService,
  filterStartDate,
  filterEndDate,
}) {
  const stabilizedThis = tableData?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis?.map((el) => el[0]);

  if (filterName) {
    tableData = tableData?.filter(
      (item) =>
        item.invoiceNumber?.toLowerCase().indexOf(filterName?.toLowerCase()) !== -1 ||
        item.invoiceTo.name?.toLowerCase().indexOf(filterName?.toLowerCase()) !== -1
    );
  }

  return tableData;
}
