/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable no-debugger */
import merge from 'lodash/merge';
import PropTypes from 'prop-types';

// @mui
import { Box, Card, CardHeader } from '@mui/material';
// components
import ReactApexChart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import { BaseOptionChart } from '../../../../components/chart';
// ----------------------------------------------------------------------

AnalyticsWebsiteVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AnalyticsWebsiteVisits({ title, subheader, chartLabels, chartData, ...other }) {
  const theme = useTheme();
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);
  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: chartData?.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: { type: 'month' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(2)} kg`;
          }
          return y.toFixed(2);
        },
      },
    },
    yaxis: [
      {
        opposite: false,
        title: {
          text: 'kg',
        },
        labels: {
          formatter: function (value) {
            return value.toFixed(0); // Set the number of decimal places to 2
          },
        },
      },
      {
        opposite: true,
        title: {
          text: '',
        },
        labels: {
          style: {
            colors: '#fff',
          },
          formatter: function (value) {
            return value.toFixed(0); // Set the number of decimal places to 2
          },
        },
      },
    ],
  });

  return (
    <Card {...other}>
      {subheader ? (
        <CardHeader
          title={title}
          subheader={subheader}
          className={mode?.themeMode === 'dark' ? 'light_color_heading' : 'travel-mode'}
        />
      ) : null}

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        {chartData ? <ReactApexChart type="line" series={chartData} options={chartOptions} height={350} /> : null}
      </Box>
    </Card>
  );
}
