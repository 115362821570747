/* eslint-disable no-debugger */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import { useState } from 'react';
// import { useAuth } from 'oidc-react';

// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, Avatar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// components
import { useNavigate } from 'react-router';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { deleteLogin, updateRole, getAccessToken, updateImage, updateName } from '../../../redux/actions/login';
import { imagesAll } from 'src/assets/Images_index';
import { useMsal } from '@azure/msal-react';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userDetails, userRole, profilePic, profileName } = useSelector((state) => state.loginReducer);
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);
  const { instance, accounts } = useMsal();
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

  const handleLogOutSSo = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: `${
        userRole?.data === 4 ? `${window.location.origin}/login` : `${window.location.origin}/login`
      }`,
      mainWindowRedirectUri: `${window.location.origin}`, // Specify the main window redirect URI
    });
  };

  const handleLogOut = () => {
    if (userRole?.data === 4) {
      dispatch(deleteLogin());
      dispatch(updateRole());
      dispatch(updateImage());
      dispatch(updateName());
      dispatch(getAccessToken());
      localStorage.clear();
      sessionStorage.clear();
      handleLogOutSSo();
      navigate('/login');
    } else {
      dispatch(deleteLogin());
      dispatch(updateRole());
      dispatch(updateImage());
      dispatch(updateName());
      dispatch(getAccessToken());
      localStorage.clear();
      sessionStorage.clear();
      handleLogOutSSo();
      navigate('/login');
    }
  };

  const handleUpdateRole = () => {
    dispatch(updateRole(userRole?.data === 5 ? 2 : 5));
    window.location.reload(false);

    handleClose();
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={profilePic ? profilePic : imagesAll?.profileicon} alt="Rayan Moran" />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap className="heading_14">
            {profileName ? profileName : `${userDetails?.data?.data?.firstName} ${userDetails?.data?.data?.lastName}`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap className="heading_14">
            {userDetails?.data?.data?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {userRole?.data === 4 ? (
          <>
            <MenuItem
              onClick={() => {
                navigate('/recyclebin', { state: { memberId: userDetails?.data?.data?.id } });
                handleClose();
              }}
              // className="view_member"
              // sx={{ color: 'error.main' }}
            >
              {/* <Iconify icon={'eva:trash-2-outline'} /> */}
              Recycle Bin
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate('/customer');
                handleClose();
              }}
              className="view_member"
              // sx={{ color: 'error.main' }}
            >
              {/* <Iconify icon={'eva:trash-2-outline'} /> */}
              Manage Customers{' '}
            </MenuItem>
          </>
        ) : (
          <MenuItem
            onClick={() => {
              navigate('/profile', { state: { memberId: userDetails?.data?.data?.id } });
              handleClose();
            }}
            // className="view_member"
            // sx={{ color: 'error.main' }}
          >
            {/* <Iconify icon={'eva:trash-2-outline'} /> */}
            View Profile
          </MenuItem>
          // <MenuItem
          //   onClick={() => {
          //     navigate('/change-password');
          //     handleClose();
          //   }}
          //   className="view_member"
          //   // sx={{ color: 'error.main' }}
          // >
          //   {/* <Iconify icon={'eva:trash-2-outline'} /> */}
          //   Change Password
          // </MenuItem>
        )}
        <Divider sx={{ borderStyle: 'dashed' }} />
        {userDetails?.data?.data?.roleId === 5 ? (
          <MenuItem onClick={handleUpdateRole}>
            {userRole?.data === 5 ? 'Switch To Team Lead View' : 'Switch To Admin View'}
          </MenuItem>
        ) : null}

        <MenuItem onClick={handleLogOut}>Logout</MenuItem>
        {/* <MenuItem onClick={auth?.signOut}>Logout Identity server</MenuItem> */}
      </MenuPopover>
    </>
  );
}
