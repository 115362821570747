import { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  MenuItem,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import { createTeam } from 'src/redux/actions/team';

export default function SkipStep() {
  const location = useLocation();
  const TeamName = location?.state?.teamName;
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const navigate = useNavigate();
  const setting = localStorage.getItem('settings');
  const dispatch = useDispatch();

  const mode = JSON.parse(setting);
  const SaveTeam = () => {
    dispatch(createTeam({}));
    navigate('/teams');
  };
  return (
    <div>
      <Box sx={{ textAlign: 'center' }}>
        <MenuItem
          onClick={() => {
            setOpenConfirmation(true);
          }}
          style={{ fontWeight: '700' }}
          className=""
        >
          Skip this step
        </MenuItem>
        <Dialog open={openConfirmation}>
          <DialogContent className="delete-alert">
            <DialogContentText id="alert-dialog-description">
              <Typography
                variant="body1"
                className={mode?.themeMode === 'dark' ? 'heading_18_dark heading_18 boldttext' : 'heading_18 boldttext'}
              >
                You can always add members to your Team later in the Manage Team area. For now we'll save your{' '}
                {TeamName} Team with the details you've provided
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="btn-section-alert">
            <Button
              onClick={() => {
                SaveTeam();
              }}
              variant="contained"
              size="medium"
              className="confirm-btn"
            >
              Confirm
            </Button>
            <Button onClick={() => setOpenConfirmation(false)} variant="contained" className="cancle_btn" size="medium">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
}
