/* eslint-disable import/no-duplicates */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-debugger */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// eslint-disable-next-line import/order
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Container, Grid, Stack, TextField, Typography } from '@mui/material';
// utils
// eslint-disable-next-line import/no-unresolved
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// eslint-disable-next-line import/no-unresolved
import useSettings from 'src/hooks/useSettings';
// eslint-disable-next-line import/no-unresolved
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import Page from 'src/components/Page';
import Label from '../../components/Label';
// routes
// components
import { FormProvider, RHFTextField, RHFUploadAvatar } from '../../components/hook-form';
// eslint-disable-next-line import/no-unresolved
import AddCustomerConfirmDailog from 'src/sections/mui/dialog/AddCustomerConfirmDailog';
import { useEffect, useState } from 'react';
import AxiosAll from 'src/service/AxiosAll';
import { useCallback } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import currentURL from '../../env';
// eslint-disable-next-line import/no-unresolved

// ----------------------------------------------------------------------

AddCustomer.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
};
const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

export default function AddCustomer({ isEdit, currentUser }) {
  const { userDetails, userRole } = useSelector((state) => state.loginReducer);
  const { CreateTeam } = useSelector((state) => state.teamReducer);
  const location = useLocation();
  const teamDetail = location?.state?.teamDetail;
  const { themeStretch } = useSettings();
  const [open, setOpen] = useState(false);
  const [imageError, setImageError] = useState('');
  const setting = localStorage.getItem('settings');
  const [uploadurl, setUploadUrl] = useState(false);
  const [data, setData] = useState();
  const [inputValue, setInputValue] = useState('');
  const [predictions, setPredictions] = useState([]);
  const mode = JSON.parse(setting);
  // Define a Yup validation schema for form validation
  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Customer name is required'),
    email: Yup.string().email('Email must be a valid email address').required('Primary Admin email is required'),
    LicencesNumber: Yup.number()
      .typeError('Number of licenses must be a valid number')
      .required('Number of licenses is required')
      .min(1, 'Number of licenses must be at least 1'),
  });
  // Define default form values
  const defaultValues = {
    name: '',
    email: '',
    LicencesNumber: '',
    notes: '',
    addressLine1: '',
    addressLine2: '',
    zipcode: '',
    city: '',
    state: '',
    country: '',
    avatarUrl: '',
  };

  // Define a function to handle form submission
  const onSubmit = () => {
    setData({
      email: values.email.trim(),
      companyName: values.name,
      licenceRequired: values.LicencesNumber,
      note: values.notes,
      address1: values?.addressLine1,
      address2: values?.addressLine2,
      town: values?.city,
      stateCounty: values?.state,
      country: values?.country,
      postCode: values?.zipcode,
      description: values?.description,
      imageUrl:
        typeof values?.avatarUrl === 'object' ? CreateTeam?.data?.imageUrl || teamDetail?.photoUrl : values?.avatarUrl,
    });
    setOpen(true);
  };

  // Initialize a useForm instance with Yup validation and default values
  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  // Destructure methods for form handling
  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // Define a callback function to handle file drop
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'avatarUrl',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  // Define a function to handle file upload
  const handleUpload = async () => {
    // eslint-disable-next-line no-debugger
    setUploadUrl(true);
    setImageError('');
    const formData = new FormData();
    formData.append('file', values?.avatarUrl);
    // eslint-disable-next-line no-debugger
    try {
      const res = await AxiosAll(
        'post',
        '/api/utilities/upload-image',
        formData,
        userDetails?.data?.token,
        'multipart/form-data'
      );
      if (res?.status === 200) {
        setValue('avatarUrl', res?.data?.data?.imageUrl);
        setImageError('');
        setUploadUrl(false);
      } else {
        setUploadUrl(false);
        setValue('avatarUrl', '');
        setImageError(res?.response?.data?.message);
        const timer1 = setTimeout(() => setImageError(''), 3 * 1000);
        return () => {
          clearTimeout(timer1);
        };
      }
    } catch (error) {
      console.error(error);
    }
  };
  const values = watch();

  // Use useEffect to handle changes in the avatarUrl field
  useEffect(() => {
    if (typeof values?.avatarUrl === 'object') {
      handleUpload();
    }
  }, [values?.avatarUrl]);

  // Define a function to handle input changes
  const handleInputChange = (e) => {
    if (e?.target?.value) {
      setInputValue(e?.target?.value);
    } else {
      setInputValue('');
      setPredictions([]);
      setValue('addressLine1', '');

      setValue('zipcode', '');

      setValue('city', '');

      setValue('country', '');

      setValue('state', '');
    }
  };

  // Define a function to handle key presses, e.g., Enter key
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAutocomplete();
    }
  };
  // Define a function to perform autocomplete for location input
  const handleAutocomplete = () => {
    axios
      .get(`${currentURL.currentEnv}/api/Location/GetPlaces?text=${inputValue}`)
      .then((response) => {
        if (response?.data?.autoCompletePlaces && response?.data?.autoCompletePlaces?.length > 0) {
          const placePredictions = response?.data?.autoCompletePlaces;
          setPredictions(placePredictions);
        } else {
          setPredictions([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  // Define a function to handle place selection
  const handlePlaceSelect = (placeId) => {
    if (placeId) {
      axios
        .get(`${currentURL.currentEnv}/api/Location/GetPlaceDetails?placeId=${placeId}`)
        .then((response) => {
          if (response.data.result && response.data.result.geometry && response.data.result.geometry.location) {
            const location = response.data.result.geometry.location;

            // Retrieve the formatted address using the selected location
            axios
              .get(
                `${currentURL.currentEnv}/api/Location/GetFormatedAddress?lattitude=${location.lat}&longitude=${location.lng}`
              )
              .then((response) => {
                const addressComponents = response.data.results[0]?.address_components;
                setValue('addressLine1', response.data.results[0].formatted_address);
                setInputValue(response.data.results[0].formatted_address);
                const postalCode = addressComponents?.find((component) => component.types.includes('postal_code'));
                const city = addressComponents?.find((component) => component.types.includes('postal_town'));
                const country = addressComponents?.find((component) => component.types.includes('country'));
                const state = addressComponents?.find((component) =>
                  component.types.includes('administrative_area_level_2')
                );

                if (postalCode) {
                  setValue('zipcode', postalCode.long_name);
                } else {
                  setValue('zipcode', '');
                }
                if (city) {
                  setValue('city', city.long_name);
                } else {
                  setValue('city', '');
                }
                if (country) {
                  setValue('country', country.long_name);
                } else {
                  setValue('country', '');
                }
                if (state) {
                  setValue('state', state.long_name);
                } else {
                  setValue('state', '');
                }
              })
              .catch((error) => {
                setValue('zipcode', '');

                setValue('city', '');

                setValue('country', '');

                setValue('state', '');
              });
          } else {
            setValue('zipcode', '');

            setValue('city', '');

            setValue('country', '');

            setValue('state', '');
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <Page title="Add Customer" className="manage-member add-customer-page">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <div>
          <Typography
            variant="h5"
            sx={{ pl: 0.5 }}
            className={mode?.themeMode === 'dark' ? 'light_link_text' : 'header_dark_heading'}
          >
            {userRole?.data === 4 ? 'TripShift Admin Portal' : null}
          </Typography>

          {userRole?.data === 4 ? (
            <Typography
              variant="h5"
              sx={{ mt: 2, mb: 10, pl: 0.5 }}
              noWrap
              className={mode?.themeMode === 'dark' ? 'light_link_text' : 'header_dark_heading'}
            >
              Welcome {userDetails?.data?.data?.firstName} {userDetails?.data?.data?.lastName}{' '}
            </Typography>
          ) : null}
        </div>
        <HeaderBreadcrumbs heading={'Add Customer'} links={[]} />

        <FormProvider methods={methods}>
          <div className="boder-upper">
            <Grid container spacing={3} sx={{ py: 3, px: 3 }}>
              <Grid item xs={12} md={3}>
                <Card sx={{ py: 10, px: 3, boxShadow: 'none' }}>
                  {isEdit && (
                    <Label
                      color={values.status !== 'active' ? 'error' : 'success'}
                      sx={{ textTransform: 'uppercase', position: 'absolute', top: 24, right: 24 }}
                    >
                      {values.status}
                    </Label>
                  )}

                  <Box sx={{ mb: 5 }}>
                    <RHFUploadAvatar
                      name="avatarUrl"
                      maxSize={3145728}
                      EditTeamUrl={teamDetail?.photoUrl}
                      onDrop={handleDrop}
                      helperText={
                        <Typography
                          variant="caption"
                          className="heading12_edit"
                          sx={{
                            mt: 2,
                            mx: 'auto',
                            display: 'block',
                            textAlign: 'center',
                            color: 'text.secondary',
                          }}
                        >
                          Allowed *.jpeg, *.jpg, *.png
                          <br /> max size of 3.1 MB
                        </Typography>
                      }
                    />
                  </Box>
                  {imageError !== '' && <p className="error-message profile_error_message">{imageError}</p>}
                </Card>
              </Grid>

              <Grid item xs={12} md={9}>
                <Card sx={{ p: 3, boxShadow: 'none' }}>
                  <Stack spacing={3} className="form-first-input-pad">
                    <RHFTextField name="name" label="Company Name*" placeholder="(max 50 characters)" />
                    <RHFTextField name="email" label="Primary Admin*" placeholder="Email address" />
                    <div className="google-place">
                      <Autocomplete
                        freeSolo
                        options={predictions.map((prediction) => prediction.description)}
                        inputValue={inputValue}
                        onInputChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                        open={true}
                        name="addressLine1"
                        renderInput={(params) => <TextField {...params} label="Enter a location" variant="outlined" />}
                        renderOption={(option) => <div>{option}</div>}
                        getOptionSelected={(option, value) => option === value}
                        onChange={(event, value) => {
                          if (value) {
                            const selectedPrediction = predictions.find(
                              (prediction) => prediction.description === value
                            );
                            if (selectedPrediction) {
                              handlePlaceSelect(selectedPrediction?.placeId);
                            }
                          }
                        }}
                      />
                    </div>
                    <RHFTextField name="addressLine2" label="" placeholder="Address 2 (optional)" />
                    {values?.city !== '' ? (
                      <RHFTextField name="city" label="" placeholder="city" className="text-field-width" />
                    ) : null}
                    {values?.state !== '' ? (
                      <RHFTextField name="state" label="" placeholder="state" className="text-field-width" />
                    ) : null}
                    {values?.zipcode !== '' ? (
                      <RHFTextField name="zipcode" label="" placeholder="postal code" className="text-field-width" />
                    ) : null}
                    {values?.country !== '' ? (
                      <RHFTextField name="country" label="" placeholder="country" className="text-field-width" />
                    ) : null}
                    <RHFTextField
                      name="LicencesNumber"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      label="Number of Licences Purchased*"
                      placeholder="Enter number"
                      className="licences-field-width"
                      onKeyDown={(e) => {
                        if (
                          e.keyCode === 107 ||
                          e.keyCode === 109 ||
                          e.keyCode === 189 ||
                          e.keyCode === 190 ||
                          e.keyCode === 187 ||
                          e.keyCode === 69
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <RHFTextField
                      name="notes"
                      multiline
                      rows={4}
                      placeholder="(Optional. Max 300 characters)"
                      label="Notes (Internal only)"
                    />
                  </Stack>
                  <Stack
                    className="form-first-input-pad save-chnage-new"
                    direction={{ xs: 'row', sm: 'row' }}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                    sx={{ mt: 3 }}
                    spacing={2}
                  >
                    {uploadurl ? (
                      <LoadingButton disabled type="button" size="large" variant="contained">
                        Create Customer
                      </LoadingButton>
                    ) : (
                      <LoadingButton type="button" onClick={handleSubmit(onSubmit)} size="large" variant="contained">
                        Create Customer
                      </LoadingButton>
                    )}
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </div>
        </FormProvider>
      </Container>
      <AddCustomerConfirmDailog open={open} setOpen={setOpen} data={data} setData={setData} />
    </Page>
  );
}
