/* eslint-disable import/no-unresolved */
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import ComingSoon from 'src/pages/ComingSoon';
import GeneralApp from 'src/pages/dashboard/GeneralApp';
import Maintenance from 'src/pages/Maintenance';
import Page403 from 'src/pages/Page403';
import Page404 from 'src/pages/Page404';
import Page500 from 'src/pages/Page500';
import Members from 'src/pages/MemberList';
import CsvUploadList from 'src/pages/CsvUploadList';

import ChangePassword from 'src/pages/auth/ChangePassword';
import TeamList from 'src/pages/team/TeamList';
import UserNewFormStepOne from 'src/pages/team/UserNewFormStepOne';
import UserNewFormStepTwo from 'src/pages/team/UserNewFormStepTwo';
import TeamDetail from 'src/pages/team/TeamDetail';
import SuperAdminRegister from 'src/pages/auth/SuperAdminRegister';
import { useSelector } from 'react-redux';
import AllocateTeamLead from 'src/pages/team/AllocateTeamLead';
import CompanyList from 'src/pages/compnay/CompanyList';
import AddCustomer from 'src/pages/compnay/AddCustomer';
import CustomerDetail from 'src/pages/compnay/CustomerDetail';
import RequestReport from 'src/pages/report/RequestReport';
import SuccessReport from 'src/pages/report/RequestSuccess';
import ViewProfile from 'src/pages/profile/ViewProfile';
import MangeProfile from 'src/pages/profile/ManageProfile';
import RecycleBin from 'src/pages/recyclebin';
import Organisation from 'src/pages/organisation';

const PrivateRoutes = () => {
  const navigation = useNavigate();
  const { userDetails, userRole } = useSelector((state) => state.loginReducer);

  return (
    <Routes>
      {userRole?.data === 4 ? (
        <>
          <Route path={'customer'} element={<CompanyList />} />
          <Route path={'add-customer'} element={<AddCustomer />} />
          <Route path={'customer'} element={<CompanyList />} />
          <Route path={'customer-detail'} element={<CustomerDetail />} />
          <Route path={'recyclebin'} element={<RecycleBin />} />
          <Route path={'*'} element={<CompanyList />} />
          <Route path="*" element={<Navigate to="customer" replace />} />
        </>
      ) : (
        <>
          <Route path={'dashboard'} element={<GeneralApp />} />
          <Route path="super-admin-register" element={<SuperAdminRegister />} />
          <Route path={'reduce'} element={<ComingSoon />} />
          <Route path={'report'} element={<RequestReport />} />
          <Route path={'success-report'} element={<SuccessReport />} />;
          <Route path={'organisation'} element={<Organisation />} />
          <Route path={'teams'} element={<TeamList />} />
          <Route path={'create-team-stepOne'} element={<UserNewFormStepOne />} />
          <Route path={'create-team-stepTwo'} element={<UserNewFormStepTwo />} />
          <Route path={'team-detail'} element={<TeamDetail />} />
          <Route path={'members'} element={<Members />} />
          <Route path={'csv-upload'} element={<CsvUploadList />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path={'404'} element={<Page404 />} />
          <Route path={'403'} element={<Page403 />} />
          <Route path={'500'} element={<Page500 />} /> <Route path={'maintenance'} element={<Maintenance />} />
          <Route path={'profile'} element={<ViewProfile />} />
          <Route path={'manage-profile'} element={<MangeProfile />} />;
          <Route path={'coming-soon'} element={<ComingSoon />} />
          <Route path={'allocate-team-lead'} element={<AllocateTeamLead />} />
          <Route path={'*'} element={<GeneralApp />} />
          <Route path="*" element={<Navigate to="dashboard" replace />} />
        </>
      )}
    </Routes>
  );
};

export default PrivateRoutes;
