/* eslint-disable react/button-has-type */
/* eslint-disable import/no-unresolved */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Stack, useTheme } from '@mui/material';
import { DateRange } from 'react-date-range';
// eslint-disable-next-line import/newline-after-import
export default function DatePickerDialog({ ranges, setSelectedDate, openDatePicker, setOpenDatePicker }) {
  const [open, setOpen] = React.useState(false);
  const setting = localStorage.getItem('settings');
  const [uploadurl, setUploadUrl] = React.useState(false);
  const [state, setState] = React.useState(ranges);
  const mode = JSON.parse(setting);
  const theme = useTheme();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedDate(ranges);
    setOpenDatePicker(false);
  };

  const handleSave = () => {
    setSelectedDate(state);
    setOpenDatePicker(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}

      <Dialog
        open={openDatePicker}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="working-hour-dialogbox"
      >
        <DialogContent>
          {openDatePicker && (
            <DateRange
              startDatePlaceholder="DD/MM/YYYY"
              endDatePlaceholder="DD/MM/YYYY"
              dateDisplayFormat="dd/MM/yyyy"
              onChange={(item) => setState([item.selection])}
              ranges={state}
              // eslint-disable-next-line react/jsx-boolean-value
              editableDateInputs={true}
              maxDate={new Date()}
            />
          )}
        </DialogContent>
        <Stack
          direction={{ xs: 'row', sm: 'row' }}
          alignItems="flex-end"
          justifyContent="flex-end"
          sx={{ mt: 3, px: 2, pb: 2 }}
          className=" working-time-dialogbox"
          spacing={2}
          // className="form-step-btnend"
        >
          <Button type="button" onClick={handleSave} size="medium" variant="contained" sx={{ mr: 2 }}>
            Save
          </Button>
          <Button
            size="medium"
            onClick={handleClose}
            variant="contained"
            className={mode?.themeMode === 'dark' ? 'cancle_btn-team cancle_btn_shadow' : ' cancle_btn-team '}
          >
            Cancel
          </Button>
        </Stack>
      </Dialog>
    </div>
  );
}
