/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { useState } from 'react';
// @mui
import { Button, Dialog, DialogActions, DialogContent, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import AxiosAll from 'src/service/AxiosAll';
import DeleteSuccess from './DeleteSuccess';
// components
// eslint-disable-next-line import/no-unresolved
// import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

const emails = ['username@gmail.com', 'user02@gmail.com'];

export default function AssignTeamLeadFromTeam({ open, userid, teamName, setOpen, teamId, userName }) {
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);
  const theme = useTheme();
  const { userDetails, token } = useSelector((state) => state.loginReducer);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [opensuceessModal, setOpenSuccessModal] = useState(false);

  const navigate = useNavigate();
  const handleUpdateRole = async () => {
    if (userid) {
      const dataObject = {
        userId: userid,
        teamId,
        roleId: 2,
      };
      const res = await AxiosAll('post', '/api/user/update-team-lead-role', [dataObject], token);
      if (res?.status === 200) {
        setOpen(false);
        setOpenSuccessModal(true);
        // setNotifyOpen(true)
        // setType("success")
        // setMessage(res?.data?.message)
        // setReload("7")
      } else {
        // setNotifyOpen(true)
        // setType("error")
        // setOpen(true)
      }
    }
  };

  return (
    <>
      {/* //delete confirmation  */}
      <Dialog open={open}>
        <DialogContent className="delete-alert">
          {/* <DialogContentText id="alert-dialog-description"> */}

          <Typography
            variant="body1"
            className={mode?.themeMode === 'dark' ? 'heading_18_dark heading_18 boldttext' : 'heading_18 boldttext'}
          >
            Assign {userName} as Team Lead of the {teamName}?
          </Typography>

          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions className="btn-section-alert">
          <Button onClick={handleUpdateRole} variant="contained" size="medium" className="confirm-btn">
            Confirm
          </Button>
          <Button onClick={() => setOpen(false)} variant="contained" className="cancle_btn" size="medium">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteSuccess
        opensuceessModal={opensuceessModal}
        setOpenSuccessModal={setOpenSuccessModal}
        AddTeamAllocate={true}
        userName={userName}
        teamId={teamId}
      />
    </>
  );
}
