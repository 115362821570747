/* eslint-disable import/no-unresolved */
import { Grid, Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

export default function ProfileSkeleton() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3}>
        <Skeleton variant="circular" width={180} height={180} style={{ margin: '0 auto 16px' }} />
        <Skeleton variant="text" height={20} width={180} style={{ margin: '0 auto' }} />
      </Grid>
      <Grid item xs={12} md={8}>
        <div
          className="team-detail-inner profile-inner"
          style={{ border: '1px solid #dfe3e8', borderRadius: '10px', padding: '15px 25px', marginBottom: '24px' }}
        >
          <Skeleton variant="text" height={50} width={250} />
          <Skeleton variant="text" height={30} width={150} />
          <Skeleton variant="text" height={30} width={100} />
          <Skeleton variant="text" height={30} width={150} />
          <Skeleton variant="text" height={30} width={200} />
          <Skeleton variant="text" height={30} width={100} />
          <Skeleton variant="text" height={30} width="80%" />
          <Skeleton variant="text" height={30} width="60%" />
        </div>
        <div
          className="team-detail-inner profile-inner"
          style={{ border: '1px solid #dfe3e8', borderRadius: '10px', padding: '15px 25px', marginBottom: '24px' }}
        >
          <Skeleton variant="text" height={50} width={250} />
          <Skeleton variant="text" height={30} width={150} />
          <Skeleton variant="text" height={30} width={100} />
          <Skeleton variant="text" height={30} width={150} />
        </div>
        <div
          className="team-detail-inner profile-inner"
          style={{ border: '1px solid #dfe3e8', borderRadius: '10px', padding: '15px 25px', marginBottom: '24px' }}
        >
          <Skeleton variant="text" height={50} width={250} />
          <Skeleton variant="text" height={30} width={150} />
          <Skeleton variant="text" height={30} width={100} />
          <Skeleton variant="text" height={30} width={150} />
        </div>
      </Grid>
    </Grid>
  );
}
