/* eslint-disable no-unused-expressions */
import * as Yup from 'yup';
// import * as useSnackbar from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, IconButton, InputAdornment, Snackbar, Stack } from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../../routes/paths';
// components
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/service/AxiosAll';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function NewPasswordForm() {
  const navigate = useNavigate();

  //   const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const emailRecovery = searchParams.get('email');
  const [type, setType] = useState('');
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [succesApi, setSuccesApi] = useState(false);
  const VerifyCodeSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .matches(/[a-z]+/, ' At least one lowercase character')
      .matches(/[A-Z]+/, ' At least one uppercase character')
      .matches(/[@$!%*#?&£]+/, ' At least one special character')
      .matches(/\d+/, ' At least one number')
      .min(8, 'Password must be at least 8 characters'),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const defaultValues = {
    email: emailRecovery || '',
    password: '',
    confirmPassword: '',
  };

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues,
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  useEffect(() => {
    const target = document.querySelector('input.field-code');

    target?.addEventListener('paste', handlePaste);

    return () => {
      target?.removeEventListener('paste', handlePaste);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaste = (event) => {
    let data = event.clipboardData.getData('text');

    data = data.split('');

    [].forEach.call(document.querySelectorAll('.field-code'), (node, index) => {
      node.value = data[index];

      const fieldIndex = `code${index + 1}`;

      setValue(fieldIndex, data[index]);
    });

    event.preventDefault();
  };

  const handleChangeWithNextField = (event, handleChange) => {
    const { maxLength, value, name } = event.target;

    const fieldIndex = name.replace('code', '');

    const fieldIntIndex = Number(fieldIndex);

    if (value.length >= maxLength) {
      if (fieldIntIndex < 6) {
        const nextfield = document.querySelector(`input[name=code${fieldIntIndex + 1}]`);

        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }

    handleChange(event);
  };

  const onSubmit = async (data) => {
    const data1 = {
      email: data?.email,
      password: data?.password.replace(/\s/g, ''),
      token: searchParams.get('token'),
    };
    try {
      const res = await AxiosAll('post', '/api/user/reset-password', data1, searchParams.get('token'));
      const email = searchParams.get('email');
      if (res?.status === 200) {
        setType('success');
        setOpen(true);
        setSuccesApi(true);
        setMessage(res?.data?.message);
        navigate('/login', { state: { email, newPassword: true } });
      } else {
        setType('error');
        setOpen(true);
        setMessage(res?.response?.data?.title || res?.response?.data?.data?.errors[0] || res?.response?.data?.message);
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const handleClose = () => {
    setOpen(false);
    succesApi && navigate('/login');
  };

  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
          // eslint-disable-next-line no-unused-expressions
        }}
        onClose={() => handleClose()}
      >
        <Alert onClose={() => handleClose()} severity={type || 'success'} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <RHFTextField name="email" label="Email" disabled={!!emailRecovery} />

          <RHFTextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
              autoComplete: 'new-password',
            }}
          />

          <RHFTextField
            name="confirmPassword"
            label="Confirm New Password"
            type={showConfirmPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                    <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} sx={{ mt: 3 }}>
            Change password
          </LoadingButton>
        </Stack>
      </FormProvider>
    </>
  );
}
