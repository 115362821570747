/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable no-debugger */
/* eslint-disable no-dupe-else-if */
/* eslint-disable no-unreachable-loop */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import { Box, Button, Card, Container, Table, TableBody, TableContainer } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import AxiosAll from 'src/service/AxiosAll';
import useSettings from '../hooks/useSettings';
import useTable, { getComparator } from '../hooks/useTable';
import useTabs from '../hooks/useTabs';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { TableHeadCustom, TableNoData } from '../components/table';

import { InvoiceTableRow, InvoiceTableToolbar } from '../sections/@dashboard/invoice/list';

const SERVICE_OPTIONS = [
  'all',
  'full stack development',
  'backend development',
  'ui design',
  'ui/ux design',
  'front end development',
];

const TABLE_HEAD = [
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'name', label: 'Name / Email', align: 'left' },
  { id: 'teamName', label: 'Team', align: 'left' },
  { id: 'role', label: 'Role', align: 'center', width: 140 },
  { id: 'appStatus', label: 'App status', align: 'center', width: 140 },
  { id: 'activityLevel', label: 'Activity level', align: 'left' },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function MemberList() {
  // Import necessary dependencies
  const theme = useTheme();
  const { userDetails, userRole, token } = useSelector((state) => state.loginReducer);
  const { themeStretch } = useSettings();
  const navigate = useNavigate();

  // Initialize state variables using custom hook useTable
  const {
    dense,
    order,
    orderBy,
    page,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'status' });

  // Initialize various state variables
  const [tableData, setTableData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [count, setCount] = useState(0);
  const [filterService, setFilterService] = useState('all');
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [reload, setReload] = useState(false);
  const [Addreload, setAddReload] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const { currentTab: filterStatus, onChangeTab: onFilterStatus } = useTabs('all');
  const [makeAdmin, setMakeAdmin] = useState(false);
  const [makeTeam, setMakeTeam] = useState(false);
  const [loading, setLoading] = useState(false);
  const [teamLength, setTeamLength] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedUserIds, setIds] = useState([]);
  const [DeleteUserIds, setDeleteIds] = useState([]);

  const [updateData, setUpdateData] = useState();
  const [roleKey, setRoleKey] = useState();

  // Function to handle filtering by name
  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  // Function to handle filtering by service
  const handleFilterService = (event) => {
    setFilterService(event.target.value);
  };

  // Function to apply sorting and filtering to the data
  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterStatus,
  });

  // Check if data is not found
  const isNotFound = !dataFiltered?.length;

  // Load More functionality
  const handleLoadMore = () => {
    setPage(page === 0 ? 2 : page + 1);
  };

  // Function to get the member list
  const getMemberList = async () => {
    setLoading(true);
    const data = {
      search: filterName,
      sortBy: 'orderByValue',
      sortOrder: 'asc',
      pageNo: page === 0 ? 1 : page,
      perPageLimit: 7,
      RoleId: userRole?.data,
    };
    const res = await AxiosAll('post', '/api/user/member-list', data, token);
    if (res?.status === 200) {
      if (tableData?.length && filterName === '') {
        if (Addreload === true) {
          setTableData([]);
          setAddReload(false);
          setTableData(res?.data?.data?.list);
          setLoading(false);
          setCount(res?.data?.data?.metaData?.totalCount);
          setReload(false);
        } else {
          // Merge the two arrays
          const mergedArray = tableData.concat(res?.data?.data?.list);
          // Remove duplicates based on a unique key
          const resultArray = mergedArray.reduce((accumulator, currentObject) => {
            // Check if the current object already exists in the accumulator array based on a unique key (in this case, 'id')
            const existingObject = accumulator.find((object) => object.userId === currentObject.userId);
            // If the current object doesn't exist in the accumulator array, add it to the accumulator
            if (!existingObject) {
              accumulator.push(currentObject);
            }
            return accumulator;
          }, []);
          setTableData(resultArray);
          setLoading(false);
          setReload(false);
        }
      } else {
        setTableData([]);
        setTableData(res?.data?.data?.list);
        setLoading(false);
        setCount(res?.data?.data?.metaData?.totalCount);
        setReload(false);
      }
    } else {
      setLoading(false);
      setTableData([]);
    }
  };

  // Function to update the member list
  const updateMemberList = () => {
    for (let j = 0; j < selectedUserIds.length; j++) {
      const newItem = selectedUserIds[j];
      for (let i = 0; i < tableData.length; i++) {
        const oldItem = tableData[i];
        if (oldItem.userId === newItem) {
          tableData[i].role =
            roleKey === 'Make Team Lead' && tableData[i]?.roleId === 1
              ? 'Admin | Team Lead'
              : roleKey === 'Make Team Lead'
              ? 'Team Lead'
              : roleKey === 'Remove Admin' && tableData[i]?.roleId === 5
              ? 'Team Lead'
              : roleKey === 'Remove Admin'
              ? 'Member'
              : roleKey === 'Remove Team Tead' && tableData[i]?.roleId === 5
              ? 'Administrator'
              : roleKey === 'Remove Team Tead'
              ? 'Member'
              : 'Make Admin' && tableData[i]?.roleId === 2
              ? 'Admin | Team Lead'
              : 'Administrator';

          tableData[i].teamName =
            roleKey === 'Remove Team Tead' && tableData[i]?.roleId === 5
              ? 'Default'
              : roleKey === 'Remove Team Tead'
              ? 'Default'
              : tableData[i].teamName;

          tableData[i].roleId =
            roleKey === 'Make Team Lead' && tableData[i]?.roleId === 1
              ? 5
              : roleKey === 'Make Team Lead'
              ? 2
              : roleKey === 'Remove Admin' && tableData[i].roleId === 5
              ? 2
              : roleKey === 'Remove Admin'
              ? 3
              : roleKey === 'Remove Team Tead' && tableData[i]?.roleId === 5
              ? 1
              : roleKey === 'Remove Team Tead'
              ? 3
              : 'Make Admin' && tableData[i]?.roleId === 2
              ? 5
              : 1;

          setUpdateData(tableData);
          setIds([]);

          setReload(false);
        }
      }
    }
  };

  // Function to handle removing selected users
  const handleRemoveSelectedUsers = () => {
    const filteredUsers = tableData.filter(
      (user) => !DeleteUserIds.find((selectedUser) => selectedUser === user.userId)
    );
    if (filteredUsers?.length) {
      setUpdateData(filteredUsers);
    } else {
      setTableData([]);
    }
    setDeleteIds([]);
    setReload(false);
  };

  // useEffect to handle removing selected users when DeleteUserIds or reload changes
  useEffect(() => {
    if (DeleteUserIds?.length) {
      handleRemoveSelectedUsers();
    }
  }, [DeleteUserIds, reload]);

  // useEffect to update the table data when updateData changes
  useEffect(() => {
    if (updateData?.length) {
      setTableData([]);
      setTableData(updateData);
      setReload(false);
      setDeleteIds([]);
    }
    setReload(false);
  }, [updateData]);

  // useEffect to update the member list when selectedUserIds or reload changes
  useEffect(() => {
    if (selectedUserIds?.length) {
      updateMemberList();
      setReload(false);
    }
    setReload(false);
  }, [selectedUserIds, reload]);

  // useEffect to handle selecting rows
  useEffect(() => {
    if (selected?.length) {
      const rowData = dataFiltered?.filter((person) => selected?.includes(person?.userId));
      setSelectedIds(rowData);
    }
    setReload(false);
  }, [selected]);

  // Function to get the team list
  const getTeamList = async () => {
    const res = await AxiosAll(
      'post',
      `/api/team/team-list?companyid=${userDetails?.data?.data?.companyId}&query=${filterName}&sortBy=&RoleId=${
        userRole?.data
      }&pageNo=${page + 1}&perPageLimit=${rowsPerPage}&sortOrder=asc`,
      {},
      token
    );
    if (res?.status === 200) {
      setTeamLength(res?.data?.result?.length);
      localStorage.setItem('teamId', res?.data?.result[0]?.teamId);
    }
  };

  // useEffect to get the team list when userRole changes
  useEffect(() => {
    getTeamList();
  }, [userRole]);

  // useEffect to update makeAdmin and makeTeam when selectedIds changes
  useEffect(() => {
    for (let i = 0; i < selectedIds?.length; i++) {
      if (selectedIds[i].status === 'Registered') {
        if (selectedIds[i].role === 'Administrator') {
          setMakeTeam(true);
          setMakeAdmin(false);
        } else if (selectedIds[i].role === 'Member' && selectedIds[i].isDefaultTeam === true) {
          setMakeTeam(true);
          setMakeAdmin(true);
        } else if (selectedIds[i].role === 'Member' && selectedIds[i].isDefaultTeam === false) {
          setMakeTeam(false);
          setMakeAdmin(true);
        } else if (selectedIds[i].role === 'Team Lead') {
          setMakeTeam(false);
          setMakeAdmin(true);
          // eslint-disable-next-line no-dupe-else-if
        } else {
          setMakeTeam(false);
          setMakeAdmin(false);
        }
      } else {
        setMakeTeam(false);
        setMakeAdmin(false);
      }
      break;
    }
  }, [selectedIds]);

  // useEffect to get the member list when token, Addreload, userRole, filterName, page, or rowsPerPage changes
  useEffect(() => {
    getMemberList();
  }, [token, Addreload, userRole, filterName, page, rowsPerPage]);

  return (
    <Page title="Manage Members" className="manage-member membertitlepage">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Manage Members"
          links={[
            { name: 'TripShift Platform', href: '/dashboard' },
            { name: 'Manage', href: '/members' },
            { name: 'Members' },
          ]}
        />
        <InvoiceTableToolbar
          filterName={filterName}
          filterService={filterService}
          filterStartDate={filterStartDate}
          filterEndDate={filterEndDate}
          teamLength={teamLength}
          selected={selected?.length}
          tableData={selectedIds}
          MainTable={dataFiltered}
          teamName={dataFiltered[0]?.teamName}
          setSelected={setSelected}
          selectedRow={selected}
          setReload={setReload}
          makeAdmin={makeAdmin}
          makeTeam={makeTeam}
          user={userDetails}
          setIds={setIds}
          setAddReload={setAddReload}
          selectedUserIds={selectedUserIds}
          setRoleKey={setRoleKey}
          DeleteUserIds={DeleteUserIds}
          setDeleteIds={setDeleteIds}
          setFilterName={setFilterName}
          onFilterName={handleFilterName}
          onFilterService={handleFilterService}
          onFilterStartDate={(newValue) => {
            setFilterStartDate(newValue);
          }}
          onFilterEndDate={(newValue) => {
            setFilterEndDate(newValue);
          }}
          optionsService={SERVICE_OPTIONS}
        />
        <Card className="member-card-section-new">
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }} className="member-new-table">
              <LoadingOverlay
                // eslint-disable-next-line react/jsx-boolean-value
                active={loading}
                spinner
              >
                <Table size={'medium'} className="memberinner-table">
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData?.length}
                    numSelected={selected?.length}
                    onSort={onSort}
                    user={userDetails}
                    team={false}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        tableData?.map((row) => row?.userId)
                      )
                    }
                  />

                  <>
                    <TableBody>
                      {dataFiltered?.map((row, index) => (
                        <InvoiceTableRow
                          key={index}
                          row={row}
                          user={userDetails}
                          selected={selected.includes(row?.userId)}
                          onSelectRow={() => onSelectRow(row?.userId)}
                          setReload={setReload}
                          setIds={setIds}
                          selectedUserIds={selectedUserIds}
                          setRoleKey={setRoleKey}
                          DeleteUserIds={DeleteUserIds}
                          setDeleteIds={setDeleteIds}
                        />
                      ))}
                      {isNotFound && loading === false ? <TableNoData isNotFound={isNotFound} /> : null}{' '}
                    </TableBody>
                  </>
                </Table>

                {count === tableData?.length || tableData?.length === 0 || loading === true ? null : (
                  <Box sx={{ textAlign: 'center', mt: 3.5, mb: 5 }}>
                    <Button
                      type="submit"
                      onClick={handleLoadMore}
                      size="medium"
                      variant="contained"
                      sx={{ textTransform: 'inherit' }}
                    >
                      Load more
                    </Button>
                  </Box>
                )}
              </LoadingOverlay>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName, filterStatus, filterRole }) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);
  return tableData;
}
