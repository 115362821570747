/* eslint-disable import/no-unresolved */
/* eslint-disable no-debugger */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
// @mui
import moment from 'moment';

import { Button, TableCell, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Label from 'src/components/Label';
import currentURL from '../../../../env';

// utils
// components

// ----------------------------------------------------------------------

UploadCsvTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function UploadCsvTableRow({ row, selected }) {
  const theme = useTheme();
  const { createdAt, fileName, status, errorFileName, correctFileName, totalRows, totalErrorRows } = row;
  console.log(status);
  const downloadScussess = (name) => {
    window.open(`${currentURL.currentEnv}/StaticFiles/UserImportFiles/${name}`);
  };
  return (
    <>
      {' '}
      <TableRow hover selected={selected}>
        <TableCell>{moment(createdAt).format('DD/MM/YYYY')}</TableCell>
        <TableCell className="file-name">{fileName}</TableCell>
        <TableCell>
          {' '}
          <Label
            variant={theme.palette.mode === 'light' ? 'outlined' : 'outlined'}
            color={
              (status === 0 && 'warning') ||
              (status === 2 && 'success') ||
              (status === 1 && 'warning') ||
              (status === 3 && 'error')
            }
            sx={{ textTransform: 'capitalize' }}
          >
            {status === 0 ? 'Pending' : status === 1 ? 'Procssing' : status === 3 ? 'error' : 'success'}
          </Label>
        </TableCell>
        <TableCell style={{ paddingLeft: '5.5%' }}>{totalRows}</TableCell>
        <TableCell style={{ paddingLeft: '5.5%' }}>{totalErrorRows}</TableCell>
        {}{' '}
        <TableCell>
          <>
            {errorFileName ? (
              <Button onClick={() => downloadScussess(errorFileName)} variant="contained" color="error" sx={{ mb: 2 }}>
                Download Error File
              </Button>
            ) : null}
            {correctFileName ? (
              <Button onClick={() => downloadScussess(correctFileName)} variant="contained">
                Download Correct File
              </Button>
            ) : null}
          </>
          {status === 2 ? (
            <Button onClick={() => downloadScussess(fileName)} variant="contained" sx={{ mt: 2 }}>
              Download Original File
            </Button>
          ) : null}
        </TableCell>{' '}
      </TableRow>
    </>
  );
}
