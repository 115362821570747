/* eslint-disable import/no-unresolved */
/* eslintdisable import/nounresolved */
import { Container, Typography } from '@mui/material';
// import { Link as RouterLink } from 'reactrouterdom';
import { Box, styled } from '@mui/system';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';
import { useMsal } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { getToken } from 'src/redux/actions/login';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
// import { useEffect } from 'react';

export default function ExpireTokenPage() {
  const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  }));

  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const location = useLocation();
  const isUnauthorized = location?.state?.isProfileCompleted;
  // eslint-disable-next-line no-debugger

  useEffect(() => {
    dispatch(getToken(''));
  }, []);

  return (
    // eslintdisablenextline react/selfclosingcomp
    <Box className="publicprofile">
      <LogoOnlyLayout />
      <Container maxWidth="sm">
        <ContentStyle sx={{ textAlign: 'center' }} className="toppadd_reset  containerstyle">
          <Typography sx={{ px: 2, mt: 5 }} variant="h4" className="main_heading">
            Expired Invitation
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }} className="heading_16">
            Your invitation has expired. Please contact your Team Lead or Admin.
          </Typography>
        </ContentStyle>
      </Container>
    </Box>
  );
}
