/* eslint-disable import/order */
/* eslint-disable no-debugger */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-dupe-else-if */
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line import/no-duplicates
import { Alert, InputAdornment, Snackbar, TextField } from '@mui/material';
import PropTypes from 'prop-types';
// import { DatePicker } from '@mui/x-date-pickers';
// components
import { useState } from 'react';
import { useSelector } from 'react-redux';
import DeleteSuccess from 'src/sections/mui/dialog/DeleteSuccess';
import MemberDeleteDialog from 'src/sections/mui/dialog/MemberDeleteDialog';
import NudgeDialoge from 'src/sections/mui/dialog/NudgeDialoge';
import AxiosAll from 'src/service/AxiosAll';
import Iconify from '../../../../components/Iconify';
// eslint-disable-next-line import/order
// eslint-disable-next-line import/order
import WarningDialog from 'src/sections/mui/dialog/warning';
import { useNavigate } from 'react-router';

// ----------------------------------------------------------------------

const INPUT_WIDTH = 160;

RecycleBinToolebar.propTypes = {
  filterName: PropTypes.string,
  filterService: PropTypes.string,
  // filterEndDate: PropTypes.instanceOf(Date),
  // filterStartDate: PropTypes.instanceOf(Date),
  onFilterName: PropTypes.func,
  // onFilterEndDate: PropTypes.func,
  onFilterService: PropTypes.func,
  // onFilterStartDate: PropTypes.func,
  optionsService: PropTypes.arrayOf(PropTypes.string),
};

export default function RecycleBinToolebar({
  optionsService,
  filterName,
  filterService,
  onFilterName,
  tableData,
  onFilterService,
  selected,
  selectedRow,
  setReload,
  setSelected,
  MainTable,
  makeTeam,
  makeAdmin,
  teamName,
  teamLength,
  user,
}) {
  const { userDetails, userRole, token } = useSelector((state) => state.loginReducer);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [message, setMessage] = useState();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');
  const [openNudge, setOpenNudge] = useState(false);
  const [opensuceessModal, setOpenSuccessModal] = useState(false);
  const [RemoveTeamLeadOpen, setRemoveTeamLeadOpen] = useState(false);
  const Navigate = useNavigate();
  const handleModalOpen = () => {
    setOpenDeleteModal(true);
  };

  const handleNudge = async (row) => {
    // eslint-disable-next-line no-debugger
    if (selectedRow) {
      const dataObject = {
        teamId: [],
        userId: selectedRow,
      };
      const res = await AxiosAll('post', '/api/user/nudge', dataObject, token);
      if (res?.status === 200) {
        setOpenNudge(true);
        setSelected([]);
      } else {
        setType('error');
        setMessage(res.data.message);
        setOpen(true);
      }
    }
  };
  return (
    <>
      {/* new design  */}
      <Snackbar
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} severity={type || 'success'} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <div className="memberside-table" sx={{ p: 3 }}>
        <div className="member-btn-all">
          <></>
        </div>

        <div className="member-serch-btn">
          <TextField
            className="search_bar"
            size="small"
            sx={
              {
                // mx: 2
              }
            }
            value={filterName}
            onChange={(event) => onFilterName(event.target.value)}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              ),
            }}
          />
          {/* <Button
                        size="medium"
                        variant="contained"
                        className="newmemberbtn"
                        startIcon={<Iconify icon={'eva:plus-fill'} />}
                        onClick={() => Navigate('/add-customer')}
                        style={{ textTransform: 'initial' }}
                    >
                        Add a Customer
                    </Button> */}
        </div>
      </div>
      {openNudge && (
        <NudgeDialoge
          dataFiltered={tableData}
          multiSelect
          open={openNudge}
          setOpen={setOpenNudge}
          setReload={setReload}
        />
      )}
      {openDeleteModal && (
        <MemberDeleteDialog
          setSelected={setSelected}
          selectedRow={selectedRow}
          dataFiltered={tableData}
          setReload={setReload}
          setOpenSuccessModal={setOpenSuccessModal}
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
        />
      )}
      {opensuceessModal && (
        <DeleteSuccess
          dataFiltered={tableData}
          setOpenSuccessModal={setOpenSuccessModal}
          opensuceessModal={opensuceessModal}
          setReload={setReload}
        />
      )}

      {RemoveTeamLeadOpen && (
        <WarningDialog
          setSelected={setSelected}
          selectedRow={selectedRow}
          setReload={setReload}
          multiSelect={true}
          teamName={teamName}
          role={userRole?.data}
          RemoveTeamLeadOpen={RemoveTeamLeadOpen}
          setRemoveTeamLeadOpen={setRemoveTeamLeadOpen}
        />
      )}
    </>
  );
}
