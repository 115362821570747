export const SignIn = {
  auth: {
    clientId: '1ebd5094-17cd-408f-a407-9031710d85e0',
    authority: 'https://tripshiftuk.b2clogin.com/tripshiftuk.onmicrosoft.com/B2C_1_Tripshift_Platform',
    scopes: ['https://tripshiftuk.onmicrosoft.com/1ebd5094-17cd-408f-a407-9031710d85e0/TripShiftPlatformProduction'],
    prompt: 'login',
    nonce: 'defaultNonce',
    knownAuthorities: ['https://tripshiftuk.b2clogin.com'],
    redirectUri: `${window.location.origin}/login`,
  },

  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        // switch (level) {
        //   case LogLevel.Error:
        //     console.error(message);
        //     return;
        //   case LogLevel.Info:
        //     console.info('message', message);
        //     return;
        //   case LogLevel.Verbose:
        //     console.debug(message);
        //     return;
        //   case LogLevel.Warning:
        //     console.warn(message);
        //     return;
        // }
      },
    },
  },
};

export const RedirectURl = window.location.origin;

// export const SignUP = {
//   auth: {
//     clientId: '0967df98-142f-4f3d-807b-eb6b5143bc7b',
//     authority: 'https://tripshiftuk.b2clogin.com/tripshiftuk.onmicrosoft.com/B2C_1_TripShift_SignUp',
//     scopes: ['https://tripshiftuk.onmicrosoft.com/0967df98-142f-4f3d-807b-eb6b5143bc7b/TripShiftPlaform.Access'],
//     nonce: 'defaultNonce',
//     knownAuthorities: ['https://tripshiftuk.b2clogin.com'],
//     redirectUri: `${window.location.origin}/user-account-setup`,
//   },
// };
