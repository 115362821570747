/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Alert, Checkbox, Divider, MenuItem, Snackbar, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// utils
// components
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import AdminTeamLeadDelete from 'src/sections/mui/dialog/AdminTeamLeadDelete';
import { useNavigate } from 'react-router';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/service/AxiosAll';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import NudgeDialoge from 'src/sections/mui/dialog/NudgeDialoge';
import Label from '../../../../components/Label';
import { TableMoreMenu } from '../../../../components/table';
// eslint-disable-next-line import/no-named-as-default
import AllocateTeamDialogs from 'src/sections/mui/dialog/allocateTeam';

// ----------------------------------------------------------------------

TeamTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function TeamTableRow({
  row,
  user,
  selected,
  setReload,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
  setDeleteIds,
  DeleteUserIds,
}) {
  const theme = useTheme();

  const { teamName, teamId, engagement, membersCount, teamLead, carbonLevels, address } = row;
  const { userDetails, userRole, token } = useSelector((state) => state.loginReducer);

  const [openMenu, setOpenMenuActions] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [message, setMessage] = useState();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');
  const [openNudge, setOpenNudge] = useState(false);
  const [allocateTeam, setAllocateTeam] = useState(false);

  const navigate = useNavigate();
  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleNudge = async (row) => {
    // eslint-disable-next-line no-debugger
    if (row?.teamId) {
      const dataObject = {
        teamId: [row?.teamId],
        userId: [],
      };
      const res = await AxiosAll('post', '/api/user/nudge', dataObject, token);
      if (res?.status === 200) {
        setOpenNudge(true);
      } else {
        setType('error');
        setMessage(res.data.message);
        setOpen(true);
      }
    }
  };

  return (
    <>
      {' '}
      <Snackbar
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} severity={type || 'success'} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>
        <Tooltip
          disableFocusListener
          disableInteractive
          disableTouchListener
          title={engagement?.trim()}
          arrow
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                marginLeft: '-30px',
              },
            },
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -30],
                },
              },
            ],
          }}
        >
          <TableCell align="left" className="pending_tab_row" style={{ paddingLeft: '3.5vw' }}>
            <Label
              variant={theme.palette.mode === 'light' ? 'filled' : 'filled'}
              color={
                (engagement?.trim() === 'High' && 'success') ||
                (engagement?.trim() === 'Medium' && 'warning') ||
                (engagement?.trim() === 'Low' && 'error') ||
                'default'
              }
              sx={{ textTransform: 'capitalize', borderRadius: '50%', height: '14px', minWidth: '14px', padding: '0' }}
            >
              {}
              {/* {engagement?.trim()} */}
            </Label>
          </TableCell>
        </Tooltip>
        <TableCell align="left">
          {/* <Stack> */}
          <Typography variant="subtitle2" noWrap>
            {teamName}
          </Typography>
        </TableCell>

        <TableCell align="left">
          {/* className="time-new-name" */}
          <Typography variant="subtitle2" noWrap>
            {teamLead === null ? 'No team lead' : teamLead}
          </Typography>
        </TableCell>

        <TableCell align="left" style={{ paddingLeft: '3vw' }}>
          {membersCount}
        </TableCell>
        <Tooltip
          disableFocusListener
          disableInteractive
          disableTouchListener
          title={address?.trim()}
          arrow
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                marginLeft: '-30px',
              },
            },
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -30],
                },
              },
            ],
          }}
        >
          <TableCell align="left" className="noapp_tab_section" style={{ paddingLeft: '1.5vw' }}>
            <Typography variant="subtitle2" noWrap>
              {address?.trim()}
            </Typography>
          </TableCell>
        </Tooltip>
        <Tooltip
          disableFocusListener
          disableInteractive
          disableTouchListener
          title={carbonLevels?.trim()}
          arrow
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                marginLeft: '-30px',
              },
            },
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -30],
                },
              },
            ],
          }}
        >
          <TableCell align="left" className="pending_tab_row noapp_tab_section" style={{ paddingLeft: '3.5vw' }}>
            <Label
              variant={theme.palette.mode === 'light' ? 'filled' : 'filled'}
              color={
                (carbonLevels?.trim() === 'High' && 'error') ||
                (carbonLevels?.trim() === 'Medium' && 'warning') ||
                (carbonLevels?.trim() === 'Low' && 'success') ||
                'default'
              }
              sx={{ textTransform: 'capitalize', borderRadius: '50%', height: '14px', minWidth: '14px', padding: '0' }}
            >
              {/* {carbonLevels?.trim()} */}
              {}
            </Label>
          </TableCell>
        </Tooltip>
        <TableCell align="right" className="test-user-siderbar">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            sx={{
              p: 0,
              mt: 1.5,
              ml: 0.75,
            }}
            actions={
              <>
                {userRole?.data === 1 || userRole?.data === 5 ? (
                  <>
                    {teamLead ? (
                      <MenuItem
                        onClick={() => {
                          handleNudge(row);
                          handleCloseMenu();
                        }}
                      >
                        Nudge Team Lead
                      </MenuItem>
                    ) : (
                      <MenuItem disabled>Nudge Team Lead</MenuItem>
                    )}
                  </>
                ) : null}
                <MenuItem
                  onClick={() => {
                    navigate('/create-team-stepTwo', { state: { edit: true, teamDetail: row, addMember: true } });
                    handleCloseMenu();
                  }}
                >
                  Add Members
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    <div>TeamDetail</div>;
                    navigate('/team-detail', { state: { Id: teamId } });
                    handleCloseMenu();
                  }}
                >
                  View Team Details
                </MenuItem>
                {(userRole?.data === 1 || userRole?.data === 5) && teamLead === null ? (
                  <MenuItem
                    onClick={() => {
                      <div>TeamDetail</div>;
                      navigate('/allocate-team-lead', { state: { teamDetail: row, addMember: true } });
                      handleCloseMenu();
                    }}
                  >
                    Allocate Team Lead
                  </MenuItem>
                ) : (
                  <MenuItem disabled>Allocate Team Lead</MenuItem>
                )}
                <Divider />
                <MenuItem
                  className=""
                  sx={{ color: 'error.main' }}
                  onClick={() => {
                    setOpenDeleteModal(true);
                    handleCloseMenu();
                  }}
                >
                  Delete Team
                </MenuItem>
              </>
            }
          />
        </TableCell>
        <AdminTeamLeadDelete
          setReload={setReload}
          teamId={row?.teamId}
          teamName={row?.teamName}
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          setDeleteIds={setDeleteIds}
          DeleteUserIds={DeleteUserIds}
        />
        {openNudge && <NudgeDialoge team open={openNudge} row={row} setOpen={setOpenNudge} setReload={setReload} />}

        {allocateTeam ? (
          <AllocateTeamDialogs setReload={setReload} open={allocateTeam} TeamId={teamId} setOpen={setAllocateTeam} />
        ) : null}
      </TableRow>
    </>
  );
}
