/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable no-debugger */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
/* eslint-disable object-shorthand */
/* eslint-disable import/extensions */
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Card, Grid, Stack, Typography, Container, Avatar } from '@mui/material';
// utils
// eslint-disable-next-line import/no-unresolved
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// eslint-disable-next-line import/no-unresolved
import useSettings from 'src/hooks/useSettings';
// eslint-disable-next-line import/no-unresolved
import Page from 'src/components/Page';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved

// eslint-disable-next-line import/no-unresolved
import { imagesAll } from 'src/assets/Images_index';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/service/AxiosAll';
import { useDispatch, useSelector } from 'react-redux';
// routes
// _mock
// components
import { FormProvider } from '../../components/hook-form';
// eslint-disable-next-line import/order, import/no-unresolved
import Image from 'src/components/Image';
import ProfileSkeleton from 'src/components/skeleton/profileSkeleton';
import { updateImage, updateName } from 'src/redux/actions/login';

// ----------------------------------------------------------------------
const label = { inputProps: { 'aria-label': 'Switch demo' } };
export default function ViewProfile() {
  const navigate = useNavigate();
  const { themeStretch } = useSettings();
  const Organisation = ['Marketing', 'Sales', 'Digital', 'IT'];
  const { userDetails, token } = useSelector((state) => state.loginReducer);
  const [memberDetail, setMemberDetail] = useState();
  const [loadingPage, setLoadingPage] = useState(false);
  const [checked, setChecked] = useState(memberDetail?.allowPlatformNotification);
  const [organisationOption, setOrganisationOption] = useState([]);
  const [checkMToF, setMToF] = useState(false);

  const dispatch = useDispatch();

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Team name is required'),
    Organisation: Yup.string().required('Function in Organisation* is required'),
  });

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();
  const location = useLocation();
  const memberId = location?.state?.memberId;
  // Define a function to fetch the organization list
  const getOrganisationList = async () => {
    const res = await AxiosAll('get', `/api/function/get-organizations`, {}, token);

    if (res?.status === 200) {
      const filter = res?.data?.data?.filter((e) => e.id === memberDetail?.businessTargetUnit);
      setOrganisationOption(filter);
    }
  };

  // Use useEffect to call getOrganisationList when memberDetail.businessTargetUnit changes
  useEffect(() => {
    getOrganisationList();
  }, [memberDetail?.businessTargetUnit]);

  // Define a function to fetch member details
  const getMemberDetail = async (id) => {
    setLoadingPage(true);
    const res = await AxiosAll('get', `/api/user/member-detail?userId=${id}`, {}, token);
    if (res?.status === 200) {
      setLoadingPage(false);
      setMemberDetail(res.data.data);
      if (id === userDetails?.data?.data?.id) {
        dispatch(updateImage(res.data.data?.photoURL));
        const name = `${res.data.data?.firstName} ${res?.data?.data?.lastName}`;
        dispatch(updateName(name));
      }
      getOrganisationList();
    }
    setLoadingPage(false);
  };

  // Use useEffect to call getMemberDetail when memberId changes
  const handleEditProfile = () => {
    navigate('/manage-profile', { state: { memberId: memberId } });
  };

  // Use useEffect to call getMemberDetail when memberId changes
  useEffect(() => {
    if (memberId) {
      getMemberDetail(memberId);
    }
  }, [memberId]);

  // Use useEffect to update the 'checked' state when memberDetail changes
  useEffect(() => {
    setChecked(memberDetail?.allowPlatformNotification ? true : false);
  }, [memberDetail]);

  // Use useEffect to determine if all start times are equal and update 'checkMToF'
  useEffect(() => {
    const allStartTimesEqual = memberDetail?.days?.every(
      (event) => event?.startTime === memberDetail?.days[0].startTime && event.timeGroupId === 1
    );
    setMToF(allStartTimesEqual);
  }, [memberDetail]);

  return (
    <Page title="Manage Profile" className="manage-member">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Manage Profile"
          links={[
            { name: 'TripShift Platform', href: '/dashboard' },
            { name: 'Profile', href: '/profile' },
            { name: 'Manage profile' },
          ]}
        />
        <FormProvider>
          {loadingPage ? (
            <ProfileSkeleton />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <Box sx={{ mb: 5 }}>
                  <div className="detail-page-profile">
                    <Avatar src={memberDetail?.photoURL} />
                  </div>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    sx={{ alignItems: 'center', justifyContent: 'center', mt: 2 }}
                    spacing={1}
                    className="change-profile-img-edit"
                  >
                    <Image src={imagesAll.pencil} onClick={handleEditProfile} className="pencil_img_profile" />
                    <Typography
                      variant="body1"
                      sx={{ textTransform: 'capitalize', color: 'text.secondary' }}
                      className="heading12_edit"
                    >
                      Change Picture
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <div className="team-detail-inner profile-inner">
                  <fieldset>
                    <legend>
                      <Image src={imagesAll.pencil} onClick={handleEditProfile} className="pencil_img" />
                    </legend>

                    <Card sx={{ boxShadow: 'none', pt: 1, backgroundColor: 'transparent' }}>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
                        <Box>
                          <Typography variant="h4" sx={{ textTransform: 'capitalize' }} className="heading12_edit">
                            Personal Details
                          </Typography>
                          <Typography
                            variant="overline"
                            sx={{ textTransform: 'capitalize' }}
                            className="heading12_edit"
                          >
                            Name
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ color: 'text.secondary', mb: 2 }}
                            className="heading12_edit"
                          >
                            {memberDetail?.firstName} {memberDetail?.lastName}
                          </Typography>
                          <Typography
                            variant="overline"
                            sx={{ textTransform: 'capitalize' }}
                            className="heading12_edit"
                          >
                            Email
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ color: 'text.secondary', mb: 2 }}
                            className="heading12_edit"
                          >
                            {memberDetail?.userEmail}
                          </Typography>
                          <Typography
                            variant="overline"
                            sx={{ textTransform: 'capitalize' }}
                            className="heading12_edit"
                          >
                            Job title
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ color: 'text.secondary', mb: 2 }}
                            className="heading12_edit"
                          >
                            {memberDetail?.jobTitle}
                          </Typography>
                          {memberDetail?.businessTargetUnit !== null ? (
                            <>
                              {' '}
                              <Typography
                                variant="overline"
                                sx={{ textTransform: 'capitalize' }}
                                className="heading12_edit"
                              >
                                Business unit
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ color: 'text.secondary', mb: 2 }}
                                className="heading12_edit"
                              >
                                {organisationOption?.length && memberDetail ? organisationOption[0]?.name : null}
                              </Typography>
                            </>
                          ) : null}

                          <Typography variant="overline" sx={{ textTransform: 'initial' }} className="heading12_edit">
                            Work hours (including commuting hours)
                          </Typography>
                          {memberDetail?.days[0]?.timeGroupId === 3 ? (
                            <>
                              {memberDetail?.days?.length &&
                                memberDetail?.days?.map((data, key) => {
                                  return (
                                    <div
                                      className="custom-time-box"
                                      key={key}
                                      style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
                                    >
                                      <Typography variant="subtitle1" sx={{ color: '#007B55' }}>
                                        {data?.day === 2
                                          ? 'Monday '
                                          : data?.day === 3
                                          ? 'Tuesday '
                                          : data?.day === 4
                                          ? 'Wednesday '
                                          : data?.day === 5
                                          ? 'Thursday '
                                          : data?.day === 6
                                          ? 'Friday '
                                          : data?.day === 7
                                          ? 'Saturday '
                                          : data?.day === 1
                                          ? 'Sunday'
                                          : ''}
                                      </Typography>
                                      <Typography variant="body" sx={{ ml: 2, color: '#007B55' }}>
                                        {' '}
                                        {data?.startTime}-{data?.endTime}
                                      </Typography>
                                    </div>
                                  );
                                })}
                            </>
                          ) : (
                            <Typography variant="body1" className="time-text heading12_edit" sx={{ mb: 3 }}>
                              {memberDetail?.days[0]?.startTime} - {memberDetail?.days[0]?.endTime}{' '}
                              {memberDetail?.days[0]?.day === 2
                                ? 'Monday '
                                : memberDetail?.days[0]?.day === 3
                                ? 'Tuesday '
                                : memberDetail?.days[0]?.day === 4
                                ? 'Wednesday '
                                : memberDetail?.days[0]?.day === 5
                                ? 'Thursday '
                                : memberDetail?.days[0]?.day === 6
                                ? 'Friday '
                                : memberDetail?.days[0]?.day === 7
                                ? 'Saturday '
                                : memberDetail?.days[0]?.day === 1
                                ? 'Sunday'
                                : ''}
                              to {'   '}
                              {memberDetail?.days[memberDetail?.days?.length - 1]?.day === 2
                                ? 'Monday '
                                : memberDetail?.days[memberDetail?.days?.length - 1]?.day === 3
                                ? 'Tuesday '
                                : memberDetail?.days[memberDetail?.days?.length - 1]?.day === 4
                                ? 'Wednesday '
                                : memberDetail?.days[memberDetail?.days?.length - 1]?.day === 5
                                ? 'Thursday '
                                : memberDetail?.days[memberDetail?.days?.length - 1]?.day === 6
                                ? 'Friday '
                                : memberDetail?.days[memberDetail?.days?.length - 1]?.day === 7
                                ? 'Saturday '
                                : memberDetail?.days[memberDetail?.days?.length - 1]?.day === 1
                                ? 'Sunday'
                                : ''}
                            </Typography>
                          )}
                        </Box>
                      </Stack>
                    </Card>
                  </fieldset>

                  <fieldset style={{ marginTop: '2rem' }}>
                    <legend style={{ textAlign: 'left' }}>
                      <Typography variant="h4" sx={{ textTransform: 'capitalize' }} className="heading12_edit">
                        TripShift Details
                      </Typography>
                    </legend>
                    <Card sx={{ boxShadow: 'none', pt: 1, backgroundColor: 'transparent' }}>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
                        <Box>
                          <Typography
                            variant="overline"
                            sx={{ textTransform: 'capitalize' }}
                            className="heading12_edit"
                          >
                            Role on TripShift Platform
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ color: 'text.secondary', mb: 2 }}
                            className="heading12_edit"
                          >
                            {memberDetail?.roleName}
                          </Typography>
                          <Typography
                            variant="overline"
                            sx={{ textTransform: 'capitalize' }}
                            className="heading12_edit"
                          >
                            TripShift Team
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ color: 'text.secondary', mb: 2 }}
                            className="heading12_edit"
                          >
                            {memberDetail?.teamName}
                          </Typography>
                        </Box>
                      </Stack>
                    </Card>
                  </fieldset>
                </div>
              </Grid>
            </Grid>
          )}
        </FormProvider>
      </Container>
    </Page>
  );
}
