/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-debugger */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-lone-blocks */
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { addLogin, deleteLogin, getAccessToken, updateImage, updateRole } from 'src/redux/actions/login';
import { store } from 'src/redux/store';
// import currentURL from '../env';

const fetchData = async () => {
  const { AllToken } = store.getState(); // Access the entire state
  if (AllToken?.refresh_token) {
    try {
      const response = await AxiosAll('post', '/api/user/loginazure', '', `Bearer ${AllToken?.refresh_token}`);
      // eslint-disable-next-line no-debugger
      if (response.data.statusCode === 200) {
        await store.dispatch(addLogin(response));
        await store.dispatch(updateRole(response?.data?.data?.roleId));
        await store.dispatch(updateImage(response.data.data?.photoURL));
        await store.dispatch(getAccessToken(response.data.data?.token));

        if (
          response?.data?.data?.roleId === 2 &&
          response?.data?.data?.assignTeamId === response?.data?.data?.defaultTeamId
        ) {
          window.location.href = '/create-team-stepOne?createNew=true';
        } else if (
          response?.data?.data?.roleId === 2 &&
          response?.data?.data?.assignTeamId !== response?.data?.data?.defaultTeamId
        ) {
          window.location.href = '/dashboard';
        } else {
          window.location.href = '/dashboard';
        }
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      store.dispatch(deleteLogin());
      store.dispatch(updateRole());
      store.dispatch(getAccessToken());
      handleLogOutSSo();
      localStorage.clear();
      sessionStorage.clear();
    }
  }
};

const handleLogOutSSo = () => {
  const { instance, accounts } = useMsal();

  instance.logoutRedirect({
    postLogoutRedirectUri: `${`${window.location.origin}/login`}`,
    mainWindowRedirectUri: `${window.location.origin}`, // Specify the main window redirect URI
  });
};

const AxiosAll = async (method, url, params, userToken, contentType) => {
  const baseURL = 'https://tsp2023uk.azurewebsites.net';
  return axios({
    method,
    url: `${baseURL}${url}`,
    data: params,
    headers: {
      'Content-Type': contentType ?? 'application/json',
      // eslint-disable-next-line no-unneeded-ternary
      Authorization: userToken ? userToken : '',
    },
  })
    .then((res) => {
      if (res.data.statusCode == 401) {
        store.dispatch(deleteLogin());
        store.dispatch(updateRole());
        store.dispatch(getAccessToken());
        handleLogOutSSo();
        localStorage.clear();
        sessionStorage.clear();
      }
      return res;
    })
    .catch((err) => {
      {
        if (err?.response?.status == 401) {
          fetchData();
        }
      }
      return err;
    });
};
export default AxiosAll;
