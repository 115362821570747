/* eslint-disable import/order */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-debugger */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-dupe-else-if */
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line import/no-duplicates
import PropTypes from 'prop-types';
// import { DatePicker } from '@mui/x-date-pickers';
// components

// eslint-disable-next-line import/order
// eslint-disable-next-line import/order
import UploadCSVDialogs from 'src/sections/mui/dialog/UploadCSV';
import { LoadingButton } from '@mui/lab';
// import currentURL from '../../../../env';

// ----------------------------------------------------------------------

const INPUT_WIDTH = 160;

UploadcsvToolbar.propTypes = {
  filterName: PropTypes.string,
  filterService: PropTypes.string,
  // filterEndDate: PropTypes.instanceOf(Date),
  // filterStartDate: PropTypes.instanceOf(Date),
  onFilterName: PropTypes.func,
  // onFilterEndDate: PropTypes.func,
  onFilterService: PropTypes.func,
  // onFilterStartDate: PropTypes.func,
  optionsService: PropTypes.arrayOf(PropTypes.string),
};

export default function UploadcsvToolbar({ setReload }) {
  const handleDownloadCSV = () => {
    window.open(`/assets/templates/SampleTemplate.csv`);
  };

  return (
    <>
      {/* new design  */}

      <div className=" new-member-table">
        <div className="member-serch-btn download-template-btn">
          <>
            {' '}
            <LoadingButton
              sx={{ mr: 2, mb: 0 }}
              variant="contained"
              className="download-template"
              onClick={handleDownloadCSV}
            >
              Download Template
            </LoadingButton>
            <UploadCSVDialogs setReload={setReload} />
          </>
        </div>
      </div>
    </>
  );
}
