// scroll bar
import 'simplebar/src/simplebar.css';
// lazy image
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import "slick-carousel/slick/slick.css";

import 'slick-carousel/slick/slick-theme.css';
// contexts
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { SettingsProvider } from './contexts/SettingsContext';
//
import App from './App';
import { store } from './redux/store';
// import './scss/main.scss';
import './scss/main.scss';
// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <SettingsProvider>
    <Provider store={store}>
      <HelmetProvider>
        <CollapseDrawerProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </CollapseDrawerProvider>
      </HelmetProvider>
    </Provider>
  </SettingsProvider>
);
