/* eslint-disable object-shorthand */
/* eslint-disable no-debugger */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { useEffect, useState } from 'react';
// @mui
import { Box, Button, Dialog, DialogActions, Grid, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Iconify from 'src/components/Iconify';
import AxiosAll from 'src/service/AxiosAll';
// ----------------------------------------------------------------------

const emails = ['username@gmail.com', 'user02@gmail.com'];

export default function WarningDialog({
  setReload,
  setRemoveTeamLeadOpen,
  RemoveTeamLeadOpen,
  alertOpne,
  selectedRow,
  setAlert,
  role,
  multiSelect,
  setSelected,
  teamName,
  setOpenConfirmationRemoveLead,
  selectedUserIds,
  setRoleKey,
  userName,
  userEmail,
  userId,
  roleId,
  DeleteUserIds,
  setDeleteIds,
}) {
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);
  const theme = useTheme();
  const [opensuceessModal, setOpenSuccessModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState(emails[1]);
  const [checked, setChecked] = useState(true);
  const [open, setOpen] = useState(false);
  const { userDetails, token } = useSelector((state) => state.loginReducer);
  const navigate = useNavigate();
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClickOpen = () => {
    setRemoveTeamLeadOpen(true);
    handleCloseMenu('happy');
  };

  useEffect(() => {
    if (alertOpne === true) setRemoveTeamLeadOpen(alertOpne);
  }, [alertOpne]);

  const handleCloseSuccess = () => {
    if (open) {
      if (multiSelect) {
        setSelected([]);
        setDeleteIds(selectedRow);
        setRemoveTeamLeadOpen(false);
        setReload(true);
        navigate('/members');
      } else {
        DeleteUserIds?.push(userId);
        setRemoveTeamLeadOpen(false);
        setReload(true);
        navigate('/members');
      }
    } else {
      setOpenSuccessModal(false);
      setReload(true);
      navigate('/members');
    }
  };

  const handleClose = (value) => {
    setRemoveTeamLeadOpen(false);
    setAlert(false);
    setSelectedValue(value);
  };

  const handleCloseMenu = (value) => {};
  const getTeamId = localStorage.getItem('teamId');

  const RemoveMemberToTeam = async () => {
    if (userId) {
      const data = {
        teamId: userDetails?.data?.data?.assignTeamId === 0 ? getTeamId : userDetails?.data?.data?.assignTeamId,
        userId: [userId],
      };
      const res = await AxiosAll('post', '/api/team/update-team-members', data, token);
      if (res?.status === 200) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    } else {
      const data = {
        teamId: userDetails?.data?.data?.assignTeamId === 0 ? getTeamId : userDetails?.data?.data?.assignTeamId,
        userId: selectedRow,
      };
      const res = await AxiosAll('post', '/api/team/update-team-members', data, token);
      if (res?.status === 200) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  };

  const handleUpdateRole = async () => {
    if (userId) {
      const dataObject = {
        userId: userId,
        roleId: 1,
      };
      const res = await AxiosAll('post', '/api/user/update-role', [dataObject], token);
      if (res?.status === 200) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    } else {
      const dataObject = selectedRow?.map((id) => ({
        userId: id,
        roleId: 1,
      }));

      const res = await AxiosAll('post', '/api/user/update-role', dataObject, token);
      if (res?.status === 200) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  };

  const handleConfirm = () => {
    if (checked) {
      setOpenConfirmationRemoveLead(true);
      setRemoveTeamLeadOpen(false);
    }
  };
  const name = userName === ' ' ? userEmail : userName;
  return (
    <>
      <Box sx={{ textAlign: 'center' }}>
        <Dialog open={RemoveTeamLeadOpen} onClose={() => setRemoveTeamLeadOpen(false)} className="toast-dialogs">
          {open && role === 2 ? (
            <Grid container spacing={2} className="MemberAlert">
              <Grid item xs={2}>
                <div className="checkicon-bg">
                  <Iconify icon={'ant-design:check-circle-filled'} width={24} height={24} className="" />
                </div>
              </Grid>

              <Grid item xs={8.5}>
                {multiSelect ? (
                  <div className="email-value ">
                    All selected members have been successfully removed from team {teamName}
                  </div>
                ) : (
                  <div className="email-value">
                    {name} has been successfully removed from team {teamName}
                  </div>
                )}
              </Grid>
              <Grid item xs={1.5} className="cross_section">
                <Iconify icon={'akar-icons:cross'} className="alert_cross" onClick={handleCloseSuccess} />
              </Grid>
            </Grid>
          ) : open && role === 5 ? (
            <Grid container spacing={2} className="MemberAlert">
              <Grid item xs={2}>
                <div className="checkicon-bg">
                  <Iconify icon={'ant-design:check-circle-filled'} width={24} height={24} className="" />
                </div>
              </Grid>

              <Grid item xs={8.5}>
                <div className="email-value">
                  {name} has been successfully removed from team {teamName}
                </div>
              </Grid>
              <Grid item xs={1.5} className="cross_section">
                <Iconify icon={'akar-icons:cross'} className="alert_cross" onClick={handleCloseSuccess} />
              </Grid>
            </Grid>
          ) : null}
          {role === 1 ? (
            <div className="warning-dialogsbox">
              <Typography variant="h6" className="heading_18" sx={{ mb: 1 }}>
                {' '}
                ! Warning
              </Typography>

              <Typography variant="body2" className="heading_16 boldttext" sx={{ pl: 1 }}>
                You are about to remove {name} as Team Lead. This means the {teamName} team will have no team lead until
                another one is assigned.
              </Typography>

              <DialogActions className="delete-end-side">
                <Button onClick={handleConfirm} variant="contained" size="large" className="confirm-btn">
                  Confirm
                </Button>

                <Button
                  onClick={() => setRemoveTeamLeadOpen(false)}
                  variant="contained"
                  className="cancle_btn"
                  size="large"
                >
                  Cancel
                </Button>
              </DialogActions>
            </div>
          ) : role === 5 ? (
            <div className="warning-dialogsbox">
              <Typography variant="h6" className="heading_18" sx={{ mb: 1 }}>
                {' '}
                ! Warning
              </Typography>

              <Typography variant="body2" className="heading_16 boldttext" sx={{ pl: 1 }}>
                You are about to remove {name} as Team Lead. This means the {teamName} team will have no team lead until
                another one is assigned.
              </Typography>

              <DialogActions className="delete-end-side">
                <Button onClick={handleConfirm} variant="contained" size="large" className="confirm-btn">
                  Confirm
                </Button>

                <Button
                  onClick={() => setRemoveTeamLeadOpen(false)}
                  variant="contained"
                  className="cancle_btn"
                  size="large"
                >
                  Cancel
                </Button>
              </DialogActions>
            </div>
          ) : open === false && role === 2 ? (
            <>
              {multiSelect ? (
                <div className="warning-dialogsbox">
                  <Typography variant="body2" className="heading_16 boldttext" sx={{ pl: 1, mb: 1.5 }}>
                    Confirm removal of all selected members from team {teamName}?
                  </Typography>

                  <DialogActions className="delete-end-side">
                    <Button onClick={RemoveMemberToTeam} variant="contained" size="large" className="confirm-btn">
                      Confirm
                    </Button>

                    <Button
                      onClick={() => setRemoveTeamLeadOpen(false)}
                      variant="contained"
                      className="cancle_btn"
                      size="large"
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </div>
              ) : (
                <div className="warning-dialogsbox">
                  <Typography variant="body2" className="heading_16 boldttext" sx={{ pl: 1.5 }}>
                    {name} is a member of {teamName}
                  </Typography>
                  <Typography variant="body2" className="heading_16 boldttext" sx={{ pl: 1, mb: 1 }}>
                    Confirm removal of {name} as member?
                  </Typography>

                  <DialogActions className="delete-end-side">
                    <Button onClick={RemoveMemberToTeam} variant="contained" size="large" className="confirm-btn">
                      Confirm
                    </Button>

                    <Button
                      onClick={() => setRemoveTeamLeadOpen(false)}
                      variant="contained"
                      className="cancle_btn"
                      size="large"
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </div>
              )}
            </>
          ) : null}
        </Dialog>
      </Box>

      <Dialog open={opensuceessModal} onClose={handleCloseSuccess} className="toast-dialogs">
        {role === 1 ? (
          <Grid container spacing={2} className="MemberAlert">
            <Grid item xs={2}>
              <div className="checkicon-bg">
                <Iconify icon={'ant-design:check-circle-filled'} width={24} height={24} className="" />
              </div>
            </Grid>
            <Grid item xs={8.5}>
              <div className="email-value">
                {name} is no longer a team
                <br />
                lead.
              </div>
            </Grid>
            <Grid item xs={1.5} className="cross_section">
              <Iconify icon={'akar-icons:cross'} className="alert_cross" onClick={handleCloseSuccess} />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} className="MemberAlert">
            <Grid item xs={2}>
              <div className="checkicon-bg">
                <Iconify icon={'ant-design:check-circle-filled'} width={24} height={24} className="" />
              </div>
            </Grid>
            <Grid item xs={8.5}>
              <div className="email-value">
                {name} is no longer a part of team {teamName}
              </div>
            </Grid>
            <Grid item xs={1.5} className="cross_section">
              <Iconify icon={'akar-icons:cross'} className="alert_cross" onClick={handleCloseSuccess} />
            </Grid>
          </Grid>
        )}
      </Dialog>
    </>
  );
}
