/* eslint-disable import/order */
/* eslint-disable arrow-body-style */
/* eslint-disable import/no-unresolved */

import { Navigate, Route, Routes } from 'react-router-dom';
import AdminRegister from 'src/pages/auth/AdminRegister';
import SuccessfulSetup from 'src/pages/SuccessfulSetup';
import CreatePassword from 'src/pages/auth/CreatePassword';
import ComingSoon from 'src/pages/ComingSoon';
import Maintenance from 'src/pages/Maintenance';
import Page403 from 'src/pages/Page403';
import Page404 from 'src/pages/Page404';
import Page500 from 'src/pages/Page500';
import Profile from 'src/pages/Profile';
import Login from '../pages/auth/Login';
import NewPassword from '../pages/auth/NewPassword';
import Register from '../pages/auth/Register';
import ResetPassword from '../pages/auth/ResetPassword';
import VerifyCode from '../pages/auth/VerifyCode';
import SuperAdminRegister from '../pages/auth/SuperAdminRegister';
import ExpireTokenPage from 'src/pages/ExpireTokenPage';

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="super-admin-register" element={<SuperAdminRegister />} />
      <Route path="register" element={<Register />} />
      <Route path="new-password" element={<NewPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="verify-code" element={<VerifyCode />} />
      <Route path="successfull-setup" element={<SuccessfulSetup />} />
      <Route path="expire-token" element={<ExpireTokenPage />} />
      <Route path="admin-register" element={<AdminRegister />} />
      <Route path="create-password" element={<CreatePassword />} />
      <Route path="user-account-setup" element={<Profile />} />
      <Route path={'404'} element={<Page404 />} />
      <Route path={'403'} element={<Page403 />} />
      <Route path={'500'} element={<Page500 />} />
      <Route path={'maintenance'} element={<Maintenance />} />
      <Route path={'coming-soon'} element={<ComingSoon />} />
      <Route path={'admin-login'} element={<Login />} />
      <Route path="*" element={<Navigate to="login" replace />} /> <Route path={'/'} element={<Login />} />
      <Route path={'*'} element={<Login />} />
    </Routes>
  );
};

export default PublicRoutes;
