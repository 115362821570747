/* eslint-disable import/no-unresolved */
/* eslintdisable import/nounresolved */
import { Container, Typography } from '@mui/material';
// import { Link as RouterLink } from 'reactrouterdom';
import { Box, styled } from '@mui/system';
import Image from 'src/components/Image';
import { imagesAll } from 'src/assets/Images_index';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';
import { useMsal } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { getToken } from 'src/redux/actions/login';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
// import { useEffect } from 'react';

export default function SuccessfulSetup() {
  const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  }));

  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const location = useLocation();
  const isUnauthorized = location?.state?.isProfileCompleted;
  // eslint-disable-next-line no-debugger

  useEffect(() => {
    dispatch(getToken(''));
  }, [accounts]);

  return (
    // eslintdisablenextline react/selfclosingcomp
    <Box className="publicprofile">
      <LogoOnlyLayout />
      <Container maxWidth="sm">
        <ContentStyle sx={{ textAlign: 'center' }} className="toppadd_reset  containerstyle">
          <div className="error404">
            <Image src={imagesAll.seo} className="error404_img2" style={{ marginBottom: '10px' }} />
          </div>
          <Typography sx={{ px: 2, mt: 5 }} variant="h4" className="main_heading">
            Congrats, you are now a TripShifter!
          </Typography>
          {isUnauthorized ? (
            <Typography sx={{ color: 'text.secondary', mb: 3 }} className="heading_16">
              Your account has already been created. Please remember to download the TripShift app on your phone!
            </Typography>
          ) : (
            <Typography sx={{ color: 'text.secondary', mb: 3 }} className="heading_16">
              Thanks for sharing your details. Please continue using the TripShift app.
            </Typography>
          )}
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 20 }}>
            <a href='https://apps.apple.com/gb/app/tripshift/id1537958896' target="_blank" rel="noreferrer">
              <Image
                  src={'https://tripshiftportalstatic.blob.core.windows.net/email-assets/images/Download_on_the_App_Store_Badge_US-UK_wht_092917.png'}
                  sx={{ height: 'auto', width: '150px', mr: 1 }}
                  alt="Get it on Apple App Store"
              />
            </a>
            <a href='https://play.google.com/store/apps/details?id=com.tripshift' target="_blank" rel="noreferrer">
              <Image
                  src={'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'}
                  sx={{ height: 'auto', width: '170px', mr: 1 }}
                  alt="Get it on Google Play Store"
              />
            </a>
          </div>
          {/* <Image src={imagesAll.qrCode} className="qrcodeimg" /> */}
        </ContentStyle>
      </Container>
    </Box>
  );
}
