/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-boolean-value */
import PropTypes from 'prop-types';
import { useEffect } from 'react';
// @mui
import { Checkbox, TableCell, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// utils
// components
// eslint-disable-next-line import/no-named-as-default

// ----------------------------------------------------------------------

CurrentTeamTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function CurrentTeamTableRow({
  row,
  selected,
  nameOfTeam,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
  selectedUser,
  setselectedUser,
}) {
  const theme = useTheme();
  const { sent, firstName, lastName, member, role, roleId, email, id, invoiceTo, level } = row;
  const name = firstName && lastName ? `${firstName} ${lastName}` : email;

  useEffect(() => {
    if (selectedUser?.length) {
      const timer1 = setTimeout(() => setselectedUser(null), 3 * 1000);
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [selectedUser]);

  return (
    <TableRow className={selectedUser?.includes(id) ? 'add-team-selected' : ''} hover selected={selected}>
      {selectedUser?.includes(id) ? (
        <TableCell padding="checkbox" className="checkbox-edit-section">
          <Checkbox checked={true} className="green_checkbox" />
        </TableCell>
      ) : roleId === 5 || roleId === 2 ? (
        <TableCell padding="checkbox" className="checkbox-edit-section">
          <Checkbox disabled />
        </TableCell>
      ) : (
        <TableCell padding="checkbox" className="checkbox-edit-section">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>
      )}
      <TableCell align="left" sx={{ fontWeight: '600' }}>
        {name}
      </TableCell>
      <TableCell align="left" sx={{ fontStyle: 'italic' }}>
        {nameOfTeam}
      </TableCell>
    </TableRow>
  );
}
