/* eslint-disable import/no-unresolved */

import { styled } from '@mui/material/styles';
// layouts
// eslint-disable-next-line import/order
// components
// eslint-disable-next-line import/order
import Page from '../../components/Page';
// sections
// eslint-disable-next-line import/order
import ChangePasswordForm from 'src/sections/auth/change-password';
// eslint-disable-next-line import/order
import { Container, Typography } from '@mui/material';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  maxWidth: 480,
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ChangePassword() {
  return (
    <Page title="Change Password" className="changepassword">
      <Container maxWidth="sm">
        <ContentStyle sx={{}} className="toppadd_reset  container-style">
          <Typography variant="h3" paragraph className="main_heading changeheading">
            Change password
          </Typography>
          <ChangePasswordForm />
        </ContentStyle>
      </Container>
    </Page>
  );
}
