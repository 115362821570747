/* eslint-disable react/jsx-key */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable import/extensions */
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Card, Grid, Stack, Typography, Container, Avatar } from '@mui/material';
import LoadingOverlay from 'react-loading-overlay';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import useSettings from 'src/hooks/useSettings';
import Page from 'src/components/Page';
import Image from 'src/components/Image';
import { imagesAll } from 'src/assets/Images_index';
import AxiosAll from 'src/service/AxiosAll';
import { useSelector } from 'react-redux';
import Label from '../../components/Label';
import { FormProvider } from '../../components/hook-form';

TeamDetail.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default function TeamDetail({ isEdit, currentUser }) {
  const navigate = useNavigate();
  const { themeStretch } = useSettings();
  const Organisation = ['Marketing', 'Sales', 'Digital', 'IT'];
  const { userDetails, token } = useSelector((state) => state.loginReducer);
  // Initialize state for team details and loading indicator
  const [teamDetail, setTeamDetail] = useState();
  const [loadingPage, setLoadingPage] = useState(false);

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Team name is required'),
    Organisation: Yup.string().required('Function in Organisation* is required'),
  });

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();
  const location = useLocation();
  const id = location?.state?.Id;
  // Handle navigation to the 'create-team-stepOne' page for editing
  const handleEditStepOne = () => {
    navigate('/create-team-stepOne', { state: { edit: true, teamDetail } });
  };

  // Handle navigation to the 'create-team-stepTwo' page for editing
  const handleEditStepTwo = () => {
    navigate('/create-team-stepTwo', { state: { edit: true, teamDetail } });
  };

  // Function to fetch team details
  const getTeamDetail = async (id) => {
    setLoadingPage(true);
    const data = {
      teamId: id,
    };
    const res = await AxiosAll('get', `/api/team/team-detail?teamId=${id}`, {}, token);

    if (res?.status === 200) {
      setTeamDetail(res.data.data);
      setLoadingPage(false);
    }
  };

  // Function to handle form submission
  const onSubmit = async (values) => {
    try {
      if (values?.name && values?.Organisation) {
        navigate('/create-team-stepTwo');
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Effect to fetch team details when 'id' changes
  useEffect(() => {
    if (id) {
      getTeamDetail(id);
    }
  }, [id]);

  return (
    <Page title="Team Details" className="manage-member">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Manage Teams"
          links={[
            { name: 'TripShift Platform', href: '/dashboard' },
            { name: 'Manage Team', href: '/teams' },
            { name: 'Team details' },
          ]}
        />
        <LoadingOverlay active={loadingPage} spinner>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <div className="team-detail-inner">
                  <fieldset>
                    <legend>
                      {id && <Image src={imagesAll.pencil} onClick={handleEditStepOne} className="pencil_img" />}
                    </legend>

                    <Card sx={{ boxShadow: 'none', pt: 1, backgroundColor: 'transparent' }}>
                      {isEdit && (
                        <Label
                          color={values.status !== 'active' ? 'error' : 'success'}
                          sx={{ textTransform: 'uppercase', position: 'absolute', top: 24, right: 24 }}
                        >
                          {values.status}
                        </Label>
                      )}
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={5}>
                            <Box sx={{ mb: 5 }}>
                              <div className="detail-page-profile">
                                <Avatar src={teamDetail?.photoUrl} />
                              </div>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={7}>
                            <Box sx={{ mb: 5 }}>
                              <Typography
                                variant="overline"
                                sx={{ textTransform: 'capitalize' }}
                                className="heading12_edit"
                              >
                                Team Name
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ color: 'text.secondary', mb: 2 }}
                                className="heading12_edit"
                              >
                                {teamDetail?.teamName}
                              </Typography>
                              <Typography
                                variant="overline"
                                sx={{ textTransform: 'initial' }}
                                className="heading12_edit"
                              >
                                Business Unit
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ color: 'text.secondary', mb: 3 }}
                                className="heading12_edit"
                              >
                                {teamDetail?.organization}
                              </Typography>
                              <Typography
                                variant="overline"
                                sx={{ textTransform: 'initial' }}
                                className="heading12_edit"
                              >
                                Main team location
                              </Typography>
                              <Typography variant="body1" sx={{ color: 'text.secondary' }} className="heading12_edit">
                                {teamDetail?.address}
                              </Typography>
                              <Typography variant="body1" sx={{ color: 'text.secondary' }} className="heading12_edit">
                                {teamDetail?.city}
                              </Typography>
                              <Typography variant="body1" sx={{ color: 'text.secondary' }} className="heading12_edit">
                                {teamDetail?.state}
                              </Typography>
                              <Typography variant="body1" sx={{ color: 'text.secondary' }} className="heading12_edit">
                                {teamDetail?.postalCode}
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ color: 'text.secondary', mb: 3 }}
                                className="heading12_edit"
                              >
                                {teamDetail?.country}
                              </Typography>
                              <Typography
                                variant="overline"
                                sx={{ textTransform: 'initial' }}
                                className="heading12_edit"
                              >
                                Work hours (including commuting hours)
                              </Typography>

                              {teamDetail?.days[0]?.timeGroupId === 3 ? (
                                <Typography variant="body1" className="time-text heading12_edit" sx={{ mb: 3 }}>
                                  {teamDetail?.days?.length &&
                                    teamDetail?.days?.map((data, key) => {
                                      return (
                                        <div
                                          className="custom-time-box"
                                          key={key}
                                          style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
                                        >
                                          <Typography variant="subtitle1" sx={{ color: '#007B55' }}>
                                            {data?.day === 2
                                              ? 'Monday '
                                              : data?.day === 3
                                              ? 'Tuesday '
                                              : data?.day === 4
                                              ? 'Wednesday '
                                              : data?.day === 5
                                              ? 'Thursday '
                                              : data?.day === 6
                                              ? 'Friday '
                                              : data?.day === 7
                                              ? 'Saturday '
                                              : data?.day === 1
                                              ? 'Sunday'
                                              : ''}
                                          </Typography>
                                          <Typography variant="body" sx={{ ml: 2, color: '#007B55' }}>
                                            {' '}
                                            {data?.startTime}-{data?.endTime}
                                          </Typography>
                                        </div>
                                      );
                                    })}
                                </Typography>
                              ) : (
                                <Typography variant="body1" className="time-text heading12_edit" sx={{ mb: 3 }}>
                                  {teamDetail?.days[0]?.startTime} - {teamDetail?.days[0]?.endTime}{' '}
                                  {teamDetail?.days[0]?.day === 2
                                    ? 'Monday '
                                    : teamDetail?.days[0]?.day === 3
                                    ? 'Tuesday '
                                    : teamDetail?.days[0]?.day === 4
                                    ? 'Wednesday '
                                    : teamDetail?.days[0]?.day === 5
                                    ? 'Thursday '
                                    : teamDetail?.days[0]?.day === 6
                                    ? 'Friday '
                                    : teamDetail?.days[0]?.day === 7
                                    ? 'Saturday '
                                    : teamDetail?.days[0]?.day === 1
                                    ? 'Sunday'
                                    : ''}
                                  to {'   '}
                                  {teamDetail?.days[teamDetail?.days?.length - 1]?.day === 2
                                    ? 'Monday '
                                    : teamDetail?.days[teamDetail?.days?.length - 1]?.day === 3
                                    ? 'Tuesday '
                                    : teamDetail?.days[teamDetail?.days?.length - 1]?.day === 4
                                    ? 'Wednesday '
                                    : teamDetail?.days[teamDetail?.days?.length - 1]?.day === 5
                                    ? 'Thursday '
                                    : teamDetail?.days[teamDetail?.days?.length - 1]?.day === 6
                                    ? 'Friday '
                                    : teamDetail?.days[teamDetail?.days?.length - 1]?.day === 7
                                    ? 'Saturday '
                                    : teamDetail?.days[teamDetail?.days?.length - 1]?.day === 1
                                    ? 'Sunday'
                                    : ''}
                                </Typography>
                              )}
                              <br />
                              <Typography
                                variant="overline"
                                sx={{ textTransform: 'initial' }}
                                className="heading12_edit"
                              >
                                Team description{' '}
                              </Typography>
                              <br />
                              <Typography
                                variant="body1"
                                sx={{ color: 'text.secondary', mb: 3 }}
                                className="heading12_edit"
                              >
                                {teamDetail?.description}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Stack>
                    </Card>
                  </fieldset>
                </div>
              </Grid>

              <Grid item xs={12} md={4}>
                <div className="team-detail-inner">
                  <fieldset>
                    <legend>
                      {id && <Image src={imagesAll.pencil} onClick={handleEditStepTwo} className="pencil_img" />}
                    </legend>
                    <Card sx={{ boxShadow: 'none', overflow: 'inherit', pt: 1, backgroundColor: 'transparent' }}>
                      <Typography variant="overline" sx={{ textTransform: 'capitalize' }} className="heading12_edit">
                        Team Members
                      </Typography>

                      {teamDetail?.listofMembers.map((data, index) => {
                        return (
                          <Typography
                            variant="body1"
                            sx={{ color: 'text.secondary', mb: 2, mt: 2 }}
                            className="heading12_edit"
                          >
                            {data?.firstName && data?.lastName ? `${data?.firstName} ${data?.lastName}` : data?.email}
                          </Typography>
                        );
                      })}
                    </Card>
                  </fieldset>
                </div>
              </Grid>
            </Grid>
          </FormProvider>
        </LoadingOverlay>
      </Container>
    </Page>
  );
}
