/* eslint-disable react/jsx-boolean-value */
/* eslint-disable object-shorthand */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Alert, MenuItem, Snackbar, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// utils
// components
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import { useNavigate } from 'react-router';
import { TableMoreMenu } from '../../../../components/table';
// eslint-disable-next-line import/no-named-as-default
import DeleteCustomer from 'src/sections/mui/dialog/DeleteCustomer';
import RestoreCustomer from 'src/sections/mui/dialog/RestoreCustomer';

// ----------------------------------------------------------------------

RecycleBinTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function RecycleBinTableRow({
  row,
  user,
  selected,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
  setReload,
  getCustomerList,
}) {
  const theme = useTheme();

  const { companyName, companyEmail, status, remainingLicence, totalLicence, companyId, tripshiftOwner } = row;
  const { userDetails, userRole, token } = useSelector((state) => state.loginReducer);
  const [openMenu, setOpenMenuActions] = useState(null);
  const [openRestoreModal, setOpenRestoreModal] = useState(false);
  const [message, setMessage] = useState();
  const [open, setOpen] = useState(false);
  const [DeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [type, setType] = useState('');

  const navigate = useNavigate();
  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };
  // eslint-disable-next-line no-debugger
  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <>
      {' '}
      <Snackbar
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} severity={type || 'success'} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">{/* <Checkbox checked={selected} onClick={onSelectRow} /> */}</TableCell>

        <TableCell>
          {/* <Stack> */}
          <Typography variant="subtitle2" noWrap>
            {companyName}
          </Typography>
        </TableCell>
        {/* <TableCell align="left">{fDate(createDate)}</TableCell> */}
        <TableCell align="left">{companyEmail}</TableCell>
        <TableCell align="left">{tripshiftOwner}</TableCell>
        <TableCell align="left">{totalLicence}</TableCell>
        <TableCell align="left">{remainingLicence}</TableCell>
        <TableCell align="right" className="test-user-siderbar">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            sx={{
              p: 0,
              mt: 1.5,
              ml: 0.75,
            }}
            actions={
              <>
                {' '}
                <MenuItem
                  onClick={() => {
                    navigate('/customer-detail', { state: { Id: companyId, View: true } });
                    handleCloseMenu();
                  }}
                  // className="set_admin"
                >
                  {/* <Iconify icon={'ant-design:star-filled'} /> */}
                  View Customer
                </MenuItem>
                <MenuItem
                  disabled={!!(companyId === 2 || companyId === 31)}
                  onClick={() => {
                    setOpenRestoreModal(true);
                    handleCloseMenu();
                  }}
                  className="view_member"
                  sx={{ mb: 1 }}
                >
                  {/* <Iconify icon={'eva:trash-2-outline'} /> */}
                  Restore Customer
                </MenuItem>
                <MenuItem
                  disabled={!!(companyId === 2 || companyId === 31)}
                  onClick={() => {
                    setDeleteModalOpen(true);
                    handleCloseMenu();
                  }}
                  className="view_member"
                  sx={{ mb: 1 }}
                >
                  {/* <Iconify icon={'eva:trash-2-outline'} /> */}
                  Permanent Delete
                </MenuItem>
              </>
            }
          />
        </TableCell>
      </TableRow>
      <DeleteCustomer
        open={DeleteModalOpen}
        getCustomerList={getCustomerList}
        companyId={companyId}
        setOpen={setDeleteModalOpen}
        name={companyName}
        deleteUser={true}
      />
      <RestoreCustomer
        open={openRestoreModal}
        getCustomerList={getCustomerList}
        companyId={companyId}
        setOpen={setOpenRestoreModal}
        name={companyName}
      />
    </>
  );
}
