/* eslint-disable no-lonely-if */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable valid-typeof */
/* eslint-disable no-debugger */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-unknown-property */
/* eslint-disable import/no-duplicates */
/* eslint-disable object-shorthand */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

/* eslint-disable import/extensions */
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// import AutoComplete from 'react-google-autocomplete';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// eslint-disable-next-line import/order
// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Card, TextField, Container, Grid, Snackbar, Stack, Typography } from '@mui/material';
// utils
// eslint-disable-next-line import/no-unresolved
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// eslint-disable-next-line import/no-unresolved
import useSettings from 'src/hooks/useSettings';
// eslint-disable-next-line import/no-unresolved
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import Page from 'src/components/Page';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/service/AxiosAll';
// eslint-disable-next-line import/no-unresolved
import { createTeam } from 'src/redux/actions/team';
import { fData } from '../../utils/formatNumber';
// routes
// components
import { FormProvider, RHFCheckbox, RHFSelect, RHFTextField, RHFUploadAvatar } from '../../components/hook-form';
// import moment from 'moment/moment';
import Label from '../../components/Label';
import WorkingHoursDialog from 'src/sections/mui/dialog/workingHours';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import currentURL from '../../env';

// eslint-disable-next-line import/no-unresolved
// ----------------------------------------------------------------------

UserNewFormStepOne.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default function UserNewFormStepOne({ isEdit }) {
  // Define and initialize state variables
  const { userRole, token } = useSelector((state) => state.loginReducer);
  const { CreateTeam } = useSelector((state) => state.teamReducer);
  const [Edit, setEdit] = useState();
  const inputRef = useRef(null);

  const { themeStretch } = useSettings();
  const [message, setMessage] = useState();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');
  const [imageError, setImageError] = useState('');
  const [organisationOption, setOrganisationOption] = useState([]);
  const setting = localStorage.getItem('settings');
  const [uploadurl, setUploadUrl] = useState(false);
  const [days, setDays] = useState([]);
  const [checkMToF, setMToF] = useState(false);
  const location = useLocation();
  const teamDetail = location?.state?.teamDetail;
  const [inputValue, setInputValue] = useState('');
  const [predictions, setPredictions] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  // Redux dispatch and navigation hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mode = JSON.parse(setting);
  const edit = location?.state?.edit;
  const createNewTeam = location?.state?.createNew;
  // Define validation schema for useForm
  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Team name is required'),
    Organisation: Yup.string().required('Function in Organisation* is required'),
  });
  // Effect to set the Edit state
  useEffect(() => {
    setEdit(edit);
  }, [edit]);
  // Utility function to get the name of the day of the week
  const firstDayOfWeek = days?.length ? days[0]?.day : null;
  const lastDayOfWeek = days?.length && days?.length !== 1 ? days[days?.length - 1]?.day : null;
  const teamDetailfirstDayOfWeek = teamDetail?.days?.length ? teamDetail?.days[0]?.day : null;
  const teamDetaillastDayOfWeek =
    teamDetail?.days?.length && teamDetail?.days?.length !== 1
      ? teamDetail?.days[teamDetail?.days.length - 1]?.day
      : null;

  // Utility function to get a formatted string of days of the week
  const getDayOfWeek = (day) => {
    switch (day) {
      case 1:
        return 'Sunday';
      case 2:
        return 'Monday';
      case 3:
        return 'Tuesday';
      case 4:
        return 'Wednesday';
      case 5:
        return 'Thursday';
      case 6:
        return 'Friday';
      case 7:
        return 'Saturday';
      default:
        return null;
    }
  };

  // Utility function to get a formatted string of days of the week
  const getDaysOfWeek = (firstDay, lastDay) => {
    const firstDayName = getDayOfWeek(firstDay);
    const lastDayName = getDayOfWeek(lastDay);
    return `${lastDayName === null ? `${firstDayName}` : `${firstDayName} - ${lastDayName}`}`;
  };
  // Define default initial state for useForm
  const defaultValues = {
    name: '',
    Organisation: organisationOption[0]?.id,
    addressLine1: '',
    addressLine2: '',
    zipcode: '',
    city: '',
    state: '',
    country: '',
    avatarUrl: 'https://www.pngitem.com/pimgs/m/30-307416_profile-icon-png-image-free-download-searchpng-employee.png',
    remoteWorking: false,
    description: '',
  };

  // Define default edit value for useForm
  const defaultEditValues = useMemo(
    () => ({
      name: CreateTeam?.name || teamDetail?.teamName,
      Organisation: CreateTeam?.organization || teamDetail?.organizationId,
      addressLine1: CreateTeam?.addressLine1 || teamDetail?.address,
      addressLine2: CreateTeam?.addressLine2 || teamDetail?.addressLine2,
      zipcode: CreateTeam?.postalCode || teamDetail?.postalCode,
      city: CreateTeam?.city || teamDetail?.city,
      state: CreateTeam?.state || teamDetail?.state,
      country: CreateTeam?.country || teamDetail?.country,
      avatarUrl: CreateTeam?.photoUrl || teamDetail?.photoUrl,
      remoteWorking: CreateTeam?.remoteWorking || teamDetail?.remoteWorking,
      description: CreateTeam?.description || teamDetail?.description,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Edit]
  );

  // Effect to reset form values when in edit mode
  useEffect(() => {
    if (Edit) {
      reset(defaultEditValues);
    }
  }, [Edit]);

  // Initialize useForm with resolver, default values, and default edit values
  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
    defaultEditValues,
  });

  // Fetch the list of organizations
  const getOrganisationList = async () => {
    const res = await AxiosAll('get', `/api/function/get-organizations`, {}, token);

    if (res?.status === 200) {
      setOrganisationOption(res?.data?.data);
    }
  };

  // Destructure methods for useForm
  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  // Event handler for cancel button
  const handleCancleBtn = () => {
    navigate('/teams');
    dispatch(createTeam({}));
  };

  // Event handler for input change
  const handleInputChange = (e) => {
    if (e?.target?.value) {
      setInputValue(e?.target?.value);
    } else {
      if (e !== null) {
        setInputValue('');
        setPredictions([]);
        setValue('addressLine1', '');

        setValue('zipcode', '');

        setValue('city', '');

        setValue('country', '');

        setValue('state', '');
      }
    }
  };

  // Event handler for Enter key press
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAutocomplete();
    }
  };

  // Effect to set input value from CreateTeam or teamDetail
  useEffect(() => {
    setInputValue(CreateTeam?.addressLine1 || teamDetail?.address || '');
  }, [CreateTeam, teamDetail]);

  // Event handler for autocomplete
  const handleAutocomplete = () => {
    axios
      .get(`${currentURL.currentEnv}/api/Location/GetPlaces?text=${inputValue}`)
      .then((response) => {
        if (response?.data?.autoCompletePlaces && response?.data?.autoCompletePlaces?.length > 0) {
          const placePredictions = response?.data?.autoCompletePlaces;
          setPredictions(placePredictions);
          setErrorMessage('');
        } else {
          setPredictions([]);
          setErrorMessage('No predictions found');
        }
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage('Error retrieving place predictions');
      });
  };

  // Event handler for place selection
  const handlePlaceSelect = (placeId) => {
    if (placeId) {
      axios
        .get(`${currentURL.currentEnv}/api/Location/GetPlaceDetails?placeId=${placeId}`)
        .then((response) => {
          if (response.data.result && response.data.result.geometry && response.data.result.geometry.location) {
            const location = response.data.result.geometry.location;
            setSelectedPlace(location);

            // Retrieve the formatted address using the selected location
            axios
              .get(
                `${currentURL.currentEnv}/api/Location/GetFormatedAddress?lattitude=${location.lat}&longitude=${location.lng}`
              )
              .then((response) => {
                const addressComponents = response.data.results[0]?.address_components;
                setValue('addressLine1', response.data.results[0].formatted_address);
                setInputValue(response.data.results[0].formatted_address);
                const postalCode = addressComponents?.find((component) => component.types.includes('postal_code'));
                const city = addressComponents?.find((component) => component.types.includes('postal_town'));
                const country = addressComponents?.find((component) => component.types.includes('country'));
                const state = addressComponents?.find((component) =>
                  component.types.includes('administrative_area_level_2')
                );

                if (postalCode) {
                  setValue('zipcode', postalCode.long_name);
                } else {
                  setValue('zipcode', '');
                }
                if (city) {
                  setValue('city', city.long_name);
                } else {
                  setValue('city', '');
                }
                if (country) {
                  setValue('country', country.long_name);
                } else {
                  setValue('country', '');
                }
                if (state) {
                  setValue('state', state.long_name);
                } else {
                  setValue('state', '');
                }
              })
              .catch((error) => {
                setValue('zipcode', '');

                setValue('city', '');

                setValue('country', '');

                setValue('state', '');
              });
          } else {
            setValue('zipcode', '');

            setValue('city', '');

            setValue('country', '');

            setValue('state', '');
          }
        })
        .catch((error) => {
          console.error(error);
          setErrorMessage('Error retrieving place details');
        });
    }
  };

  // Event handler for image upload
  const handleUpload = async () => {
    // eslint-disable-next-line no-debugger
    setUploadUrl(true);
    setImageError('');
    const formData = new FormData();
    formData.append('file', values?.avatarUrl);
    // eslint-disable-next-line no-debugger
    try {
      const res = await AxiosAll('post', '/api/utilities/upload-image', formData, token, 'multipart/form-data');
      if (res?.status === 200) {
        setValue('avatarUrl', res?.data?.data?.imageUrl);
        setImageError('');
        setUploadUrl(false);
      } else {
        setUploadUrl(false);
        setValue('avatarUrl', teamDetail?.photoUrl);
        setImageError(res?.response?.data?.message);
        const timer1 = setTimeout(() => setImageError(''), 3 * 1000);
        return () => {
          clearTimeout(timer1);
        };
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Effect to handle image upload when values.avatarUrl is an object
  useEffect(() => {
    if (typeof values?.avatarUrl === 'object') {
      handleUpload();
    }
  }, [values?.avatarUrl]);

  // Effect to fetch the list of organizations on component mount
  useEffect(() => {
    getOrganisationList();
  }, []);

  // Effect to set days when teamDetail or CreateTeam changes
  useEffect(() => {
    if (teamDetail?.days?.length || CreateTeam?.days?.length) {
      setDays(teamDetail?.days || CreateTeam?.days);
    }
  }, [teamDetail]);

  // Event handler for form submission
  const onSubmit = async (values) => {
    try {
      if (values?.name && values?.Organisation) {
        if (edit === true) {
          setEdit(false);
          const createPayload = {
            id: CreateTeam?.id ? CreateTeam?.id : teamDetail?.teamId,
            name: values?.name,
            organization: values.Organisation,
            addressLine1: values?.addressLine1 ? values?.addressLine1 : '',
            addressLine2: values?.addressLine2 ? values?.addressLine2 : '',
            city: values?.city ? values?.city : '',
            state: values?.state ? values?.state : '',
            country: values?.country ? values?.country : '',
            postalCode: values?.zipcode ? values?.zipcode : '',
            description: values?.description,
            remoteWorking: values?.remoteWorking,
            photoUrl:
              typeof values?.avatarUrl === 'object' ? CreateTeam?.photoUrl || teamDetail?.photoUrl : values?.avatarUrl,
            days: days,
            RoleId: userRole?.data === 2 ? userRole?.data : 0,
          };

          const res = await AxiosAll('post', '/api/team/create-team', createPayload, token);
          if (res?.status === 200) {
            setImageError('');
            const updatedCreatePayload = { ...createPayload, id: res.data?.data?.id };
            dispatch(createTeam(updatedCreatePayload));
            DetailPage(res?.data?.id);
            setType('success');
            setOpen(true);
            setMessage(res?.data?.message);
          } else {
            setType('error');
            setOpen(true);
            setMessage(res?.response?.data?.message || res?.response?.data?.errors?.Name[0]);
          }
        } else {
          const createPayload = {
            id: 0,
            name: values?.name,
            organization: values.Organisation,
            addressLine1: values?.addressLine1,
            addressLine2: values?.addressLine2,
            city: values?.city,
            state: values?.state,
            country: values?.country,
            postalCode: values?.zipcode,
            description: values?.description,
            remoteWorking: values?.remoteWorking,
            photoUrl:
              typeof values?.avatarUrl === 'object' ? CreateTeam?.photoUrl || teamDetail?.photoUrl : values?.avatarUrl,
            days: days,
            RoleId: userRole?.data === 2 ? userRole?.data : 0,
          };
          const res = await AxiosAll('post', '/api/team/create-team', createPayload, token);
          if (res?.status === 200) {
            const updatedCreatePayload = { ...createPayload, id: res.data?.data?.id };
            dispatch(createTeam(updatedCreatePayload));
            if (createNewTeam === true) {
              setImageError('');
              setType('success');
              setOpen(true);
              setMessage(res?.data?.message);
              dispatch(createTeam({}));
              navigate('/teams', { state: { createNewTeam: false } });
            } else {
              navigate('/create-team-stepTwo', {
                state: { edit: false, id: res?.data?.data?.id, teamName: values?.name },
              });
            }
          } else {
            setType('error');
            setOpen(true);
            setMessage(res?.response?.data?.message || res?.response?.data?.errors?.Name[0]);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Navigate to detail page based on whether it's a new team or an edit
  const DetailPage = (id) => {
    const TeamId = teamDetail?.teamId;
    if (TeamId) {
      navigate('/team-detail', { state: { Id: TeamId } });
      dispatch(createTeam({}));
    } else {
      navigate('/create-team-stepTwo', { state: { edit: false, id, teamName: values?.name } });
    }
  };

  // Callback function for handling file drop
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'avatarUrl',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  // Effect to clear address fields when remoteWorking is true
  useEffect(() => {
    if (values?.remoteWorking === true) {
      setValue('addressLine1', '');
      setValue('addressLine2', '');
      setValue('city', '');
      setValue('state', '');
      setValue('country', '');
      setValue('zipcode', '');
    }
  }, [values?.remoteWorking]);

  // Effect to set address fields from CreateTeam
  useEffect(() => {
    setValue('city', CreateTeam?.city);
    setValue('state', CreateTeam?.stateCounty);
    setValue('country', CreateTeam?.country);
    setValue('zipcode', CreateTeam?.postalCode);
  }, [CreateTeam]);

  // Effect to set the checkMToF state based on start times
  useEffect(() => {
    const allStartTimesEqual = teamDetail?.days?.every((event) => event?.startTime === teamDetail?.days[0].startTime);
    setMToF(allStartTimesEqual);
  }, [teamDetail]);

  // Effect to set the checkMToF state based on start times
  useEffect(() => {
    if (days?.length) {
      const allStartTimesEqual = days?.every((event) => event?.startTime === days[0]?.startTime);
      setMToF(allStartTimesEqual);
    }
  }, [days]);

  return (
    <Page title="Create a new team  >  create team (step 1 of 2)" className="manage-member">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={
            // eslint-disable-next-line no-nested-ternary
            edit === false
              ? 'Team Details (Step 1 of 2)'
              : createNewTeam === true
              ? 'Team Details (Step 1 of 2)'
              : 'Manage Teams'
          }
          links={[
            { name: 'TripShift Platform', href: '/dashboard' },
            { name: 'Teams', href: '/teams' },
            { name: `${edit ? 'Edit Team details' : 'New Team'}` },
          ]}
        />
        <Snackbar
          open={open}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={3000}
          onClose={() => setOpen(false)}
        >
          <Alert onClose={() => setOpen(false)} severity={type || 'success'} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>

        <FormProvider methods={methods}>
          <div className="boder-upper">
            <Grid container spacing={3} sx={{ py: 3, px: 3 }}>
              <Grid item xs={12} md={3}>
                <Card sx={{ py: 10, px: 3, boxShadow: 'none' }}>
                  {isEdit && (
                    <Label
                      color={values.status !== 'active' ? 'error' : 'success'}
                      sx={{ textTransform: 'uppercase', position: 'absolute', top: 24, right: 24 }}
                    >
                      {values.status}
                    </Label>
                  )}

                  <Box sx={{ mb: 5 }}>
                    <RHFUploadAvatar
                      name="avatarUrl"
                      maxSize={3145728}
                      EditTeamUrl={teamDetail?.photoUrl}
                      onDrop={handleDrop}
                      helperText={
                        <Typography
                          variant="caption"
                          className="heading12_edit"
                          sx={{
                            mt: 2,
                            mx: 'auto',
                            display: 'block',
                            textAlign: 'center',
                            color: 'text.secondary',
                          }}
                        >
                          Allowed *.jpeg, *.jpg, *.png
                          <br /> max size of {fData(3145728)}
                        </Typography>
                      }
                    />
                  </Box>
                  {imageError !== '' && <p className="error-message profile_error_message">{imageError}</p>}
                </Card>
              </Grid>

              <Grid item xs={12} md={9}>
                <Card sx={{ p: 3, boxShadow: 'none' }} className="custome-card-form">
                  <Stack spacing={3}>
                    <RHFTextField
                      name="name"
                      label="Team Name*"
                      placeholder="(max 50 characters)"
                      className="text-field-width"
                    />
                    <div>
                      <Typography variant="body2" sx={{ mb: 1 }} className="mainly-working">
                        Business Unit*
                      </Typography>
                      <RHFSelect select name="Organisation" className="text-field-width select_input_field">
                        <option>Select Business Unit</option>
                        {organisationOption?.map((data, index) => (
                          <option
                            key={index}
                            value={data?.id}
                            sx={{
                              mx: 1,
                              my: 0.5,
                              borderRadius: 0.75,
                              typography: 'body2',
                              textTransform: 'capitalize',
                            }}
                          >
                            {data?.name}
                          </option>
                        ))}
                      </RHFSelect>
                    </div>
                    {values?.remoteWorking === false ? (
                      <div className="text-field-width google-place">
                        <Autocomplete
                          freeSolo
                          options={predictions?.map((prediction) => prediction.description)}
                          inputValue={inputValue}
                          onInputChange={handleInputChange}
                          onKeyPress={handleKeyPress}
                          open={true}
                          name="addressLine1"
                          renderInput={(params) => (
                            <TextField {...params} label="Enter a location" variant="outlined" ref={inputRef} />
                          )}
                          renderOption={(option) => <div>{option}</div>}
                          getOptionSelected={(option, value) => option === value}
                          onChange={(event, value) => {
                            if (value) {
                              const selectedPrediction = predictions.find(
                                (prediction) => prediction?.description === value
                              );
                              if (selectedPrediction) {
                                handlePlaceSelect(selectedPrediction?.placeId);
                              }
                            }
                          }}
                        />
                      </div>
                    ) : null}

                    {values?.addressLine1 !== '' ? (
                      <RHFTextField
                        name="addressLine2"
                        label=""
                        placeholder="Address 2 (optional)"
                        className="text-field-width"
                      />
                    ) : null}
                    {values?.city !== '' && values?.remoteWorking === false ? (
                      <RHFTextField name="city" label="" placeholder="city" className="text-field-width" />
                    ) : null}
                    {values?.state !== '' && values?.remoteWorking === false ? (
                      <RHFTextField name="state" label="" placeholder="state" className="text-field-width" />
                    ) : null}
                    {values?.zipcode !== '' && values?.remoteWorking === false ? (
                      <RHFTextField name="zipcode" label="" placeholder="postal code" className="text-field-width" />
                    ) : null}
                    {values?.country !== '' && values?.remoteWorking === false ? (
                      <RHFTextField name="country" label="" placeholder="country" className="text-field-width" />
                    ) : null}
                    <Typography variant="body2" sx={{ mt: 3, mb: 3, pl: 2 }} className="mainly-working">
                      {/* Set default work hours (include commuting hours) */}
                      Please set your working hours (include commuting hours):
                    </Typography>
                    {checkMToF ? (
                      <div className="edit_hour_section">
                        <card className="sethour-new-box">
                          <div className="time-new-box">
                            <Typography variant="subtitle1" className="header_dark_heading">
                              {days?.length
                                ? getDaysOfWeek(firstDayOfWeek, lastDayOfWeek)
                                : teamDetail?.days?.length
                                ? getDaysOfWeek(teamDetailfirstDayOfWeek, teamDetaillastDayOfWeek)
                                : 'Monday - Friday'}
                            </Typography>

                            <Typography variant="subtitle1" className="header_dark_heading">
                              {days?.length
                                ? `${days[0]?.startTime.replace(/^0|:00/g, '')}- ${days[0]?.endTime.replace(
                                    /^0|:00/g,
                                    ''
                                  )}`
                                : teamDetail?.days?.length
                                ? `${teamDetail?.days[0]?.startTime.replace(
                                    /^0|:00/g,
                                    ''
                                  )} - ${teamDetail?.days[0]?.endTime.replace(/^0|:00/g, '')}
                                  }`
                                : '7:00 am - 7:00 pm'}
                            </Typography>
                          </div>

                          <WorkingHoursDialog setNewDays={setDays} legnth={teamDetail?.days?.length} newdays={days} />
                        </card>
                      </div>
                    ) : days?.length ? (
                      <div className="custom-new-box">
                        <div className="custom-card">
                          {days?.length &&
                            days?.map((data, key) => {
                              return (
                                <div className="custom-time-box" key={key}>
                                  <Typography variant="subtitle1" sx={{ mb: 0.8 }}>
                                    {data?.day === 2
                                      ? 'Mon'
                                      : data?.day === 3
                                      ? 'Tue'
                                      : data?.day === 4
                                      ? 'Weds'
                                      : data?.day === 5
                                      ? 'Thu'
                                      : data?.day === 6
                                      ? 'Fri'
                                      : data?.day === 7
                                      ? 'Sat'
                                      : 'Sun'}
                                  </Typography>
                                  <Typography variant="body">
                                    {' '}
                                    {data?.startTime.replace(/^0|:00/g, '')}-{data?.endTime.replace(/^0|:00/g, '')}
                                  </Typography>
                                </div>
                              );
                            })}
                        </div>
                        <WorkingHoursDialog setNewDays={setDays} legnth={teamDetail?.days?.length} newdays={days} />
                      </div>
                    ) : teamDetail?.days?.length ? (
                      <div className="custom-new-box">
                        <div className="custom-card">
                          {teamDetail &&
                            teamDetail?.days?.map((data, key) => {
                              return (
                                <div className="custom-time-box" key={key}>
                                  <Typography variant="subtitle1" sx={{ mb: 0.8 }}>
                                    {data?.day === 2
                                      ? 'Mon'
                                      : data?.day === 3
                                      ? 'Tue'
                                      : data?.day === 4
                                      ? 'Weds'
                                      : data?.day === 5
                                      ? 'Thu'
                                      : data?.day === 6
                                      ? 'Fri'
                                      : data?.day === 7
                                      ? 'Sat'
                                      : 'Sun'}
                                  </Typography>
                                  <Typography variant="body">
                                    {' '}
                                    {data?.startTime.replace(/^0|:00/g, '')}-{data?.endTime.replace(/^0|:00/g, '')}
                                  </Typography>
                                </div>
                              );
                            })}
                        </div>
                        <WorkingHoursDialog setNewDays={setDays} legnth={teamDetail?.days?.length} newdays={days} />
                      </div>
                    ) : (
                      <div className="edit_hour_section">
                        <card className="sethour-new-box">
                          <div className="time-new-box">
                            <Typography variant="subtitle1">Monday - Friday</Typography>

                            <Typography variant="subtitle1">7:00 am - 7:00 pm</Typography>
                          </div>

                          <WorkingHoursDialog setNewDays={setDays} legnth={teamDetail?.days?.length} newdays={days} />
                        </card>
                      </div>
                    )}
                    <RHFCheckbox
                      name="remoteWorking"
                      className="mainly-working heading12_edit"
                      label="Do you work from home / remotely the majority of the time?"
                    />
                    <RHFTextField
                      name="description"
                      multiline
                      rows={4}
                      className="text-field-width"
                      label="Team Description"
                    />
                    {/* </Box> */}
                  </Stack>
                  <Stack
                    direction={{ xs: 'row', sm: 'row' }}
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    sx={{ mt: 3 }}
                    spacing={2}
                    className="save-chnage-new"
                    // className="form-step-btnend"
                  >
                    {/* <Stack > */}
                    {edit === true ? (
                      <LoadingButton
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                        size="large"
                        variant="contained"
                        sx={{ textTransform: 'initial' }}
                        loading={isSubmitting}
                      >
                        Save changes
                      </LoadingButton>
                    ) : (
                      ''
                    )}
                    {createNewTeam === true ? (
                      <LoadingButton
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                        size="large"
                        variant="contained"
                        sx={{ textTransform: 'initial' }}
                        loading={isSubmitting}
                      >
                        Save changes
                      </LoadingButton>
                    ) : (
                      ''
                    )}
                    <LoadingButton
                      size="large"
                      variant="contained"
                      onClick={handleCancleBtn}
                      className={mode?.themeMode === 'dark' ? 'cancle_btn-team cancle_btn_shadow' : ' cancle_btn-team '}
                      sx={{ px: 4, boxShadow: 'none !important' }}
                    >
                      Cancel
                    </LoadingButton>
                    {edit === false ? (
                      <>
                        {' '}
                        {uploadurl ? (
                          <LoadingButton size="large" variant="contained" disabled>
                            Next
                          </LoadingButton>
                        ) : (
                          <LoadingButton
                            type="button"
                            onClick={handleSubmit(onSubmit)}
                            size="large"
                            variant="contained"
                            loading={isSubmitting}
                          >
                            Next
                          </LoadingButton>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </div>
        </FormProvider>
      </Container>
    </Page>
  );
}
