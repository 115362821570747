// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_user_group'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  invoice: getIcon('ic_invoice'),
  company: getIcon('ic_banking'),
  chart: getIcon('ic_charts'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Carbon insights',
    items: [
      { title: 'Dashboard', path: '/dashboard', icon: ICONS.dashboard },
      // { title: 'Reduce', path: '/reduce', icon: ICONS.chart },
      { title: 'Report', path: '/report', icon: ICONS.invoice },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'manage',
    items: [
      {
        // title: 'company',
        // path: '/company',
        title: 'organisation',
        path: '/organisation',
        icon: ICONS.company,
      },
      {
        title: 'members',
        path: '/members',
        icon: ICONS.user,
      },
      {
        title: 'teams',
        path: '/teams',
        icon: ICONS.ecommerce,
      },
    ],
  },
];

export default navConfig;
