/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { useState } from 'react';
// @mui
import { Box, Button, Dialog, DialogActions, DialogContent, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import AxiosAll from 'src/service/AxiosAll';
// components
// eslint-disable-next-line import/no-unresolved
// import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

const emails = ['username@gmail.com', 'user02@gmail.com'];

export default function MemberDeleteDialog({
  openDeleteModal,
  dataFiltered,
  setOpenDeleteModal,
  setOpenSuccessModal,
  setReload,
  multidelete,
  selectedRow,
  setSelected,
  DeleteUserIds,
  userName,
  userEmail,
  userId,
  roleId,
  setDeleteIds,
}) {
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);

  const navigate = useNavigate();
  const theme = useTheme();
  const [selectedValue, setSelectedValue] = useState(emails[1]);
  const { userDetails, token } = useSelector((state) => state.loginReducer);

  const rowData = dataFiltered?.filter((person) => selectedRow?.includes(person?.userId));

  // console.log("dataFiltered", dataFiltered);
  const onSubmit = async () => {
    // eslint-disable-next-line no-debugger
    if (userId) {
      const dataObject = {
        userId: [userId],
      };
      const res = await AxiosAll('post', '/api/user/delete-member', dataObject, token);
      if (res?.status === 200) {
        DeleteUserIds?.push(userId);
        setOpenDeleteModal(false);
        setOpenSuccessModal(true);
      }
    } else {
      const dataObject = {
        userId: selectedRow,
      };
      const res = await AxiosAll('post', '/api/user/delete-member', dataObject, token);
      if (res?.status === 200) {
        setDeleteIds(selectedRow);
        setOpenDeleteModal(false);
        setSelected([]);
        setOpenSuccessModal(true);
      }
    }
  };
  const name = userName === ' ' ? userEmail : userName;

  return (
    <>
      <Box sx={{ textAlign: 'left' }}>
        <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
          <div className="">
            <DialogContent>
              <Typography
                variant="body1"
                className={mode?.themeMode === 'dark' ? 'heading_18_dark heading_18 boldttext' : 'heading_18 boldttext'}
                sx={{ pl: 1 }}
              >
                {dataFiltered
                  ? 'You are about to delete all selected members. Confirm?'
                  : `You are about to delete ${userName} from the TripShift Platform. Confirm?`}
              </Typography>
            </DialogContent>
            <DialogActions className="delete-end-side" sx={{ pt: 0 }}>
              <Button onClick={onSubmit} variant="contained" size="medium">
                Confirm
              </Button>

              <Button
                onClick={() => setOpenDeleteModal(false)}
                variant="contained"
                className="cancle_btn"
                size="medium"
              >
                Cancel
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </Box>
    </>
  );
}
