/* eslint-disable import/order */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-debugger */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-dupe-else-if */
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line import/no-duplicates
import { Alert, Button, InputAdornment, Snackbar, TextField } from '@mui/material';
import PropTypes from 'prop-types';
// import { DatePicker } from '@mui/x-date-pickers';
// components
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DeleteSuccess from 'src/sections/mui/dialog/DeleteSuccess';
import MemberDeleteDialog from 'src/sections/mui/dialog/MemberDeleteDialog';
import NudgeDialoge from 'src/sections/mui/dialog/NudgeDialoge';
import AxiosAll from 'src/service/AxiosAll';
import Iconify from '../../../../components/Iconify';
// eslint-disable-next-line import/order
import AddMemberDialogs from 'src/sections/mui/dialog/addMember';
import AlertDialog from '../../../mui/dialog/deleteAlert';
// eslint-disable-next-line import/order
import WarningDialog from 'src/sections/mui/dialog/warning';
import { useNavigate } from 'react-router';

// ----------------------------------------------------------------------

const INPUT_WIDTH = 160;

InvoiceTableToolbar.propTypes = {
  filterName: PropTypes.string,
  filterService: PropTypes.string,
  // filterEndDate: PropTypes.instanceOf(Date),
  // filterStartDate: PropTypes.instanceOf(Date),
  onFilterName: PropTypes.func,
  // onFilterEndDate: PropTypes.func,
  onFilterService: PropTypes.func,
  // onFilterStartDate: PropTypes.func,
  optionsService: PropTypes.arrayOf(PropTypes.string),
};

export default function InvoiceTableToolbar({
  optionsService,
  filterName,
  filterService,
  onFilterName,
  tableData,
  onFilterService,
  selected,
  selectedRow,
  setReload,
  setSelected,
  MainTable,
  makeTeam,
  makeAdmin,
  teamName,
  teamLength,
  user,
  setIds,
  setRoleKey,
  DeleteUserIds,
  setAddReload,
  setDeleteIds,
}) {
  const { userDetails, userRole, token } = useSelector((state) => state.loginReducer);
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [message, setMessage] = useState();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');
  const [openNudge, setOpenNudge] = useState(false);
  const [opensuceessModal, setOpenSuccessModal] = useState(false);
  const [RemoveTeamLeadOpen, setRemoveTeamLeadOpen] = useState(false);
  const [manageNudge, setManageNudge] = useState(false);

  const handleModalOpen = () => {
    setOpenDeleteModal(true);
  };
  console.log('userRole?.data', userRole?.data);
  useEffect(() => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < tableData?.length; i++) {
      if (
        (userRole?.data === 2 && tableData[i]?.roleId === 2) ||
        (userRole?.data === 2 && tableData[i]?.roleId === 1) ||
        (userRole?.data === 2 && tableData[i]?.roleId === 5)
      ) {
        setManageNudge(true);
      } else {
        setManageNudge(false);
      }
    }
  }, [tableData]);

  const handleNudge = async (row) => {
    // eslint-disable-next-line no-debugger
    if (selectedRow) {
      const dataObject = {
        teamId: [],
        userId: selectedRow,
      };
      const res = await AxiosAll('post', '/api/user/nudge', dataObject, token);
      if (res?.status === 200) {
        setOpenNudge(true);
        setSelected([]);
      } else {
        setType('error');
        setMessage(res.data.message);
        setOpen(true);
      }
    }
  };

  const handleClickOpen = () => {
    navigate('/csv-upload');
  };
  return (
    <>
      {/* new design  */}
      <Snackbar
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} severity={type || 'success'} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <div className="memberside-table new-member-table">
        <div className="member-btn-all">
          {selected > 0 ? (
            <>
              {manageNudge ? (
                <Button
                  disabled
                  size="medium"
                  variant="contained"
                  className=" nudge-btn-disable nudge-btn"
                  onClick={handleNudge}
                >
                  Nudge
                </Button>
              ) : (
                <Button size="medium" variant="contained" className=" nudge-btn" onClick={handleNudge}>
                  Nudge
                </Button>
              )}
              {userRole?.data === 1 || userRole?.data === 5 ? (
                <>
                  {makeAdmin ? (
                    <AlertDialog
                      multiSelect
                      title="Make Admin"
                      selectedRow={selectedRow}
                      dataFiltered={tableData}
                      setSelected={setSelected}
                      setReload={setReload}
                      setIds={setIds}
                      setRoleKey={setRoleKey}
                    />
                  ) : null}
                  {makeTeam ? (
                    <AlertDialog
                      multiSelect
                      title="Make Team Lead"
                      selectedRow={selectedRow}
                      dataFiltered={tableData}
                      setSelected={setSelected}
                      setReload={setReload}
                      setIds={setIds}
                      setRoleKey={setRoleKey}
                    />
                  ) : null}
                </>
              ) : null}
              {userRole?.data === 1 || userRole?.data === 5 ? (
                <Button onClick={handleModalOpen} size="medium" className="deleteuser_btn " variant="outlined">
                  Delete members
                </Button>
              ) : (
                <>
                  {manageNudge ? (
                    <Button disabled size="medium" className="deleteuser_btn nudge-btn-disable" variant="outlined">
                      Remove from team
                    </Button>
                  ) : (
                    <Button
                      onClick={() => setRemoveTeamLeadOpen(true)}
                      size="medium"
                      className="deleteuser_btn "
                      variant="outlined"
                    >
                      Remove from team
                    </Button>
                  )}
                </>
              )}
            </>
          ) : (
            ''
          )}
        </div>

        <div className="member-serch-btn">
          <TextField
            className="search_bar"
            size="small"
            sx={
              {
                // mx: 2
              }
            }
            value={filterName}
            onChange={(event) => onFilterName(event.target.value)}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              ),
            }}
          />{' '}
          {(userRole?.data === 1 || userRole?.data === 5) && (
            <AddMemberDialogs setAddReload={setAddReload} setReload={setReload} />
          )}
          {userRole?.data === 2 && teamLength ? (
            <AddMemberDialogs setAddReload={setAddReload} setReload={setReload} />
          ) : null}
          {/* {userRole?.data !== 2 ? (
            <Button
              size="medium"
              variant="contained"
              className="newmemberbtn"
              startIcon={<Iconify icon={'eva:plus-fill'} />}
              onClick={handleClickOpen}
              sx={{ boxShadow: 'none', ml: 2 }}
            >
              Upload CSV
            </Button>
          ) : (
            ''
          )} */}
        </div>
      </div>
      {openNudge && (
        <NudgeDialoge
          dataFiltered={tableData}
          multiSelect
          open={openNudge}
          setOpen={setOpenNudge}
          setReload={setReload}
        />
      )}
      {openDeleteModal && (
        <MemberDeleteDialog
          setSelected={setSelected}
          selectedRow={selectedRow}
          dataFiltered={tableData}
          setReload={setReload}
          setOpenSuccessModal={setOpenSuccessModal}
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          DeleteUserIds={DeleteUserIds}
          setDeleteIds={setDeleteIds}
        />
      )}
      {opensuceessModal && (
        <DeleteSuccess
          dataFiltered={tableData}
          setOpenSuccessModal={setOpenSuccessModal}
          opensuceessModal={opensuceessModal}
          setReload={setReload}
        />
      )}

      {RemoveTeamLeadOpen && (
        <WarningDialog
          setSelected={setSelected}
          selectedRow={selectedRow}
          setReload={setReload}
          multiSelect={true}
          teamName={teamName}
          role={userRole?.data}
          RemoveTeamLeadOpen={RemoveTeamLeadOpen}
          setRemoveTeamLeadOpen={setRemoveTeamLeadOpen}
          setIds={setIds}
          setRoleKey={setRoleKey}
          setDeleteIds={setDeleteIds}
        />
      )}
    </>
  );
}
